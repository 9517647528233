import React from 'react';

/**
 * @typedef {object} SliderRegularContextValue
 * @property {Function} showCursor
 * @property {Function} hideCursor
 */

/** @type {SliderRegularContextValue} */
const defaultContextValue = {
  showCursor: () => {},
  hideCursor: () => {}
};

const SliderRegularContext = React.createContext(defaultContextValue);

export const SliderRegularContextProvider = SliderRegularContext.Provider;

export const useSliderRegularContext = () => (
  React.useContext(SliderRegularContext) || defaultContextValue
);
