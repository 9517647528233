import { useClickAway, useDebounceFn, useSize } from 'ahooks';
import classNames from 'classnames';
import { AnimatePresence, motion, useIsPresent } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { engineName } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import { SvgCircleNumberMap } from '../../utils';
import { Badge } from '../Badge';
import { PointerMinion } from '../Cursor/PointerMinion';
import { MotionImage } from '../Image';
import { VideoThumbnail } from '../VideoThumbnail';
import classes from './Card.module.scss';
import './Card.scss';
import { CardType } from './CardType';
import { MobileOverlay } from './MobileOverlay';
import { ComingSoonIcon, OnRequestIcon } from './constants';
import { useCardSharedTransitions } from './contexts/cardSharedTrasitionContext';

// const Ripple = createRipples({
//   color: 'rgba(180, 174, 183, 0.4)',
//   during: 1000,
// });

function pointInRect(point, { top, left, width, height }) {
  return (
    point.x >= left
    && point.x <= left + width
    && point.y >= top
    && point.y <= top + height
  );
}

function Card({
  comingSoon,
  cursorPosition,
  href,
  image,
  index,
  isMobile,
  tags,
  title,
  type,
  video,
  year,
  onRequest
}) {
  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const elRef = useRef(null);

  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const footerRef = useRef(null);

  /** @type {import('react').MutableRefObject<HTMLLinkElement>} */
  const linkRef = useRef(null);
  const location = useLocation();
  const { rootVariants, contentVariants } = useCardSharedTransitions();
  const isPresent = useIsPresent();
  const [isPlayingReel, setIsPlayingReel] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMobileOverlayVisible, setMobileOverlayVisible] = useState(false);
  const hasMobileOverlay = useMemo(() => isMobile && (comingSoon || onRequest), [isMobile, comingSoon, onRequest]);

  const size = useSize(footerRef) ?? { height: 0 };

  const overlayProps = useMemo(() => {
    if (!hasMobileOverlay) {
      return null;
    }

    if (comingSoon) {
      return {
        decorator: (
          <CardType
            icon={ComingSoonIcon}
          >
            Case - Soon
          </CardType>
        ),
        text: 'Open Website'
      };
    }

    if (onRequest) {
      return {
        decorator: (
          <CardType
            icon={OnRequestIcon}
          >
            On - Request
          </CardType>
        ),
        text: 'Contact Us'
      };
    }
  }, [comingSoon, onRequest, hasMobileOverlay])

  const routerState = useMemo(() => ({
    from: location.pathname
  }), [location]);

  const target = useMemo(() => (
    href.startsWith('/') ? '_self' : '_blank'
  ), [href]);

  const { run, cancel } = useDebounceFn(
    useCallback(() => setIsPlayingReel(true), []),
    { wait: 0 }
  );

  const handleMouseEnter = useCallback(() => {
    run();
    setIsMouseOver(true);
  }, [run]);

  const handleMouseLeave = useCallback(() => {
    cancel();
    setIsPlayingReel(false);
    setIsMouseOver(false);
  }, [cancel]);

  const handleLinkClick = useCallback((e) => {
    if (hasMobileOverlay && !isMobileOverlayVisible) {
      e.preventDefault();
      setMobileOverlayVisible(true);
      return;
    }

  }, [hasMobileOverlay, isMobileOverlayVisible]);

  const handleRootElementAnimationComplete = useCallback(() => {
    const rect = elRef.current.getBoundingClientRect();

    if (!isMobile && pointInRect(cursorPosition, rect)) {
      handleMouseEnter();
    }
  }, [cursorPosition, handleMouseEnter, isMobile]);

  useClickAway(
    useCallback(() => {
      if (isMobile && isMobileOverlayVisible) {
        setMobileOverlayVisible(false);
      }
    }, [isMobile, isMobileOverlayVisible]),
    linkRef
  );

  return (
    <div
      ref={elRef}
      className={classes.root}
      onMouseEnter={isMobile ? null : handleMouseEnter}
      onMouseLeave={isMobile ? null : handleMouseLeave}
    >
      <motion.div
        className={classNames(
          "Card",
          {
            "Card--overlay-visible": isMobileOverlayVisible
          }
        )}
        custom={[
          {
            index,
            isPresent,
            isMobile
          }
        ]}
        variants={rootVariants}
        onAnimationComplete={handleRootElementAnimationComplete}
      >
        <div
          className="cardWrapper"
          style={{ display: 'flex' }}
        >
          <Link
            ref={linkRef}
            to={href}
            target={target}
            state={routerState}
            style={{ height: "100%", display: "contents" }}
            onClick={handleLinkClick}
          >
            <div className="Card__header Card--border">
              <div className="CardIndex">
                <motion.div
                  custom={[
                    { delay: 1.2, index }
                  ]}
                  variants={contentVariants}
                >
                  <div className="CardIndex__label">N&#176;</div>
                  <div
                    className="CardIndex__index"
                  // dangerouslySetInnerHTML={{ __html: `&#x${UnicodeCircleNumberMap[index + 1]?.toString(16)};` }}

                  >
                    {SvgCircleNumberMap[index + 1]}
                  </div>
                </motion.div>
              </div>
              <div className="CardTags">
                {tags.map((tag, index, collection) => (
                  <div
                    key={index}
                    className="CardTag"
                  >
                    <motion.div
                      custom={[
                        { delay: 1.2 + collection.length * 0.1, index }
                      ]}
                      variants={contentVariants}
                    >
                      #{tag}
                    </motion.div>
                  </div>
                ))}
              </div>
            </div>

            <div className="Card__body Card--border">
              <h2 className="CardTitle">
                <motion.div
                  custom={[
                    { delay: 0.52, duration: 1.20, index }
                  ]}
                  variants={contentVariants}
                >
                  {title}
                </motion.div>
              </h2>
              <div className="CardYear">
                <motion.div
                  custom={[
                    { delay: 0.62, duration: 1.10, index }
                  ]}
                  variants={contentVariants}
                >
                  Year — {year}
                </motion.div>
              </div>
            </div>

            <div ref={footerRef} className="Card__footer">
              <div className="CardImage__wrapper">
                <MotionImage
                  alt=""
                  custom={
                    [{ delay: 0.24, duration: 1.2, index, scale: 1 }, { index, delay: 0.2, duration: 1.2, scale: 1.1 }]
                  }
                  className={classNames(
                    "CardImage",
                    {
                      "CardImage__hover": isPresent && (comingSoon || onRequest) && (isMouseOver || isMobileOverlayVisible)
                    }
                  )}
                  src={image.src}
                  // Firefox has performance issues with inlined data-uris.
                  preSrc={engineName === 'Gecko' ? null : image.preSrc}
                  variants={{
                    enter: contentVariants.enter,
                    exit: isPresent ? contentVariants.exit : null
                  }}
                  style={{ transformOrigin: 'bottom center' }}
                />
              </div>

              {!isMobile && video && (
                <VideoThumbnail
                  {...video}
                  className="Card__reel"
                  isPlaying={isPlayingReel}
                  style={{ opacity: isPlayingReel ? 1 : 0 }}
                />
              )}

              <AnimatePresence>
                {isPresent && !isMobile && isMouseOver && (comingSoon || onRequest) && (
                  <motion.div
                    className={classes.overlay}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0.4 } }}
                  >
                    {comingSoon && (
                      <CardType
                        icon={ComingSoonIcon}
                      >
                        Case - Soon
                      </CardType>
                    )}

                    {onRequest && (
                      <CardType
                        icon={OnRequestIcon}
                      >
                        On - Request
                      </CardType>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {!isMobile && typeof type === 'string' && (
              <PointerMinion
                boundingElement={elRef}
                offsetY={32}
              >
                {(ref) => (
                  <Badge ref={ref}>
                    {type}
                  </Badge>
                )}
              </PointerMinion>
            )}

            <AnimatePresence>
              {isMobileOverlayVisible && (
                <MobileOverlay
                  iconSlot={overlayProps?.decorator}
                  textSlot={overlayProps?.text}
                  style={{
                    '--overlay-height': `${size.height}px`,
                  }}
                  onClick={() => linkRef.current.click()}
                />
              )}
            </AnimatePresence>
          </Link>
        </div>
      </motion.div>
    </div>
  );
}

Card.propTypes = {
  cursorPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  href: PropTypes.string,
  root: PropTypes.instanceOf(Node),
  rootMargin: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  threshold: function (props, propName, componentName) {
    if (props[propName] < 0.0 || props[propName] > 1.0) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Validation failed. Range(0, 1).'
      );
    }
  }
};

Card.defaultProps = {
  cursorPosition: {
    x: 0,
    y: 0
  },
  tags: []
};

export default Card;
