import classNames from 'classnames';
import { motion, useIsPresent } from 'framer-motion';
import React, { useCallback, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { Badge } from '../Badge';
import { Counter } from '../Counter';
import { PointerMinion } from '../Cursor/PointerMinion';
import { Flex } from '../Flex';
import cardClasses from './Card.module.scss';
import classes from './CardMore.module.scss';
import { useCardSharedTransitions } from './contexts/cardSharedTrasitionContext';

/**
 * @typedef {object} CardMoreProps
 * @property {number} [index=0]
 * @property {number} [projectCount=0]
 * @property {string} title
 * @property {() => void} onClick
 */

/** @type {import('react').FC<CardMoreProps>} */
export const CardMore = (props) => {
  const {
    index = 0,
    projectCount = 0,
    title,
    onClick
  } = props;

  const elRef = useRef(null);
  /** @type {import('../Counter').CounterProps['ref']} */
  const counterRef = useRef(null);
  const { rootVariants, contentVariants } = useCardSharedTransitions();
  const [animate, setAnimate] = useState();
  const [clicked, setClicked] = useState(false);
  const isPresent = useIsPresent();
  const startValue = isMacOs ? projectCount : 1;

  const handleClick = useCallback(() => {
    if (clicked) {
      return;
    }

    setAnimate('exit');
    setClicked(true);
  }, [clicked]);

  const handleAnimationComplete = useCallback((v) => {
    if (clicked && v === 'exit' && onClick instanceof Function) {
      onClick();
    }
  }, [clicked, onClick]);

  return (
    <div
      ref={elRef}
      className={classNames([
        cardClasses.root,
        classes.root
      ])}
      onClick={handleClick}
    >
      <motion.div
        animate={animate}
        className={classNames(['Card', classes.card])}
        custom={[
          {
            index: !isPresent || (isPresent && !clicked) ? index : 0
          },
          {
            height: '0%',
            width: '100%',
            duration: !isPresent || (isPresent && !clicked) ? 1.4 : 0.8
          }
        ]}
        variants={rootVariants}
        onAnimationComplete={handleAnimationComplete}
      >
        <div className="cardWrapper">
          <Flex
            alignItems="center"
            justifyContent="center"
            className="Card__body Card--border"
            direction="column"
            grow={1}
          >
            <h2 className={classNames(['CardTitle', classes.title])}>
              <motion.div
                custom={[{ delay: 0.6, index }]}
                variants={contentVariants}
                style={{ display: 'flex', alignItems: 'flex-start' }}
                onAnimationComplete={() => {
                  counterRef.current?.startAnimation();
                }}
              >
                <span>{title}</span>
                <span className={classes.sup}>
                  (&nbsp;
                  <Counter
                    ref={counterRef}
                    duration={1.4}
                    startValue={startValue}
                    value={projectCount}
                  />
                  &nbsp;)</span>
              </motion.div>
            </h2>

            <div className={classes.decoratorContainer}>
              <motion.div
                custom={[{ delay: 0.52, index }]}
                className={classes.decorator}
                variants={contentVariants}
              />
            </div>

            <div className={classes.subtitle}>
              <div style={{ overflow: 'hidden' }}>
                <motion.div
                  custom={[{ delay: 0.44, index }]}
                  variants={contentVariants}
                >
                  A legacy not
                </motion.div>
              </div>

              <div style={{ overflow: 'hidden' }}>
                <motion.div
                  custom={[{ delay: 0.38, index }]}
                  variants={contentVariants}
                >
                  forgotten.
                </motion.div>
              </div>
            </div>
          </Flex>

          <PointerMinion
            boundingElement={elRef}
            offsetY={60}
          >
            {(ref) => (
              <Badge ref={ref}>
                Open
              </Badge>
            )}
          </PointerMinion>
        </div>
      </motion.div>
    </div>
  );
}

CardMore.displayName = 'CardMore';
