import classNames from 'classnames';
import classes from './AwardListTableRow.module.scss';
import { useAwardListTableContext, useAwardListTableRowContext } from './contexts';

/**
 * @typedef {object} AwardListTableRowPartialProps
 * @property {never} children
 */

/**
 * @typedef {import('./AwardList').AwardListNormalizedItem & AwardListTableRowPartialProps} AwardListTableRowProps
 */

/** @type {import('react').FC<AwardListTableRowProps>} */
export const AwardListTableRow = (props) => {
  const {
    award,
    category,
    href,
    title,
    type,
    ...rest
  } = props;

  const { activeGroup, isLastGroup } = useAwardListTableContext();
  const { group, isLastRow } = useAwardListTableRowContext();

  const isHighlighted = activeGroup?.groupIndex === group;

  return (
    <>
      <tr
        className={classes.row}
        {...rest}
      >
        <td
          className={classNames(
            classes.cell,
            isHighlighted && classes.highlighted
          )}
        >
          {title}
        </td>

        <td className={classes.cell}>{award}</td>

        <td className={classes.cell}>{category}</td>
      </tr>

      {!isLastGroup && isLastRow && (
        <tr>
          <td className={classes.lastCell} colSpan={3} />
        </tr>
      )}
    </>
  );
}
