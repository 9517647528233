import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import lottie from "lottie-web";

import { ScrollTrigger, useGSAP } from "../../../gsap";

const Wrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  transform: ${(props) => props.transform};
`;

const Lottie = (props) => {
  const container = useRef(null);
  const animation = useRef(null);

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: props.loop || true,
      autoplay: props.autoplay || true,
      animationData: props.src,
    });

    return () => animation.current.destroy();
  }, [props.autoplay, props.loop, props.src]);

  useGSAP(
    () => {
      ScrollTrigger.create({
        trigger: container.current,
        start: `top-=${
          props.startTrigger ? props.startTrigger : "2000px"
        } bottom+=200px`,
        onEnter: () => {
          animation.current.play();
        },
        onEnterBack: () => {
          animation.current.play();
        },
        onLeave: () => {
          animation.current.pause();
        },
        onLeaveBack: () => {
          animation.current.pause();
        },
      });
    },
    {
      dependencies: [props.startTrigger],
      scope: container,
    }
  );

  return (
    <Wrapper
      ref={container}
      className={props.className}
      transform={props.transform}
      backgroundColor={props.backgroundColor}
      style={{ "--aspect": props.aspect }}
    />
  );
};

export default Lottie;
