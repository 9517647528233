import React from "react";
import { isTablet } from 'react-device-detect';
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";

import Image from "./Media/Image";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${pxToRem(132)};
  gap: ${pxToRem(22)};

  @media screen and (max-width: 620px) {
    flex-direction: column;
    text-align: center;
    margin-top: ${pxToRem(44)};
  }
  `;

const ImageWrapper = styled.div`
  width: ${pxToRem(80)};
  height: ${pxToRem(80)};
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  `;

const Signature = styled.div`
  position: absolute;
  z-index: 1;
  top: ${isTablet ? "-180%" : "-118%"};
  left: 46%;
  width: ${pxToRem(318)};
  transform: translateX(-50%);

  @media screen and (max-width: 620px) {
    width: ${pxToRem(198)};
    top: 11%;
    left: 47%;
  }

  svg {
    width: 100%;
  }
  
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;

const Name = styled.h4`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(19)};
  letter-spacing: 0em;
  line-height: 1.4;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(16)};
  }
`;

const Role = styled.p`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(14)};
  letter-spacing: 0.04em;
  line-height: 1.428;
`;

const Profile = (props) => {
  return (
    <Wrapper className={props.className}>
      <Signature>
        {props.signature}
      </Signature>

      <ImageWrapper>
        <Image src={props.img} aspect="1/1" />
      </ImageWrapper>

      <Text>
        <Name>{props.name}</Name>
        <Role>{props.role}</Role>
      </Text>
    </Wrapper>
  );
};

export default Profile;
