import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef } from 'react';

/**
 * @typedef {object} VideoThumbnailPartialProps
 * @property {boolean} [isPlaying]
 */

/**
 * @typedef {import('react').VideoHTMLAttributes<HTMLVideoElement> & VideoThumbnailPartialProps} VideoThumbnailProps
 */

/** @type {import('react').ForwardRefExoticComponent<VideoThumbnailProps>} */
export const VideoThumbnail = React.forwardRef((props, ref) => {
  const {
    autoPlay = false,
    loop = true,
    muted = true,
    playsInline = true,
    isPlaying = false,
    ...rest
  } = props;

  const videoRef = useRef(null);

  const captureRef = useCallback((node) => {
    if (node !== null) {
      videoRef.current = node;
    }

    if (ref instanceof Function) {
      ref(node);
    }
    else if (ref) {
      ref.current = node;
    }
  }, [ref]);

  useEffect(() => {
    if (autoPlay) {
      return
    }

    if (isPlaying) {
      videoRef.current?.play();
      return;
    }
    else {
      videoRef.current?.pause();
    }
  }, [autoPlay, isPlaying]);

  return (
    <video
      {...rest}
      ref={captureRef}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      playsInline={playsInline}
    />
  );
});

VideoThumbnail.displayName = 'VideoThumbnail'

export const MotionVideoThumbnail = motion(VideoThumbnail);
