import React from 'react';

/**
 * @typedef {object} AwardListTableRowContextValue
 * @property {number | string | null} group
 * @property {boolean} isFirstRow
 * @property {boolean} isLastRow
 */

/** @type {AwardListTableRowContextValue} */
const defaultContextValue = {
  group: null,
  isFirstRow: false,
  isLastRow: false
};

const AwardListTableRowContext = React.createContext(defaultContextValue);

export const AwardListTableRowContextProvider = AwardListTableRowContext.Provider;

export const useAwardListTableRowContext = () => (
  React.useContext(AwardListTableRowContext) || defaultContextValue
);
