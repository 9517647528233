import Timeline from "../components/About/Timeline";
import Container from "../components/Container";
import { MaskedReveal } from "../components/Motion";
import Page from "../components/Page";
import { withRegisterRoute } from "../routing";
import classes from './Why.module.scss';

// ‎ is invisible character
const data = [
  {
    title: "The Art",
    subtitle: "Who We Are",
    description: [
      "<span class='checkpoint'>We are <span class='hidden'>‎</span><b class='first'>storytellers.</b><span class='hidden'>‎</span></span>",
      "<span class='checkpoint blur'>We offer the <span class='hidden'>‎</span><b>full spectrum</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>of design, branding, and consulting services to companies that are <span class='hidden'>‎</span><b>committed</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>to realizing their <span class='hidden'>‎</span><b>brand’s</b><span class='hidden'>‎</span> <span class='checkpoint blur'>potential.",
      "Our work is grounded in the <span class='hidden'>‎</span><b>principle</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>of eloquent visual <span class='hidden'>‎</span><b>expression:</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>design that <span class='hidden'>‎</span><b>elevates</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>your product, or service, and <span class='hidden'>‎</span><b>gives</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>your audience an <span class='hidden'>‎</span><b>authentic</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>brand experience.",
      "Your <span class='hidden'>‎</span><b>story</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>is our art, and your <b>success</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>is the beauty of our craft.</span>",
    ]
  },
  {
    title: "The Craft",
    subtitle: "What We Do",
    description: [
      "<span class='checkpoint'>We work with brands to help them <span class='hidden'>‎</span><b class='first'>establish,</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>or <span class='hidden'>‎</span><b>reinvent,</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>their identity and <span class='hidden'>‎</span><b>reach</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>the heights of their ambition.",
      "By <span class='hidden'>‎</span><b>integrating</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>a brand’s character into aesthetic elements, we <span class='hidden'>‎</span><b>compose</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>a visual narrative that is <span class='hidden'>‎</span><b>unique</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>to your brand — a <span class='hidden'>‎</span><b>good</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>story.",
      "This <span class='hidden'>‎</span><b>story</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>is what leaves a lasting <span class='hidden'>‎</span><b>impression</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>on your audience and forms a real <span class='hidden'>‎</span><b>connection</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>— with <span class='hidden'>‎</span><b>real</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>results.",
      "We <span class='hidden'>‎</span><b>find</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>your story, <span class='hidden'>‎</span><b>and</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>we tell it well.</span>",
    ]
  },
  {
    title: "The Method",
    subtitle: "How We Do It",
    description: [
      "<span class='checkpoint'>Your <span class='hidden'>‎</span><b class='first'>ambition,</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>your passion, and your purpose: all these share a common <span class='hidden'>‎</span><b>thread</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>— one that is as <span class='hidden'>‎</span><b>unique</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>to a <span class='hidden'>‎</span><b>brand</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>as it is to a person.",
      "It’s a golden thread that <span class='hidden'>‎</span><b>leads</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>your audience <span class='hidden'>‎</span><b>through</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>the visual narrative: it <span class='hidden'>‎</span><b>invites</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>them to truly <span class='hidden'>‎</span><b>experience</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>the story of your brand.",
      "We <span class='hidden'>‎</span><b>weave</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>this thread into an authentic <span class='hidden'>‎</span><b>expression</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>of your brand’s character, so that your audience <span class='hidden'>‎</span><b>finds</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>it — and <span class='hidden'>‎</span><b>follows</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>it — every time.</span>",
    ]
  },
  {
    title: "The Client",
    subtitle: "You",
    description: [
      "<span class='checkpoint'>We<span class='hidden'>‎</span><b class='first'>partner</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>with clients who <span class='hidden'>‎</span><b>trust</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>us to bring their vision to life, and through mutual <span class='hidden'>‎</span><b>respect</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>and a shared <span class='hidden'>‎</span><b>commitment</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>to excellence, we do.",
      "<span class='hidden'>‎</span><b>This</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>is what allows us to <span class='hidden'>‎</span><b>create</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>a true <span class='hidden'>‎</span><b>expression</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>of your brand. It’s why your stories <span class='hidden'>‎</span><b>resonate,</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>and why our designs last.",
      "Our <span class='hidden'>‎</span><b>partnership</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>is the story of our joint success, <span class='hidden'>‎</span><b>but</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>it all begins and ends with the <span class='hidden'>‎</span><b>client</b><span class='hidden'>‎</span></span> <span class='checkpoint blur'>— you.</span>",
    ]
  }
];

export function Why() {
  return (
    <Page dimOnExit>
      <Container centered withPreventLenis={false}>
        <div className={classes.title}>
          <MaskedReveal delay={[0, 0.15]} style={{ paddingBottom: '0.075em' }}>
            Founded to
          </MaskedReveal>

          <MaskedReveal delay={[0.15, 0]} style={{ paddingBottom: '0.075em' }}>
            Express
          </MaskedReveal>
        </div>
        <Timeline data={data} />
      </Container>
    </Page>
  );
}

export default withRegisterRoute(Why, {
  route: '/why',
  name: 'Why?',
  description: 'Our why is your why. Your mission and your goals are our mission and goals. All of this forms a character, and we are here to express it.',
  jsonld: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'FIFTYSEVEN',
    description: 'Our why is your why. Your mission and your goals are our mission and goals. All of this forms a character, and we are here to express it.',
    url: 'https://fiftyseven.co/why',
    image: {
      '@type': 'ImageObject',
      url: 'https://www.fiftyseven.co/thumbnail/why.png',
      width: 1200,
      height: 628
    }
  },
  weight: 12,
  options: {
    transition: 'wait'
  }
});
