import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  gap: ${(props) => props.gap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify || 'center'};
  height: ${(props) => props.fullheight ? '100%' : 'auto'}; 
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  box-sizing: ${(props) => props.padding ? 'border-box' : null}; 

  background-color: ${(props) => props.backgroundColor};
`;

const LayoutFlex = (props) => {
  return (
    <Flex className={props.className} backgroundColor={props.backgroundColor} direction={props.direction} gap={props.gap} align={props.align} justify={props.justify} fullheight={props.fullheight} width={props.width} margin={props.margin} padding={props.padding}>{props.children}</Flex>
  );
};

export default LayoutFlex;
