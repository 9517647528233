import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '../Flex';
import { MaskedReveal } from '../Motion';
import classes from './Details.module.scss';
import Services from './Services';
import { Durations } from '../../animation';

function getDelay(idx, delay = 1) {
  return delay + idx * Durations.gap;
}

function Details({
  client,
  industry,
  services,
  year,
  theme
}) {
  return (
    <Flex
      className={classes.root}
      alignItems="center"
      justifyContent="flex-start"
      wrap
    >
      <MaskedReveal
        delay={getDelay(1)}
        elStyle={{ display: 'flex', alignItems: 'center' }}
      >
        <div className={classes.label}>
          Client:
        </div>
        <div className={classes.value}>
          {client}
        </div>

        <div className={classes.separator}>/</div>
      </MaskedReveal>

      <MaskedReveal
        delay={getDelay(2)}
        elStyle={{ display: 'flex', alignItems: 'center' }}
      >
        <div className={classes.label}>
          Industry:
        </div>
        <div className={classes.value}>
          {industry}
        </div>

        <div className={classes.separator}>/</div>
      </MaskedReveal>

      <MaskedReveal
        delay={getDelay(3)}
        elStyle={{ display: 'flex', alignItems: 'center' }}
      >
        <Services
          services={services}
          theme={theme}
        />

        <div className={classes.separator}>/</div>
      </MaskedReveal>

      <MaskedReveal
        delay={getDelay(4)}
        elStyle={{ display: 'flex', alignItems: 'center' }}
      >
        <div className={classes.label}>
          Year:
        </div>
        <div className={classes.value}>
          {year}
        </div>
      </MaskedReveal>
    </Flex>
  );
}

Details.propTypes = {
  client: PropTypes.string,
  industry: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

Details.defaultProps = {
  client: '',
  industry: '',
  services: [],
  theme: {},
  year: 0
};

export default Details;
