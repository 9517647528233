import React from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";

// Component
import Menunav from "./Menunav";
import Chapter from "./Text/Chapter";
import Heading1 from "./Text/Heading1";
import pxToRem from "../Utils/pxToRem";
import AnimTextPerline from "./Motion/Textperline";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const Chaptersection = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  return (
    <Wrapper className={props.className}>
      <Chapter text="Chapter" number={props.number} margin={`${isMobile ? `0 0 ${pxToRem(20)} 0` : `0 0 ${pxToRem(59)} 0`}`} />
      <div>
        <AnimTextPerline>
          <Heading1 as="h2" text={props.title} align="center" margin={`${isMobile ? `0 0 ${pxToRem(20)} 0` : `0 0 ${pxToRem(38)} 0`}`} />
        </AnimTextPerline>
        {props.menuitem && <Menunav items={props.menuitem} padding={props.menunavPadding} separatorOpacity={props.separatorOpacity} />}
      </div>
    </Wrapper>
  );
};

export default Chaptersection;
