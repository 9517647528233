import { useEventListener } from "ahooks";
import classNames from "classnames";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import { random } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Easings } from "../../animation";
import { useAppContext } from "../AppContext";
import Appear from "../Appear";
import { Flex } from "../Flex";
import Modal from "../Modal/Modal";
import TransformableImage from "../TransformableImage";
import classes from "./ListItem.module.scss";
import ModalBody from "./ModalBody";
import ModalContent from "./ModalContent";

const sides = ["left", "right"];

function ListItem({
  appearMotionPropVariants,
  exitAnimation,
  hideFooter,
  idx,
  listItemData,
  withHover,
  onModalClose,
  onModalShow,
}) {
  const { industry, name, shortreview, portrait, client, title } = listItemData;

  /** @type {React.MutableRefObject<HTMLElement>} */
  const rootRef = useRef(null);
  const isPresent = useIsPresent();
  const { set: setAppContext } = useAppContext();

  const computedStyle = useMemo(
    () => ({
      [`--name-offset-${sides[random(2)]}`]: `${random(15, true)}vw`,
    }),
    []
  );

  const [isHover, setHover] = useState(false);
  const isHoverable = withHover && isHover && isPresent && !isMobileOnly && !isTablet;

  const roleRef = useRef();
  const [roleDim, setRoleDim] = useState({ width: 0 });

  const borderVariants = useMemo(
    () => ({
      visible: () => ({
        y: 0,
        transition: {
          ease: Easings.brunoIn,
        },
      }),
      hidden: () => ({
        y: `${100 * 1.2}%`,
        transition: {
          ease: Easings.brunoOut,
        },
      }),
    }),
    []
  );

  const handleHoverEnd = useCallback((_, { point }) => {
    setRoleDim({ width: 0 });
    setHover(false);
  }, []);

  const handleHoverStart = useCallback((e, { point }) => {
    const rect = roleRef.current.getBoundingClientRect();
    setRoleDim({ width: rect.width });
    setHover(true);
  }, []);

  const [position, setPosition] = useState({
    clientX: 0,
    clientY: 0,
    x: 0,
    y: 0,
  });

  const handleMouseMove = useCallback(
    (e) => {
      if (!withHover) {
        return;
      }

      /** @type {HTMLDivElement} */
      const target = rootRef.current;
      const rect = target.getBoundingClientRect();

      setPosition({
        clientX: e.clientX,
        clientY: e.clientY,
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    },
    [withHover]
  );

  const handleShowModal = useCallback(
    (showModal) => () => {
      if (!isPresent) {
        return;
      }

      showModal();
      if (onModalShow instanceof Function) {
        onModalShow();
      }
    },
    [isPresent, onModalShow]
  );

  const handleModalOnBeforeOpen = useCallback(() => {
    if (hideFooter) {
      setAppContext('footerVisible', false);
    }
  }, [hideFooter, setAppContext]);

  const handleModalOnBeforeClose = useCallback(() => {
    if (hideFooter) {
      setAppContext('footerVisible', true);
    }

    if (onModalClose instanceof Function) {
      onModalClose();
    }
  }, [hideFooter, onModalClose, setAppContext]);

  useEventListener("mousemove", handleMouseMove);

  return (
    <Modal
      body={ModalBody}
      contentEl={ModalContent}
      contentProps={listItemData}
      overlayProps={useMemo(() => ({ className: classes.overlay, invisible: false }), [])}
      withOverlay
      onBeforeClose={handleModalOnBeforeClose}
      onBeforeOpen={handleModalOnBeforeOpen}
    >
      {({ showModal }) => (
        <motion.li
          ref={rootRef}
          className={classNames([
            "testimonials",
            classes.root,
            {
              [classes.interactive]: isHoverable,
              [classes.hover]: isHoverable
            },
          ])}
          onClick={handleShowModal(showModal)}
          onHoverEnd={handleHoverEnd}
          onHoverStart={handleHoverStart}
        >
          <AnimatePresence>
            {isHoverable && (
              <TransformableImage
                {...position}
                {...portrait}
                alt={name}
                className={classes.portrait}
                offsetParent={rootRef.current}
              />
            )}
          </AnimatePresence>

          <Flex alignItems="center" grow={1} justifyContent="space-between">
            {withHover && (
              <React.Fragment>
                <div className={classes.borderWrapper}>
                  <motion.div
                    className={classes.border}
                    animate={isHoverable ? "visible" : "hidden"}
                    initial="hidden"
                    variants={borderVariants}
                  />
                </div>

                <div className={classes.index}>
                  <motion.div
                    className={classes.indexBody}
                    animate={{ y: isHoverable ? "0" : "112%" }}
                    exit={{ y: "112%" }}
                    initial={{ y: "112%" }}
                    transition={{
                      duration: 0.64,
                      ease: Easings.easeOutCubic,
                    }}
                  >
                    <div className={classes.indexLabel}>N&#176;</div>
                    <div className={classes.indexValue}>{idx}</div>
                  </motion.div>
                </div>
              </React.Fragment>
            )}

            <div style={{ position: "relative" }}>
              <motion.div
                className={classNames([
                  "testimonials__name",
                  classes.name,
                ])}
                initial={{ x: 0 }}
                animate={isHoverable ? { x: -(roleDim.width / 2) } : {}}
                transition={{
                  ease: Easings.easeOutCubic,
                }}
                style={computedStyle}
              >
                <div style={{ overflow: "hidden " }}>
                  {withHover ? (
                    <Appear
                      exit={exitAnimation}
                      variants={appearMotionPropVariants}
                    >
                      <div
                        data-name={name}
                        className={classes.nameInner}
                      >
                        {name}
                      </div>
                    </Appear>
                  ) : (
                    <span>
                      {shortreview}
                    </span>
                  )}
                </div>
              </motion.div>

              <motion.div
                style={{ position: "absolute", top: "-0.3em", right: 0 }}
                initial={{ x: 0 }}
                animate={isHoverable ? { x: roleDim.width / 2 } : {}}
                transition={{
                  ease: Easings.easeOutCubic,
                }}
              >
                <Flex ref={roleRef} alignItems="flex-start">
                  <div className={classes.slash}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHoverable ? 1 : 0 }}
                    >
                      /
                    </motion.div>
                  </div>

                  <div className={classes.role}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHoverable ? 1 : 0 }}
                    >
                      {client}, {title}
                    </motion.div>
                  </div>
                </Flex>
              </motion.div>
            </div>

            {withHover && (
              <React.Fragment>
                <div className={classes.industry}>
                  <motion.div
                    animate={{ y: isHoverable ? "0" : "100%" }}
                    exit={{ y: "100%" }}
                    initial={{ y: "100%" }}
                    transition={{
                      duration: 0.64,
                      ease: Easings.easeOutCubic,
                    }}
                  >
                    #{industry}
                  </motion.div>
                </div>

                <div className={classes.borderWrapper}>
                  <motion.div
                    className={classes.border}
                    animate={isHoverable ? "visible" : "hidden"}
                    initial="hidden"
                    variants={borderVariants}
                  />
                </div>
              </React.Fragment>
            )}
          </Flex>
        </motion.li>
      )}
    </Modal>
  );
}

ListItem.propTypes = {
  appearMotionPropVariants: PropTypes.object,
  exitAnimation: PropTypes.string,
  hideFooter: PropTypes.bool,
  idx: PropTypes.number,
  listItemData: PropTypes.object,
  withHover: PropTypes.bool,
  onModalClose: PropTypes.func,
  onModalShow: PropTypes.func
};

ListItem.defaultProps = {
  appearMotionPropVariants: {},
  exitAnimation: "exit",
  hideFooter: false,
  idx: 0,
  listItemData: {},
  withHover: false,
  onModalClose: null,
  onModalShow: null
};

export default ListItem;
