import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import ContextMenu from '../ContextMenu';
import contextMenuClasses from '../ContextMenu/ContextMenu.module.scss';
import Dropdown, { DropdownToggle } from '../Dropdown';
import { Flex } from '../Flex';
import detailClasses from './Details.module.scss';
import classes from './Services.module.scss';

function Opener({
  isDropdownVisible,
  serviceCount,
  theme
}) {
  const fill = useMemo(() => (
    isDropdownVisible ? 'var(--info-icon-hover, #fff)' : 'var(--info-icon, #565656)'
  ), [isDropdownVisible]);

  // const color = useMemo(() => (
  //   isDropdownVisible ? '#fff' : '#939393'
  // ), [isDropdownVisible]);

  return (
    <Flex
      alignItems="center"
      className={classes.opener}
    >
      <div className={detailClasses.label}>
        Services:
      </div>

      <div className={detailClasses.value}>
        <span>{serviceCount}</span>

        <svg
          data-popover-open={isDropdownVisible ? '' : null}
          className={classes.icon}
          fill="none"
          version="1.1"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7" />
          <path d="M8.577 5.011v-1.05H7.423v1.05zm0 7.027V6.566H7.423v5.472z" />
        </svg>
      </div>
    </Flex>
  );
}
const Services = React.forwardRef((
  {
    services,
    theme
  },
  ref
) => {
  return (
    <Dropdown
      opener={DropdownToggle}
      openerProps={{
        children: Opener,
        childrenProps: { serviceCount: services.length, theme },
      }}
      popoverProps={{
        event: isMobile ? 'click' : 'hover',
        offset: [10, 10],
        placement: 'top'
      }}
    >
      <ContextMenu
        className={classNames([
          classes.contextMenu
        ])}
        menuItems={services.map((item, idx) => ({
          render: () => (
            <div
              className={classNames([
                contextMenuClasses.item,
                classes.contextMenuItem
              ])}
            >
              {idx + 1}. {item}
            </div>
          )
        }))}
        style={{
          '--details-popup-text': theme?.detailsPopupText,
          '--details-popup-bg': theme?.detailsPopupBg
        }}
      />
    </Dropdown>
  )
});

Services.displayName = 'Services';

Services.propTypes = {
    services: PropTypes.arrayOf(PropTypes.string),
    theme: PropTypes.object
  };

Services.defaultProps = {
  services: [],
  theme: {}
};

export default Services
