import React, { useMemo } from 'react';
import { defaultSiteConfigurationContextValue, useSiteConfiguration } from '../contexts/siteConfigurationContext';
import { useCurrentTime } from '../hooks';
import { Analog, Digital } from './Clock';
import './Time.scss';

function Time({ className }) {
  const currentTime = useCurrentTime();

  const {
    businessHours = defaultSiteConfigurationContextValue.businessHours
  } = useSiteConfiguration();

  const isOpen = useMemo(() => {
    return businessHours.days.includes(currentTime.w) && (
      currentTime.h >= businessHours.hours[0] && currentTime.h < businessHours.hours[1]
    );
  }, [businessHours, currentTime.h, currentTime.w]);

  const computedStyle = useMemo(() => ({
    backgroundColor: isOpen ? '#00ef5f' : '#f90000'
  }), [isOpen]);

  return (
    <div className={"Time " + className}  >
      <div className="Time__indicator">
        (&nbsp;<span style={computedStyle}></span>&nbsp;)
      </div>

      <div className="Time__label">
        {isOpen ? 'Open' : 'Close'}
      </div>

      <div className="Time__separator" />

      <div className="Time__digital">
        <Digital time={currentTime} />
      </div>

      <div className="Time__analog">
        <Analog time={currentTime} />
      </div>
    </div>
  );
}

export default Time;
