import React, { useRef, useEffect } from "react";
import { useMedia } from "react-recipes";
import { A11y, Autoplay, EffectCreative } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

import ImageRegular from "../Media/ImageRegular";

import { gsap, ScrollTrigger, useGSAP } from "../../../gsap";

SwiperCore.use([A11y, Autoplay, EffectCreative]);

const Slider = (props) => {
  let el = useRef();
  let elSwiper = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const params = {
    slidesPerView: 1,
    grabCursor: false,
    allowTouchMove: false,
    spaceBetween: 0,
    speed: 1,
    centeredSlides: true,
    centeredSlidesBounds: true,
    effect: "creative",
    creativeEffect: {
      prev: {
        translate: [0, 0, -1],
      },
      next: {
        translate: [0, 0, 0],
      },
    },
    autoplay: {
      delay: props.speed || 1200,
      disableOnInteraction: false,
    },
    onSwiper: (swiper) => {
      elSwiper.current = swiper;
    },
  };

  useEffect(() => {
    if (!elSwiper.current) return;
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: el.current,
        start: "top-=2000px bottom+=200px",
        onEnter: () => {
          elSwiper.current.autoplay.start();
        },
        onEnterBack: () => {
          elSwiper.current.autoplay.start();
        },
        onLeave: () => {
          elSwiper.current.autoplay.stop();
        },
        onLeaveBack: () => {
          elSwiper.current.autoplay.stop();
        },
      });
    }, elSwiper);

    return () => ctx.revert();
  }, []);

  useGSAP(
    () => {
      if (props.noAnimated) return;
      gsap.from(el.current, {
        delay: props.delay || 0,
        y: 35,
        ease: "customEase2",
        scrollTrigger: {
          trigger: el.current,
        },
      });
    },
    {
      dependencies: [props.delay, props.noAnimated, isMobile],
      scope: el,
    }
  );

  return (
    <div className={props.className} ref={el}>
      <Swiper {...params} ref={elSwiper}>
        {props.slides.map((slide, index) => (
          <SwiperSlide key={index} zindex={props.slides.length - 1 - index}>
            <div style={{ "--aspect": props.aspect || "16/9" }}>
              <ImageRegular src={slide.src} noAnimated={true} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
