import { motion, useMotionValue, useSpring } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Easings } from '../../animation';
import { useContainer } from '../Container';
import { MotionImage } from '../Image';

function TransformableImage({
  alt,
  className,
  clientY,
  preSrc,
  src,
  x,
  y
}) {
  /** @type {import('react').MutableRefObject<HTMLImageElement} */
  const el = useRef(null);
  const { bounds } = useContainer();
  const mX = useSpring(x, { damping: 150, stiffness: 1000 });
  const mY = useSpring(y, { damping: 150, stiffness: 1000 });
  const rotate = useMotionValue(0);

  useEffect(() => {
    const rect = el.current.getBoundingClientRect();
    const yOff = rect.height / 2;
    let yy = y - yOff;

    if (clientY - bounds.top - yOff < 0) {
      yy -= clientY - bounds.top - yOff;
    }
    else if (clientY + yOff > bounds.top + bounds.height) {
      yy -= (clientY + yOff) - (bounds.top + bounds.height)
    }

    mX.set(x + rect.width / 10);
    mY.set(yy);
  }, [
    bounds,
    clientY,
    mX,
    mY,
    x,
    y
  ]);

  useEffect(() => {
    function setRotation() {
      rotate.set(mX.getVelocity() / 150);
    }

    const unsubscribe = mX.on('change', setRotation);

    return () => {
      unsubscribe();
    }
  }, [mX, rotate]);

  return (
    <motion.div
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        rotate,
        x: mX,
        y: mY,
        zIndex: -1
      }}
    >
      <motion.div
        animate={{
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
          transition: {
            delay: 0.12,
            ease: Easings.easeOutCubic,
          }
        }}
        exit={{
          clipPath: 'polygon(100% 0, 100% 1%, 100% 100%, 100% 100%)',
          transition: {
            ease: Easings.easeOutCubic
          }
        }}
        initial={{
          clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)'
        }}
        style={{
          position: 'relative',
        }}
      >
        <img
          ref={el}
          alt={alt}
          className={className}
          src={src}
          style={{
            visibility: 'collapse'
          }}
        />
        <MotionImage
          alt={alt}
          animate={{
            scale: 1,
            transition: {
              delay: 0.12,
              duration: 0.64,
              ease: Easings.easeOutCubic
            }
          }}
          delay={250}
          initial={{ scale: 1.6 }}
          preSrc={preSrc}
          src={src}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            originX: '0%'
          }}
        />
      </motion.div>
    </motion.div>
  );
}

TransformableImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  clientY: PropTypes.number.isRequired,
  preSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};

TransformableImage.defaultProps = {
  alt: '',
  className: null
};

export default TransformableImage;
