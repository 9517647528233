import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { Easings } from '../../animation';
import classes from './CardType.module.scss';
import { OnRequestIcon } from './constants';

/** @type {import('framer-motion').Variants} */
const BracketVariants = {
  show: ([{ position } = {}]) => ({
    [position]: '-5%',
    transition: {
      ease: Easings.circInOut,
      duration: 0.8
    }
  }),
  hide: ([{ position } = {}]) => ({
    [position]: '50%',
    transition: {
      ease: Easings.circInOut,
      duration: 0.4,
      delay: 0.32
    }
  })
};

/** @type {import('framer-motion').Variants} */
const RevealVariants = {
  show: () => ({
    y: '0%',
    transition: {
      delay: 0.48,
      duration: 0.32,
      ease: 'easeOut'
    }
  }),
  hide: () => ({
    y: 'calc(100% + 8px)',
    transition: {
      delay: 0,
      duration: 0.32,
      ease: 'easeOut'
    }
  })
};

/** @type {import('framer-motion').Variants} */
const RevealIconVariants = {
  initial: () => ({
    y: 'calc(100% + 8px)',
    scale: 1,
  }),
  show: () => ({
    y: '0%',
    scale: 1,
    transition: {
      delay: 0.48,
      duration: 0.32,
      ease: 'easeOut'
    },
    transformOrigin: 'bottom center'
  }),
  hide: () => ({
    scale: 0,
    y: 'calc(100% + 8px)',
    transition: {
      delay: 0,
      duration: 0.8,
      ease: Easings.easeOutCubic
    },
    transformOrigin: 'center'
  })
};

export const CardType = (props) => {
  const { children, icon = OnRequestIcon } = props;

  const internalIcon = useMemo(() => {
    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, {
        className: classes.icon,
        variants: RevealIconVariants,
        initial: 'initial',
        animate: 'show',
        exit: 'hide'
      });
    }

    return null;
  }, [icon]);

  return (
    <div className={classes.root}>
      {internalIcon}
      <div className={classes.text}>
        <motion.div
          animate="show"
          className={classes.bracket}
          custom={[{ position: 'left' }]}
          exit="hide"
          initial="hide"
          variants={BracketVariants}
        >
          (
        </motion.div>

        <div style={{ overflow: 'hidden' }}>
          <motion.div
            animate="show"
            exit="hide"
            initial="hide"
            variants={RevealVariants}
          >
            &nbsp;{children}&nbsp;
          </motion.div>
        </div>

        <motion.div
          animate="show"
          className={classes.bracket}
          custom={[{ position: 'right' }]}
          exit="hide"
          initial="hide"
          variants={BracketVariants}
        >
          )
        </motion.div>
      </div>
    </div>
  );
}
