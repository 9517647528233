import React, { useRef } from "react";
import styled from "styled-components";

import { gsap, useGSAP } from "../../../gsap";

const Wrapper = styled.div`
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor};
`;

const Box = (props) => {
  let el = useRef();

  useGSAP(
    () => {
      if (props.noAnimated) return;
      gsap.from(el.current, {
        delay: props.delay || 0,
        y: 35,
        ease: "customEase2",
        scrollTrigger: {
          trigger: el.current,
        },
      });
    },
    {
      dependencies: [props.delay, props.noAnimated],
      scope: el,
    }
  );

  return (
    <Wrapper
      ref={el}
      className={props.className}
      width={props.width}
      backgroundColor={props.backgroundColor}
      margin={props.margin}
      padding={props.padding}
      style={{ "--aspect": props.aspect }}
    >
      {props.children}
    </Wrapper>
  );
};

export default Box;
