import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Durations, Easings } from '../../animation';
import { Flex } from '../Flex';
import Split from '../Split';
import classes from './Quote.module.scss';
import QuoteDecorator from './QuoteDecorator';
import { useMedia } from 'react-recipes';

const splitOptions = {
  html_boundaries: true,
  sanitize: true
};

function Quote({
  children,
  className
}) {
  const variants = useMemo(() => ({
    enter: ([{ idx, delay }]) => ({
      y: '0%',
      transition: {
        delay: (delay ?? 0) + (idx ?? 0) * Durations.gap,
        duration: Durations.base,
        ease: Easings.easeOutCubic
      }
    }),
    exit: {
      y: '120%'
    }
  }), []);

  const isMobile = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  // const _text = useMemo(() => (
  //   !isMobile ? children : children?.replace(/<br\s*\/?>/gi, ' ')
  // ), [children, isMobile]);

  const _text = children;

  return (
    <div className={className}>
      <QuoteDecorator
        className={classes.decorator}
        motionProps={useMemo(() => ({
          custom: [{ delay: 0.4 }],
          variants,
        }), [variants])}
      />

      <Flex
        className={classes.content}
        direction="column"
      >

        <Split
          byChars={false}
          options={splitOptions}
          text={_text}
        >
          {(lines) => (
            lines.map((line, idx) => (
              <div
                key={idx}
                className={classes.line}
                direction="column"
              >
                <motion.div
                  animate="enter"
                  custom={[{ idx, delay: 0.5 }]}
                  initial="exit"
                  variants={variants}
                >
                  {line}
                </motion.div>
              </div>
            ))
          )}
        </Split>
      </Flex>
    </div>
  )
}

Quote.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string
};

Quote.defaultProps = {
  children: null,
  className: null
};

export default Quote;
