/**
 * Checks if the given element has a parent element with the given class name.
 * @param {HTMLElement|null} element
 * @param {string|string[]} className
 * @returns {boolean}
 */
export function hasParentElementWithClass(element, className) {
  if (!(element instanceof HTMLElement)) {
    return false;
  }

  const classes = Array.isArray(className) ? className : [className];
  let currentElement = element;

  while (currentElement) {
    // eslint-disable-next-line no-loop-func
    if (classes.some((name) => currentElement.classList.contains(name))) {
      return true;
    }

    currentElement = currentElement.parentElement;
  }

  return false;
}

/**
 * Checks if the given element has a parent element.
 * @param {HTMLElement|null} element
 * @param {HTMLElement|null} targetElement
 * @returns {boolean}
 */
export function hasParentElement(element, targetElement) {
  if (!(element instanceof HTMLElement) || !(targetElement instanceof HTMLElement)) {
    return false;
  }

  let currentElement = element;

  while (currentElement) {
    if (currentElement.parentElement === targetElement) {
      return true;
    }

    currentElement = currentElement.parentElement;
  }

  return false;
}

/**
 * @param {Window|Document|HTMLElement|EventTarget|null} obj
 * @param  {...any} args
 */
export function on(obj, ...args) {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...args);
  }
}

/**
 * @param {Window|Document|HTMLElement|EventTarget|null} obj
 * @param  {...any} args
 */
export function off(obj, ...args) {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...args);
  }
}

/**
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}


/**
 * Returns an array of unique random integers within a specified range.
 *
 * @param {number} min - The minimum value of the range (inclusive).
 * @param {number} max - The maximum value of the range (exclusive).
 * @param {number} range - The number of integers to return.
 * @returns {number[]} An array of unique random integers within the specified range.
 */
export function getRandomIntRange(min, max, range) {
  const set = new Set();

  while (set.size < range) {
    set.add(getRandomInt(min, max));
  }

  return Array.from(set);
}

export const UnicodeCircleNumberMap = [
  0x24EA, 0x2460, 0x2461, 0x2462, 0x2463,
  0x2464, 0x2465, 0x2466, 0x2467, 0x2468,
  0x2469, 0x246A, 0x246B, 0x246C, 0x246D,
  0x246E, 0x246F, 0x2470, 0x2471, 0x2472,
  0x2473, 0x2469, 0x246A, 0x246B, 0x246C,
  0x246D, 0x246E, 0x246F, 0x2470, 0x2471
];

export const SvgCircleNumberMap = [
  0,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm5.696-1.264H7.792V6.576H6.144v-.944h.56c.736 0 1.152-.288 1.248-.848L8 4.496h1.024v6.832Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm7.44-1.472h-5.28c0-1.44.56-2.432 1.68-2.976l1.36-.656c.656-.32.976-.72.976-1.216 0-.688-.432-1.04-1.312-1.04-.976 0-1.264.432-1.312 1.312H5.6c.048-1.472.912-2.4 2.592-2.4 1.552 0 2.592.784 2.592 2.128 0 .96-.528 1.6-1.696 2.16l-.832.4c-.832.4-1.184.656-1.264 1.184h3.776v1.104Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm4.848-1.136c-1.712 0-2.768-.816-2.768-2.272h1.28c.032.816.528 1.232 1.504 1.232s1.456-.352 1.456-1.072c0-.688-.48-1.04-1.44-1.04h-.624V7.2h.624c.8 0 1.2-.32 1.2-.944 0-.656-.448-.992-1.328-.992-.88 0-1.328.352-1.376 1.072h-1.28c0-1.328 1.024-2.112 2.64-2.112s2.624.752 2.624 1.92c0 .752-.4 1.312-1.088 1.504v.032c.816.24 1.328.848 1.328 1.696 0 1.232-1.072 2.08-2.752 2.08Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm6.32-1.472H8.416V9.552l-3.312.032V8.512l3.232-4.224h1.312v4.336l1.216-.032v.992l-1.216-.032v1.568ZM6.192 8.592l2.224.032V5.728h-.064l-2.16 2.864Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm4.8-1.136c-1.392 0-2.368-.576-2.704-1.664l1.264-.272c.208.592.688.88 1.456.88.96 0 1.52-.48 1.52-1.296 0-.832-.56-1.36-1.52-1.36-.544 0-.96.16-1.264.496L5.6 7.968l.688-3.616h4.144v1.056H7.168L6.88 7.136l.032.032c.4-.272.912-.416 1.536-.416 1.616 0 2.496.96 2.496 2.288 0 1.392-1.008 2.416-2.816 2.416Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm4.88-1.264c-1.92 0-2.864-1.264-2.864-3.44 0-2.416 1.008-3.792 2.928-3.792 1.216 0 2.112.544 2.448 1.424l-1.216.336c-.24-.48-.656-.72-1.248-.72-1.072 0-1.632.752-1.712 2.272.432-.496 1.072-.736 1.888-.736 1.488 0 2.48.848 2.48 2.272 0 1.472-1.024 2.384-2.704 2.384Zm.016-1.024c.912 0 1.44-.496 1.44-1.312 0-.4-.128-.72-.368-.96-.256-.24-.608-.352-1.072-.352-.464 0-.832.112-1.104.336a1.17 1.17 0 0 0-.416.912c0 .896.64 1.376 1.52 1.376Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm4.784-1.184H6.816c.24-2.48 1.12-4.416 2.64-5.776H5.68V4.576h5.296v1.008c-1.728 1.36-2.608 3.072-2.864 5.824Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm6.848-1.808c-.512.368-1.216.56-2.112.56-.896 0-1.6-.192-2.112-.56-.512-.368-.768-.88-.768-1.536 0-.832.544-1.488 1.424-1.728v-.032c-.72-.272-1.072-.768-1.072-1.472 0-1.104.976-1.792 2.528-1.792s2.528.688 2.528 1.792c0 .704-.352 1.2-1.072 1.472v.032c.896.24 1.424.88 1.424 1.728 0 .656-.256 1.168-.768 1.536ZM6.768 6.128c0 .64.432.96 1.296.96.864 0 1.296-.32 1.296-.96 0-.624-.432-.928-1.296-.928-.864 0-1.296.304-1.296.928ZM6.48 9.184c0 .784.528 1.184 1.584 1.184s1.584-.4 1.584-1.184c0-.768-.528-1.152-1.584-1.152S6.48 8.416 6.48 9.184Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm4.704-1.072c-1.216 0-2.112-.544-2.448-1.424L6.8 9.76c.24.48.656.72 1.248.72 1.072 0 1.632-.752 1.712-2.272-.432.496-1.072.736-1.888.736-1.488 0-2.48-.848-2.48-2.272 0-1.472 1.024-2.384 2.704-2.384 1.92 0 2.864 1.264 2.864 3.44 0 2.416-1.008 3.792-2.928 3.792ZM7.024 7.584c.24.24.592.352 1.056.352.464 0 .832-.112 1.104-.352.272-.224.416-.528.416-.896 0-.896-.64-1.376-1.52-1.376-.912 0-1.44.496-1.44 1.312 0 .4.128.72.384.96Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path fillRule="evenodd" clipRule="evenodd" d="M8.064 15.744c2.32 0 4.256-.736 5.776-2.24 1.52-1.488 2.288-3.36 2.288-5.616s-.768-4.144-2.288-5.632C12.32.768 10.384.032 8.064.032S3.808.768 2.288 2.256C.768 3.744 0 5.616 0 7.888c0 2.256.768 4.128 2.288 5.616 1.52 1.504 3.456 2.24 5.776 2.24Zm0-1.28c-1.904 0-3.488-.624-4.736-1.872-1.264-1.232-1.888-2.8-1.888-4.704s.624-3.472 1.888-4.72C4.576 1.936 6.16 1.312 8.064 1.312s3.488.624 4.752 1.856c1.248 1.248 1.872 2.816 1.872 4.72s-.624 3.472-1.872 4.704c-1.264 1.248-2.848 1.872-4.752 1.872Zm1.68-3.008c-1.92 0-2.944-1.264-2.944-3.616 0-2.352 1.024-3.616 2.944-3.616 1.936 0 2.96 1.264 2.96 3.616 0 2.352-1.024 3.616-2.96 3.616ZM8.528 9.744c.256.384.656.576 1.216.576.56 0 .96-.192 1.232-.592.272-.384.4-1.008.4-1.888 0-.88-.128-1.504-.4-1.904-.272-.384-.672-.576-1.232-.576-.56 0-.96.192-1.216.576-.272.4-.4 1.024-.4 1.904 0 .88.128 1.52.4 1.904ZM5.88 11.328H4.648V6.576H3v-.944h.56c.736 0 1.152-.288 1.248-.848l.048-.288H5.88v6.832Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path fillRule="evenodd" clipRule="evenodd" d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm3.528-1.264H5.624V6.576H3.976v-.944h.56c.736 0 1.152-.288 1.248-.848l.048-.288h1.024v6.832Zm2.992 0h1.232V4.496h-1.024l-.048.288c-.096.56-.512.848-1.248.848H8.2v.944h1.648v4.752Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path fillRule="evenodd" clipRule="evenodd" d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm3.648-1.472h5.28v-1.104H8.48c.08-.528.432-.784 1.264-1.184l.832-.4c1.168-.56 1.696-1.2 1.696-2.16 0-1.344-1.04-2.128-2.592-2.128-1.68 0-2.544.928-2.592 2.4h1.28c.048-.88.336-1.312 1.312-1.312.88 0 1.312.352 1.312 1.04 0 .496-.32.896-.976 1.216l-1.36.656c-1.12.544-1.68 1.536-1.68 2.976Zm-2.352.208h1.232V4.496H4.832l-.048.288c-.096.56-.512.848-1.248.848h-.56v.944h1.648v4.752Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path fillRule="evenodd" clipRule="evenodd" d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm3.448-3.408c0 1.456 1.056 2.272 2.768 2.272 1.68 0 2.752-.848 2.752-2.08 0-.848-.512-1.456-1.328-1.696v-.032c.688-.192 1.088-.752 1.088-1.504 0-1.168-1.008-1.92-2.624-1.92s-2.64.784-2.64 2.112h1.28c.048-.72.496-1.072 1.376-1.072.88 0 1.328.336 1.328.992 0 .624-.4.944-1.2.944h-.624v1.104h.624c.96 0 1.44.352 1.44 1.04 0 .72-.48 1.072-1.456 1.072S8.088 10 8.056 9.184h-1.28Zm-2.152 2.144h1.232V4.496H4.832l-.048.288c-.096.56-.512.848-1.248.848h-.56v.944h1.648v4.752Z" fill="#fff"/></svg>,
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path fillRule="evenodd" clipRule="evenodd" d="M13.84 13.504c-1.52 1.504-3.456 2.24-5.776 2.24s-4.256-.736-5.776-2.24C.768 12.016 0 10.144 0 7.888c0-2.272.768-4.144 2.288-5.632C3.808.768 5.744.032 8.064.032s4.256.736 5.776 2.224c1.52 1.488 2.288 3.376 2.288 5.632s-.768 4.128-2.288 5.616Zm-10.512-.912c1.248 1.248 2.832 1.872 4.736 1.872s3.488-.624 4.752-1.872c1.248-1.232 1.872-2.8 1.872-4.704s-.624-3.472-1.872-4.72c-1.264-1.232-2.848-1.856-4.752-1.856s-3.488.624-4.736 1.856C2.064 4.416 1.44 5.984 1.44 7.888s.624 3.472 1.888 4.704Zm8.116-1.472h-1.232V9.552L6.9 9.584V8.512l3.232-4.224h1.312v4.336l1.216-.032v.992l-1.216-.032v1.568ZM7.988 8.592l2.224.032V5.728h-.064l-2.16 2.864Zm-3.364 2.736h1.232V4.496H4.832l-.048.288c-.096.56-.512.848-1.248.848h-.56v.944h1.648v4.752Z" fill="#fff"/></svg>
];

/**
 * Returns the display name of a React component.
 *
 * @param {React.ComponentType} Component - The component to get the display name from.
 * @returns {string} The display name of the component.
 */
export const getDisplayName = (Component) => {
  return Component.displayName || Component.name || 'Component';
};

/**
 * @template {unknown[]} Args
 * @param {(...args: Args) => void} callback1
 * @param {(...args: Args) => void} callback2
 * @returns {(...args: Args) => void}
 */
export function mergeCallbacks(
  callback1,
  callback2
) {
  return (...args) => {
    callback1?.(...args);
    callback2?.(...args);
  }
}


