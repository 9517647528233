import React, { useContext } from 'react';

/**
 * @typedef {import('../api/siteConfiguration').SiteConfiguration} SiteConfigurationContextValue
 */

/** @type {SiteConfigurationContextValue} */
export const defaultSiteConfigurationContextValue = {
  businessInquiries: null,
  businessHours: {
    days: [1, 2, 3, 4],
    hours: [9, 17]
  }
};

const SiteConfigurationContext = React.createContext(null);

export const { Provider: SiteConfigurationProvider } = SiteConfigurationContext;

export const useSiteConfiguration = () => (
  useContext(SiteConfigurationContext) ?? defaultSiteConfigurationContextValue
);
