import React, { useRef } from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import pxToRem from "../Utils/pxToRem";
import AnimTextPerlineSpecial from "./Motion/TextperlineSpecial";

import { gsap, ScrollTrigger, useGSAP } from "../../gsap";
import Drag from "./Drag";

const Wrapper = styled.div`
  width: 100%;

  @media (max-width: 620px) {
    padding: ${pxToRem(60)} 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: ${(props) => props.borderColor || "currentColor"};
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 3}, 1fr);
  grid-column-gap: ${pxToRem(227)};
  grid-row-gap: 0;
  width: 100%;
  margin: 0;

  @media (max-width: 620px) {
    grid-column-gap: ${(props) => props.gapMobile || pxToRem(104)};
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${pxToRem(12)};
  padding: ${pxToRem(100)} 0;
  position: relative;
  width: 100%;
  max-width: ${pxToRem(270)};

  @media (max-width: 620px) {
    padding: 0;
    max-width: ${(props) => props.maxWidthMobile || pxToRem(188)};
  }

  &:nth-child(-n + ${(props) => props.columns || 3}) {
    justify-content: ${(props) =>
      props.items && props.items.length <= 3 ? "flex-end" : "flex-start"};
    padding-bottom: ${(props) =>
      props.items && props.items.length <= 3 ? pxToRem(100) : 0};
    padding-bottom: ${(props) =>
      props.items && props.items.length <= 3 ? 0 : pxToRem(100)};

    @media (max-width: 620px) {
      justify-content: center;
      padding-bottom: 0;
    }
  }

  &.active {
    &:not(:nth-child(3)):not(:nth-child(6))::after {
      transform: scaleY(1);

      @media (max-width: 620px) {
        transform: scaleY(0.8);
        transform-origin: center;
      }
    }

    &:nth-child(-n + 3)::before {
      transform: scaleX(1);
    }

    &:not(:last-child)::after {
      @media (max-width: 620px) {
        transform: scaleY(0.8);
        transform-origin: center;
      }
    }
  }

  &:not(:nth-child(3)):not(:nth-child(6))::after {
    content: "";
    position: absolute;
    top: 0;
    right: -${pxToRem(113)};
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: ${(props) => props.borderColor || "currentColor"};
    transform-origin: top;
    transform: scaleY(0);
    transition: transform var(--transition-default);

    @media (max-width: 620px) {
      right: ${(props) => {
        if (props.gapMobile) {
          const value = parseFloat(props.gapMobile);
          return `-${value / 2}rem`;
        }
        return `-${pxToRem(52)}`;
      }};
    }
  }

  @media (max-width: 620px) {
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 0;
      right: ${(props) => {
        if (props.gapMobile) {
          const value = parseFloat(props.gapMobile);
          return `-${value / 2}rem`;
        }
        return `-${pxToRem(52)}`;
      }};
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: ${(props) => props.borderColor || "currentColor"};
      transform-origin: top;
      transform: scaleY(0);
      transition: transform var(--transition-default);
    }
  }

  &:nth-child(-n + 3)::before {
    display: ${(props) =>
      props.items && props.items.length <= 3 ? "none" : ""};
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.borderColor || "currentColor"};
    z-index: 1;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform var(--transition-default);

    @media (max-width: 620px) {
      display: none;
    }
  }
`;

const Value = styled.h5`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(57)};
  letter-spacing: 0.01em;
  line-height: 1.098;
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${(props) => props.titleFontSizeMobile};
    line-height: 1.1;
  }
`;

const Label = styled.p`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, ${pxToRem(16)}, ${pxToRem(16)});
  letter-spacing: 0.03em;
  line-height: 1.375;
  margin-top: ${pxToRem(12)};

  @media screen and (max-width: 620px) {
    font-size: ${(props) => props.descFontSizeMobile};
  }
`;

const GridComponent = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();
  const itemRefs = useRef([]);
  const elGridContainer = useRef();

  // This function is used to assign a ref for each item
  const addItemRef = (el, index) => {
    itemRefs.current[index] = el;
  };

  useGSAP(
    () => {
      if (!itemRefs.current) return;
      ScrollTrigger.create({
        trigger: el.current,
        once: true,
        onEnter: () => {
          itemRefs.current.forEach((el, index) => {
            gsap.delayedCall(index * 0.15, () => {
              el.classList.add("active");
            });
          });
        },
      });
    },
    {
      scope: el,
    }
  );

  return (
    <Wrapper borderColor={props.borderColor} ref={el}>
      {isMobile ? (
        <Drag>
          <GridContainer
            columns={props.columns}
            rows={props.rows}
            gapMobile={props.gapMobile}
          >
            {props.items.map((item, index) => (
              <GridItem
                key={index}
                ref={(el) => addItemRef(el, index)}
                items={props.items}
                maxWidthMobile={props.maxWidthMobile}
                gapMobile={props.gapMobile}
                borderColor={props.borderColor}
              >
                <AnimTextPerlineSpecial delay={index * 0.15}>
                  <Value titleFontSizeMobile={props.titleFontSizeMobile}>
                    {item.value}
                  </Value>
                  <Label descFontSizeMobile={props.descFontSizeMobile}>
                    {item.label}
                  </Label>
                </AnimTextPerlineSpecial>
              </GridItem>
            ))}
          </GridContainer>
        </Drag>
      ) : (
        <GridContainer
          columns={props.columns}
          rows={props.rows}
          gapMobile={props.gapMobile}
          ref={elGridContainer}
        >
          {props.items.map((item, index) => (
            <GridItem
              key={index}
              ref={(el) => addItemRef(el, index)}
              items={props.items}
              maxWidthMobile={props.maxWidthMobile}
              gapMobile={props.gapMobile}
              borderColor={props.borderColor}
            >
              <AnimTextPerlineSpecial
                delay={index * 0.15}
                trigger={elGridContainer.current}
              >
                <Value titleFontSizeMobile={props.titleFontSizeMobile}>
                  {item.value}
                </Value>
                <Label descFontSizeMobile={props.descFontSizeMobile}>
                  {item.label}
                </Label>
              </AnimTextPerlineSpecial>
            </GridItem>
          ))}
        </GridContainer>
      )}
    </Wrapper>
  );
};

export default GridComponent;
