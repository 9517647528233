import axios from 'axios';
import { buildMemoryStorage, setupCache } from 'axios-cache-interceptor';

/** @type {import('axios-cache-interceptor').AxiosCacheInstance} */
const ResourceLoaderRequest = axios.create();

setupCache(ResourceLoaderRequest, {
  storage: buildMemoryStorage()
});

export const ResourceLoader = {
  get: async (url) => (
    await ResourceLoaderRequest.get(url, {
      responseType: 'blob',
      transformResponse: (blob) => {
        if (typeof blob === 'string' && blob.startsWith('blob:')) {
          return blob;
        };

        return URL.createObjectURL(blob);
      },
      cache: {
        interpretHeader: false
      }
    })
  )
};
