import React, { useState, useRef, useEffect } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCreative,
  EffectFade,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

import { gsap, CustomEase, useGSAP } from "../../../gsap";
import convertToCircledNumber from "../../Utils/convertToCircledNumber";
import LayoutFlex from "../Layout/Flex";

import Slideshow from "../Motion/Slideshow";

import Paragraph3 from "../Text/Paragraph3";
import ImageRegular from "../Media/ImageRegular";
import Grid from "../Grid";
import GridItem from "../GridItem";
import Border from "../Border";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCreative,
  EffectFade,
]);

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  user-select: none;
  cursor: pointer;
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};

  .swiper-wrapper {
    order: ${(props) => (props.paginationTop ? "2" : undefined)};
  }

  .swiper-pagination-wrapper {
    position: relative;
    align-self: ${(props) => props.paginationAlignSelf};

    @media (max-width: 620px) {
      display: ${(props) => (props.paginationMobile ? "flex" : "none")};
      flex-direction: column;
    }
  }

  .swiper-pagination-flex {
    position: relative;

    @media (max-width: 620px) {
      order: 2;
    }
  }

  .swiper-pagination {
    width: auto;
    position: relative;
    font-family: ${(props) =>
      props.paginationTitle ? "Suisse Intl" : "Helvetica Now Text"};
    font-weight: ${(props) => (props.paginationTitle ? 900 : 800)};
    font-size: ${(props) =>
      props.paginationTitle ? pxToRem(12) : pxToRem(18)};
    letter-spacing: ${(props) => (props.paginationTitle ? "0.04em" : "")};
    line-height: normal;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ${(props) => (props.paginationTitle ? pxToRem(64) : pxToRem(40 - 5))};
    list-style: none;
    padding: 0;
    text-transform: uppercase;
  }

  .swiper-pagination-bullet {
    width: auto;
    height: auto;
    background: none;
    border-radius: 0;
    color: ${(props) => props.bulletHighlightColor || "currentColor"};
    cursor: pointer;
    width: ${(props) => (props.paginationTitle ? "auto" : pxToRem(18))};
    padding-bottom: ${(props) => (props.paginationTitle ? "" : pxToRem(12))};
    margin-bottom: ${(props) => (props.paginationTitle ? pxToRem(16) : "")};
    opacity: 0.25;
    transition: opacity 0.3s ease;
    position: relative;

    &:not(.swiper-pagination-bullet-active):hover {
      opacity: 0.5;
    }

    span {
      opacity: 0.25;
      transition: opacity 0.3s ease;
      position: relative;
    }

    &:not(:last-child) {
      &::after {
        display: ${(props) => (props.paginationTitle ? "" : "none")};
        content: "";
        position: absolute;
        right: -${pxToRem(31)}; // half of the gap
        top: 0;
        width: 1px;
        height: 100%;
        background-color: currentColor;
        opacity: 1;
        transition: opacity 0.1ms step-start;
      }
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;

    &:not(:last-child) {
      &::after {
        opacity: 0.25;
      }
    }
  }

  .swiper-pagination-active {
    position: absolute;
    left: 0;
    width: 100%;
    height: clamp(2px, ${pxToRem(3)}, 3px);
    bottom: 0;
    z-index: 2;
    background-color: ${(props) =>
      props.bulletHighlightColor || "currentColor"};
    transition: background-color 0.3s ease;
    will-change: transform;
  }

  .swiper {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-cursor {
    position: absolute;
    top: 50%;
    left: 80%;
    width: ${pxToRem(84)};
    height: ${pxToRem(84)};
    border-radius: 100%;
    background-color: ${(props) => props.cursorColor || "#000"};
    z-index: 2;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: scale(0);

    @media (max-width: 620px) {
      display: none;
    }

    .swiper-cursor-inner {
      font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
      font-weight: 700;
      font-size: ${pxToRem(8)};
      letter-spacing: 0.07em;
      line-height: normal;
      overflow: hidden;
      width: 100%;
      color: ${(props) => props.cursorColorText || "#fff"};
      position: relative;
      text-transform: uppercase;
      padding-top: 1px;
    }

    .swiper-cursor-text {
      display: block;
      transition: transform var(--transition-default);

      &.prev {
        position: relative;
        transform: translateY(-140%);

        &.active {
          transform: translateY(0%);
        }
      }

      &.next {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 105%);

        &.active {
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;

// Fake box
const Content = styled.div`
  padding-top: ${(props) => (props.paginationTop ? undefined : pxToRem(50))};
  padding-bottom: ${(props) => (props.paginationTop ? pxToRem(50) : undefined)};
  margin-top: -${(props) => (props.paginationTop ? undefined : pxToRem(50))};
  margin-bottom: -${(props) => (props.paginationTop ? pxToRem(50) : undefined)};
  padding-right: ${pxToRem(100)};
  padding-left: ${pxToRem(100)};
  margin-right: ${pxToRem(-100)};
  margin-left: ${pxToRem(-100)};
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
`;

const StyledSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column;
`;

const StyledLayoutFlex = styled(LayoutFlex)`
  order: ${(props) => (props.paginationTop ? "1" : undefined)};
  margin: ${(props) => props.paginationMargin || `${pxToRem(17)} 0 0 0`};
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 100%;
  height: 100%;

  .slidergrid {
    margin-top: ${pxToRem(60)};

    @media (max-width: 620px) {
      margin-top: ${pxToRem(2.5)};
      display: flex;
      flex-direction: row;
      gap: ${pxToRem(7)};
    }

    .slidergrid-item {
      @media (max-width: 620px) {
        width: ${pxToRem(86)};
      }
    }

    .border {
      @media (max-width: 620px) {
        display: none;
      }
    }
  }

  .textwrapper {
    box-sizing: border-box;
    padding: ${pxToRem(90)} ${pxToRem(90)} ${pxToRem(240)} ${pxToRem(90)};
    overflow: hidden;
    background-color: #ffffff;

    @media (max-width: 620px) {
      padding: ${pxToRem(19)} ${pxToRem(19)} ${pxToRem(50)} ${pxToRem(19)};
    }

    svg {
      @media (max-width: 620px) {
        width: 10px;
        height: 10px;
      }
    }

    p {
      text-transform: uppercase;

      @media (max-width: 620px) {
        font-size: ${pxToRem(6)};
      }
    }
  }
`;

const Slider = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [area, setArea] = useState("right");
  // const [slideIndex, setSlideIndex] = useState(0);
  const [paginationHover, setPaginationHover] = useState(false);
  // const [firstEnter, setFirstEnter] = useState(false);

  let el = useRef();
  let elPaginationWrapper = useRef();
  let elPagination = useRef();
  let elPaginationActive = useRef();
  const elCursor = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const assetsPath = isMobile
    ? "/img/projects/kollabo/mobile"
    : "/img/projects/kollabo/desktop";

  const params = {
    slidesPerView: 1,
    grabCursor: false,
    spaceBetween: props.spaceBetweenM || 10,
    speed: isMobile ? 300 : 1200,
    rewind: false,
    effect: props.fade ? "fade" : "slide",
    roundLengths: true,
    threshold: 0.2,
    resistanceRatio: 0.1,
    touchRatio: 1.5,
    touchAngle: 60,
    touchEventsTarget: props.paginationTitle ? "wrapper" : "container",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        if (props.paginationTitle) {
          return `<span class="${className} pagination-index-${index}" data-text="${props.slides[index].paginationTitle}">${props.slides[index].paginationTitle}</span>`;
        } else {
          return `<span class="${className} pagination-index-${index}" data-text="${
            index + 1
          }">${index + 1}</span>`;
        }
      },
    },
    breakpoints: {
      // when window width is >= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: props.spaceBetween || 0,
        rewind: true,
        followFinger: false,
        allowTouchMove: false,
      },
    },
  };

  const { contextSafe } = useGSAP({ scope: el });

  const paginationChange = (init = false) => {
    const paginationItem = [
      ...elPagination.current.querySelectorAll(".swiper-pagination-bullet"),
    ];
    const paginationActive = elPagination.current.querySelector(
      ".swiper-pagination-bullet-active"
    );

    if (!paginationActive) return;

    if (!props.paginationTitle) {
      paginationItem.forEach((item) => {
        if (item.classList.contains("swiper-pagination-bullet-active")) return;
        item.textContent = item.dataset.text;
      });

      paginationActive.textContent = convertToCircledNumber(
        paginationActive.textContent
      );
    }

    const getWidth = paginationActive.offsetWidth;
    const getLeft = paginationActive.offsetLeft;

    if (init) {
      // slide init
      gsap.set(elPaginationActive.current, {
        width: getWidth,
        left: getLeft,
      });
    } else {
      // slide chage
      gsap.to(elPaginationActive.current, {
        width: getWidth,
        left: getLeft,
        duration: 1.2,
        ease: CustomEase.create("custom", "M0,0 C0.33,0.22 0,1.01 1,1"),
      });
    }
  };

  const slideInit = (el) => {
    setSwiper(el);
    paginationChange(true);
  };

  const slideChange = (el) => {
    paginationChange();
    // setSlideIndex(el.activeIndex);
  };

  const handleMouseMove = contextSafe((event) => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (paginationHover) return;

    const elRect = el.current.getBoundingClientRect();
    const cursorRect = elCursor.current.getBoundingClientRect();

    const x = event.clientX - elRect.left - cursorRect.width / 2;
    const y = event.clientY - elRect.top - cursorRect.height / 2;

    // if (!firstEnter) {
    //   gsap.set(elCursor.current, {
    //     left: x,
    //     top: y,
    //   });

    //   setFirstEnter(true);
    // }

    gsap.to(elCursor.current, {
      scale: 1,
      left: x,
      top: y,
      duration: 0.6,
      delay: 0.01,
      ease: "power4.out",
    });

    if (x < elRect.width / 2) {
      // Left area
      setArea("left");
    } else {
      // Right area
      setArea("right");
    }
  });

  const handleMouseClick = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (paginationHover) return;

    const tl = gsap.timeline({
      defaults: {
        duration: 0.2,
        ease: "power1.out",
      },
    });

    tl.to(elCursor.current, {
      scale: 0.8,
    });

    tl.to(elCursor.current, {
      scale: 1,
    });

    if (area === "left") {
      // Left area
      if (swiper) {
        swiper.slidePrev();
      }
    } else {
      // Right area
      if (swiper) {
        swiper.slideNext();
      }
    }
  });

  const handleMouseEnter = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 1,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
    });
  });

  const handleMouseLeave = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 0,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
      // onComplete: () => {
      //   setFirstEnter(false);
      // },
    });
  });

  const handlePaginationHover = contextSafe(() => {
    if (isMobile) return;
    if (paginationHover) return;
    setPaginationHover(true);
    setTimeout(() => {
      handleMouseLeave();
    }, 1);
  });

  const handlePaginationLeave = contextSafe(() => {
    if (isMobile) return;
    if (!paginationHover) return;
    setPaginationHover(false);
  });

  return (
    <Wrapper
      ref={el}
      padding={props.padding}
      backgroundColor={props.backgroundColor}
      bulletHighlightColor={props.bulletHighlightColor}
      cursorColor={props.cursorColor}
      paginationTop={props.paginationTop}
      paginationTitle={props.paginationTitle}
      paginationMobile={props.paginationMobile}
      paginationAlignSelf={props.paginationAlignSelf}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      cursorColorText={props.cursorColorText}
    >
      <Content paginationTop={props.paginationTop}>
        <StyledSwiper
          className={props.className}
          {...params}
          onInit={(el) => slideInit(el)}
          onSlideChange={(el) => slideChange(el)}
        >
          <StyledSwiperSlide
            style={{ "--aspect": props.aspect || "5/3" }}
            key={1}
            zindex="1"
          >
            <div className="textwrapper">
              <ImageRegular
                alt="Slider Slideshow"
                src={`${assetsPath}/branding/kollabo-70-team-image-03-digital-collaboration-platform-branding-blue-color-workers-seo-optimization-fiftyseven-design-studio.webp`}
              />

              <Grid className="slidergrid">
                <GridItem className="slidergrid-item" start={1} end={7}>
                  <LayoutFlex
                    direction="column"
                    gap={isMobile ? pxToRem(5.25) : pxToRem(18)}
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="13" cy="13" r="13" fill="#F56038" />
                      <path
                        d="M10.2633 15.572H12.0633V10.364L10.2633 10.58V9.164L12.3873 8.6H14.2473V15.572H15.9273V17H10.2633V15.572Z"
                        fill="white"
                      />
                    </svg>

                    <Paragraph3>PORTRAIT TAKEN</Paragraph3>
                  </LayoutFlex>
                </GridItem>

                <GridItem start={8} end={8}>
                  <Border
                    className="border ml-1"
                    isVertical={true}
                    color="#DCDBDB"
                  />
                </GridItem>

                <GridItem className="slidergrid-item" start={9} end={16}>
                  <LayoutFlex
                    margin={isMobile ? `` : `0 0 0 ${pxToRem(16)}`}
                    direction="column"
                    gap={isMobile ? pxToRem(5.25) : pxToRem(18)}
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="13" cy="13" r="13" fill="#F56038" />
                      <path
                        d="M9.9633 15.38L13.1073 12.404C13.3153 12.204 13.4713 12.048 13.5753 11.936C13.6793 11.816 13.7433 11.724 13.7673 11.66V10.508C13.7033 10.388 13.5913 10.284 13.4313 10.196C13.2793 10.108 13.0913 10.064 12.8673 10.064C12.6513 10.064 12.4673 10.108 12.3153 10.196C12.1633 10.276 12.0593 10.364 12.0033 10.46V11.54H9.9873V9.716C10.0513 9.628 10.1513 9.512 10.2873 9.368C10.4313 9.224 10.6193 9.084 10.8513 8.948C11.0913 8.804 11.3753 8.68 11.7033 8.576C12.0393 8.472 12.4273 8.42 12.8673 8.42C13.3073 8.42 13.6993 8.476 14.0433 8.588C14.3953 8.692 14.6993 8.824 14.9553 8.984C15.2113 9.144 15.4193 9.32 15.5793 9.512C15.7473 9.696 15.8713 9.868 15.9513 10.028V12.152C15.9113 12.248 15.8233 12.38 15.6873 12.548C15.5513 12.716 15.3553 12.916 15.0993 13.148L12.8673 15.164V15.26H15.9753V17H9.9633V15.38Z"
                        fill="white"
                      />
                    </svg>

                    <Paragraph3>CUT BACKGROUND + retouching</Paragraph3>
                  </LayoutFlex>
                </GridItem>

                <GridItem start={16} end={17}>
                  <Border
                    className="border"
                    margin={isMobile ? `` : `0 0 0 ${pxToRem(28)}`}
                    isVertical={true}
                    color="#DCDBDB"
                  />
                </GridItem>

                <GridItem className="slidergrid-item" start={17}>
                  <LayoutFlex
                    margin={isMobile ? `` : `0 0 0 ${pxToRem(40)}`}
                    direction="column"
                    gap={isMobile ? pxToRem(5.25) : pxToRem(18)}
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="13" cy="13" r="13" fill="#F56038" />
                      <path
                        d="M9.9393 14.408H11.9553V15.092C12.0113 15.204 12.1193 15.308 12.2793 15.404C12.4393 15.492 12.6273 15.536 12.8433 15.536C13.0673 15.536 13.2553 15.492 13.4073 15.404C13.5673 15.308 13.6753 15.204 13.7313 15.092V14.036C13.6833 13.884 13.5793 13.756 13.4193 13.652C13.2673 13.54 13.0793 13.484 12.8553 13.484H11.7153V11.936H12.8193C13.0353 11.936 13.2193 11.884 13.3713 11.78C13.5233 11.676 13.6273 11.572 13.6833 11.468V10.46C13.6273 10.356 13.5233 10.264 13.3713 10.184C13.2193 10.104 13.0353 10.064 12.8193 10.064C12.6033 10.064 12.4153 10.108 12.2553 10.196C12.1033 10.276 12.0033 10.364 11.9553 10.46V11.12H9.9393V9.716C9.9953 9.628 10.0953 9.512 10.2393 9.368C10.3833 9.224 10.5713 9.084 10.8033 8.948C11.0433 8.804 11.3273 8.68 11.6553 8.576C11.9913 8.472 12.3793 8.42 12.8193 8.42C13.2593 8.42 13.6513 8.476 13.9953 8.588C14.3393 8.692 14.6393 8.824 14.8953 8.984C15.1513 9.144 15.3593 9.316 15.5193 9.5C15.6873 9.676 15.8073 9.836 15.8793 9.98V11.78C15.8073 11.9 15.6713 12.04 15.4713 12.2C15.2713 12.352 15.0393 12.496 14.7753 12.632C15.0553 12.768 15.2953 12.932 15.4953 13.124C15.6953 13.316 15.8393 13.496 15.9273 13.664V15.572C15.8553 15.724 15.7353 15.896 15.5673 16.088C15.4073 16.272 15.1993 16.444 14.9433 16.604C14.6873 16.764 14.3833 16.9 14.0313 17.012C13.6793 17.124 13.2833 17.18 12.8433 17.18C12.4033 17.18 12.0153 17.128 11.6793 17.024C11.3433 16.92 11.0553 16.796 10.8153 16.652C10.5753 16.508 10.3833 16.36 10.2393 16.208C10.0953 16.056 9.9953 15.932 9.9393 15.836V14.408Z"
                        fill="white"
                      />
                    </svg>

                    <Paragraph3>KOLLABO BACKGROUND</Paragraph3>
                  </LayoutFlex>
                </GridItem>
              </Grid>
            </div>
          </StyledSwiperSlide>
          <StyledSwiperSlide
            style={{ "--aspect": props.aspect || "5/3" }}
            key={0}
            zindex="0"
          >
            <Slideshow
              aspect={props.aspect || "5/3"}
              slides={[
                {
                  src: `${assetsPath}/branding/kollabo-68-team-image-01-digital-collaboration-platform-branding-construction-industry-seo-optimization-fiftyseven-design-studio.webp`,
                },
                {
                  src: `${assetsPath}/branding/kollabo-69-team-image-02-construction-staffing-agency-branding-hiring-platform-seo-optimization-fiftyseven-design-studio.webp`,
                },
              ]}
            />
          </StyledSwiperSlide>

          <StyledLayoutFlex
            className="swiper-pagination-wrapper"
            justify="space-between"
            paginationTop={props.paginationTop}
            paginationMargin={props.paginationMargin}
          >
            <div
              className="swiper-pagination-flex"
              ref={elPaginationWrapper}
              onMouseEnter={handlePaginationHover}
              onMouseLeave={handlePaginationLeave}
            >
              <div className="swiper-pagination" ref={elPagination}></div>
              <div
                className="swiper-pagination-active"
                ref={elPaginationActive}
              ></div>
            </div>
          </StyledLayoutFlex>
        </StyledSwiper>
      </Content>

      {!isMobile && (
        <div className="swiper-cursor" ref={elCursor}>
          <div className="swiper-cursor-inner">
            <span
              className={`swiper-cursor-text prev ${
                area === "left" && "active"
              }`}
            >
              Prev
            </span>
            <span
              className={`swiper-cursor-text next ${
                area === "right" && "active"
              }`}
            >
              Next
            </span>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default Slider;
