import React, { useRef, useState, forwardRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import { isTablet } from "react-device-detect";
import pxToRem from "../../Utils/pxToRem";

// Component
import Container from "../Layout/Container";
import Section from "../Section";
import Overlay from "../Overlay";

import { gsap, ScrollTrigger, useGSAP } from "../../../gsap";
import AnimTextPerline from "../Motion/Textperline";
import Chapter from "../Text/Chapter";
import Menunavhero from "../Menunavhero";
import Heading1 from "../Text/Heading1";
import ButtonCursor from "../ButtonCursor";

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  a {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
  }

  &.active {
    visibility: hidden;
  }

  .next__bg {
    will-change: transform;
  }
`;

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 620px) {
    margin-bottom: calc(var(--header-height) + 25px + 54px + var(--vh));
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMenunavhero = styled(Menunavhero)`
  order: 2;
  margin-top: ${pxToRem(73)};
  margin-bottom: 0;
  color: #fff;

  @media screen and (max-width: 620px) {
    margin-top: ${pxToRem(20)};
    order: 2;
  }
`;

const StyledHeading1 = styled(Heading1)`
  order: 1;
  margin-top: ${pxToRem(73)};
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    margin-top: ${pxToRem(20)};
    order: 1;
    padding: 0 2rem;
    box-sizing: border-box;
    white-space: normal;
  }
`;

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const Next = forwardRef((props, el) => {
  const location = useLocation();

  const [, setFirstEnter] = useState(false);
  const [mouseClick, setMouseClick] = useState(false);

  const elCursor = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const clone = useRef();

  // const [isNextTransition, setIsNextTransition] = useAtom(nextTransition);

  const routerState = useMemo(
    () => ({
      from: location.pathname,
      casetocase: true,
      next: false, // TODO: change this to true later
    }),
    [location]
  );

  const { contextSafe } = useGSAP({ scope: el });

  useGSAP(
    () => {
      clone.current = document.querySelector(".clone-transition");

      if (isTablet) {
        gsap.set("#next a", {
          marginBottom: "80px",
        });
      }

      gsap.from(".next__bg", {
        scale: 1.4,
        yPercent: -50,
        ease: "none",
        scrollTrigger: {
          trigger: el.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: ScrollTrigger.isTouch ? 0.2 : true,
        },
      });

      gsap.to(".next__overlay", {
        opacity: 0.2,
        ease: "none",
        scrollTrigger: {
          trigger: el.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },
      });
    },
    {
      dependencies: [el],
      scope: el,
    }
  );

  const handleMouseClick = contextSafe(() => {
    if (isMobile) return;
    if (!elCursor.current) return;

    setMouseClick(true);

    setTimeout(() => {
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.6,
        overwrite: true,
        ease: "power4.out",
      });
    }, 1000);
  });

  const handleMouseMove = contextSafe((event) => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (mouseClick) return;

    const elRect = el.current.getBoundingClientRect();
    const cursorRect = elCursor.current.getBoundingClientRect();

    const x = event.clientX - elRect.left - cursorRect.width / 2;
    const y = event.clientY - elRect.top - cursorRect.height / 2;

    gsap.to(elCursor.current, {
      scale: 1,
      left: x,
      top: y,
      duration: 0.6,
      delay: 0.01,
      ease: "power4.out",
    });
  });

  const handleMouseLeave = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (mouseClick) return;

    gsap.to(elCursor.current, {
      scale: 0,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
      onComplete: () => {
        setFirstEnter(false);
      },
    });
  });

  const handleMouseEnter = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (mouseClick) return;

    gsap.to(elCursor.current, {
      scale: 1,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
    });
  });

  return (
    <Wrapper
      ref={el}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ButtonCursor
        ref={elCursor}
        text="Open"
        size={isTablet ? pxToRem(220) : pxToRem(134)}
        sizeMobile={pxToRem(111)}
        fontSize={isTablet ? pxToRem(14) : null}
        top={isTablet ? "62.5%" : "25%"}
        left={isTablet ? "88%" : "65%"}
        topMobile="calc(50% + var(--header-height))"
        leftMobile="50%"
        bgColor={props.cursorBgColor}
        textColor={props.cursorTextColor}
      />
      <Section id="next" position="relative" padding="0">
        <Link state={routerState} to={props.link} onClick={handleMouseClick}>
          <img
            className="next__bg"
            src={props.img}
            alt="Next Case"
            draggable="false"
          />
          <Container>
            <Overlay className="next__overlay" opacity={0.8} />
            <Content type={props.type}>
              <AnimTextPerline stagger="0">
                <Chapter
                  className="chapter"
                  text="Case Study"
                  color="#ffffff"
                  number={props.number ?? "2"}
                  fontSizeM={pxToRem(14)}
                />
              </AnimTextPerline>

              <Flex>
                <StyledMenunavhero
                  type={props.type}
                  className={isMobile ? "mt-8" : "mb-4"}
                  items={props.menuitem}
                  services={props.services}
                  opacity="1"
                  serviceInfo={false}
                />
                <AnimTextPerline>
                  <StyledHeading1
                    type={props.type}
                    text={props.title}
                    align="center"
                    color="#ffffff"
                  />
                </AnimTextPerline>
              </Flex>
            </Content>
          </Container>
        </Link>
      </Section>
    </Wrapper>
  );
});

export default Next;
