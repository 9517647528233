import React, {
  useRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useCallback,
} from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import { ScrollTrigger } from "../../../gsap";

const Wrapper = styled.div`
  overflow: hidden;
  width: ${(props) => props.width};
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${(props) => props.padding};
`;

const OverlayVideo = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
`;

const VideoElement = styled.video`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: ${(props) => props.paddingVideo};
  border-radius: ${(props) => props.borderRadius || `${pxToRem(25)}`};
  border-width: ${(props) => props.borderWidth || "3px"};
  border-color: ${(props) => props.borderColor || "#D9DACA"};
  border-style: ${(props) => props.borderStyle || "solid"};

  @media (max-width: 620px) {
    border-radius: ${(props) => props.borderRadius || `${pxToRem(11)}`};
    border-width: ${(props) => props.borderWidth || "1.5px"};
  }
`;

const VideoFallback = styled.p`
  padding: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
`;

const IconMedia = styled.div`
  position: absolute;
  width: clamp(20px, ${pxToRem(32)}, 32px);
  height: clamp(20px, ${pxToRem(32)}, 32px);
  bottom: ${pxToRem(30)};
  right: ${pxToRem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.iconMediaBorderColor || "rgba(255, 255, 255, 0.2)"};
  transition: transform 0.3s ease;
  will-change: transform;
  transform-style: preserve-3d;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .softbox {
    padding: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    overflow: hidden;

    @media (max-width: 620px) {
      padding: 80%;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 620px) {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    bottom: ${pxToRem(15)};
    right: ${pxToRem(15)};
  }

  .play,
  .pause {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: none;

    &.active {
      display: flex;
    }

    svg {
      height: 35%;
    }
  }

  .play {
    svg {
      margin-left: 1.5px;
    }
  }

  .pause {
    svg {
      @media (max-width: 620px) {
        transform: translateX(0.3px);
      }
    }
  }
`;

const Video = forwardRef((props, ref) => {
  const refWrapper = useRef(null);
  const refVideo = useRef(null);

  const elPlay = useRef();
  const elPause = useRef();
  
  const pauseVideo = useCallback(() => {
    if (!refVideo.current) return;
    refVideo.current.pause();

    if (props.hideIconMedia) return;
    if (!elPause.current && !elPlay.current) return;
    elPause.current.classList.remove("active");
    elPlay.current.classList.add("active");
  }, [props.hideIconMedia]);

  const playVideo = useCallback(() => {
    if (!refVideo.current) return;
    refVideo.current.play();

    if (props.hideIconMedia) return;
    if (!elPause.current && !elPlay.current) return;
    elPause.current.classList.add("active");
    elPlay.current.classList.remove("active");
  }, [props.hideIconMedia]);

  const toggleVideo = useCallback(() => {
    if (!refVideo.current) return;

    if (refVideo.current.paused) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [playVideo, pauseVideo]);

  // If you want to expose additional methods to parent components, you can do so with useImperativeHandle
  useImperativeHandle(ref, () => ({
    play: () => {
      if (refVideo.current) {
        refVideo.current.play();
      }
    },
    pause: () => {
      if (refVideo.current) {
        refVideo.current.pause();
      }
    },
    isPaused: () => refVideo.current ? refVideo.current.paused : true,
    // any other video control methods you want to expose...
  }));

  useEffect(() => {
    if (props.noScrollTrigger) return;
    ScrollTrigger.create({
      trigger: refWrapper.current,
      start: "top-=1750px bottom+=200px",
      onEnter: () => {
        playVideo();
      },
      onEnterBack: () => {
        playVideo();
      },
      onLeave: () => {
        pauseVideo();
      },
      onLeaveBack: () => {
        pauseVideo();
      },
    });
  }, [pauseVideo, playVideo, props.noScrollTrigger]);

  return (
    <Wrapper
      ref={refWrapper}
      className={props.className}
      width={props.width}
      style={{ "--aspect": props.aspect || "16/9" }}
    >
      <VideoWrapper padding={props.padding}>
        <OverlayVideo />
        <VideoElement
          ref={refVideo}
          autoPlay={props.autoPlay ?? false}
          playsInline={props.playsInline ?? true}
          loop={props.loop ?? true}
          muted={props.muted ?? true}
          controls={props.controls ?? false}
          preload={props.preload ?? "metadata"}
          poster={props.poster}
          borderRadius={props.borderRadius}
          borderWidth={props.borderWidth}
          borderColor={props.borderColor}
          borderStyle={props.borderStyle}
          paddingVideo={props.paddingVideo}
        >
          <source src={props.src} type={props.type ?? "video/mp4"} />
          <VideoFallback>
            {props.fallbackMessage ??
              "Your browser does not support the video element. Please consider updating to a modern browser."}
          </VideoFallback>
        </VideoElement>
      </VideoWrapper>

      {!props.hideIconMedia && (
        <IconMedia
          iconMediaBorderColor={props.iconMediaBorderColor}
          iconMediaTextColor={props.iconMediaTextColor}
          onClick={() => toggleVideo()}
        >
          <span className="softbox"></span>
          <span ref={elPlay} className="play">
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 4.5L1.09788e-07 8.39711L4.50484e-07 0.602885L6 4.5Z"
                fill={props.iconMediaTextColor || "currentColor"}
              />
            </svg>
          </span>

          <span ref={elPause} className="pause">
            <svg
              width="6"
              height="8"
              viewBox="0 0 6 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="1.5"
                height="8"
                fill={props.iconMediaTextColor || "currentColor"}
              />
              <rect
                x="4"
                width="1.5"
                height="8"
                fill={props.iconMediaTextColor || "currentColor"}
              />
            </svg>
          </span>
        </IconMedia>
      )}
    </Wrapper>
  );
});

export default Video;
