import React, { useRef, useCallback } from "react";
import { useMedia } from "react-recipes";
import { useLocation, useBeforeUnload, useNavigate } from "react-router-dom";
import styled from "styled-components";

// Component
import Container from "../Layout/Container";
import Chapter from "../Text/Chapter";
import Menunavhero from "../Menunavhero";
import Heading1 from "../Text/Heading1";

import { gsap, ScrollTrigger, SplitText, useGSAP } from "../../../gsap";

import { useAtom } from "jotai";
import { lenisAtom } from "../../../atom/transitionAtom";

const Wrapper = styled.section`
  margin: 0 !important;
  padding: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  background-color: ${(props) => props.backgroundColor || "#1b1a1c"};
  transform: translateY(-100%);
  box-sizing: border-box;
  pointer-events: none;

  @media screen and (max-width: 620px) {
    justify-content: center;
    height: calc(100% + 5vh);
  }

  .chapter {
    margin-bottom: 17vh;

    @media screen and (max-width: 620px) {
      margin-bottom: 9vh;
    }
  }

  .container {
    @media screen and (min-width: 620px) {
      padding: 0;
    }
  }
`;

const Section = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 38vh;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 620px) {
    margin-top: calc(8vh + 54px / 2);
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledMenunavhero = styled(Menunavhero)`
  color: #fff;

  @media screen and (max-width: 620px) {
    order: 2;
    margin-bottom: 0;
  }
`;

const StyledHeading1 = styled(Heading1)`
  width: 100%;
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    order: 1;
    box-sizing: border-box;
    white-space: normal;
  }
`;

const Intro = (props) => {
  let el = useRef();

  let splitText = useRef(null);

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const location = useLocation();
  const navigate = useNavigate();

  const [lenis] = useAtom(lenisAtom);

  useBeforeUnload(
    useCallback(() => {
      // set casetocase state history api to false on reload/leaving page
      navigate(location.pathname, {
        state: { ...location.state, casetocase: false },
      });
    }, [location, navigate])
  );

  useGSAP(
    () => {
      splitText.current = new SplitText(".chapter, .case__title", {
        type: "lines",
        linesClass: "line",
      });

      gsap.utils.toArray(splitText.current.lines).forEach((el, i) => {
        const wrapper = document.createElement("div");
        wrapper.classList.add("masking-text");
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
      });

      const tl = gsap.timeline({
        delay: location.state?.casetocase ? 3 : 0,
        onStart: () => {
          if (!ScrollTrigger.isTouch && lenis) {
            lenis.stop();
          }
        },
        onComplete: () => {
          gsap.to(el.current, {
            delay: 0.2,
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
              trigger: lenis.rootElement,
              start: "clamp(top top)",
              end: "+=100%",
              scrub: true,
            },
          });

          gsap.set(".case__menunav .masking-text", {
            overflow: "inherit",
          });

          if (!ScrollTrigger.isTouch && lenis) {
            lenis.start();
          }

          ScrollTrigger.refresh();
        },
      });

      if (!location.state?.casetocase) {
        tl.to(el.current, {
          y: 0,
          duration: 1.4,
          ease: "customEase3",
        });

        tl.from(".line", {
          yPercent: 105,
          stagger: 0,
        });

        tl.call(() => {
          if (!ScrollTrigger.isTouch && lenis) {
            lenis.start();
          }
        });

        tl.to(el.current, {
          yPercent: -5,
        });
      } else {
        gsap.set(el.current, {
          y: 0,
        });

        tl.from(".line", {
          yPercent: 105,
          stagger: 0,
        });

        tl.call(() => {
          if (!ScrollTrigger.isTouch && lenis) {
            lenis.start();
          }
        });

        tl.to(el.current, {
          yPercent: -5,
        });
      }
    },
    {
      dependencies: [location.state?.casetocase],
      scope: el,
    }
  );

  return (
    <Wrapper
      ref={el}
      backgroundColor={props.backgroundColor}
      id="intro"
      className="case__hero"
    >
      <Container className="container">
        <Section type={props.type}>
          <Chapter
            className="chapter"
            text="Case Study"
            color="#ffffff"
            number={props.number ?? "2"}
          />

          <Flex>
            {/* Note: Interactive */}
            <StyledMenunavhero
              className={`case__menunav ${isMobile ? "mt-8" : "mb-4"}`}
              items={props.menuitem}
              services={props.services}
              opacity="1"
              serviceInfo={true}
            />
            <StyledHeading1
              className="case__title"
              text={props.title}
              align="center"
              color="#ffffff"
            />
          </Flex>
        </Section>
      </Container>
    </Wrapper>
  );
};

export default Intro;
