import React from 'react';
import { Link } from 'react-router-dom';
import classes from './AwardListTableRow.mobile.module.scss';
import { useAwardListTableContext, useAwardListTableRowContext } from './contexts';

/**
 * @typedef {object} AwardListTableRowMobilePartialProps
 * @property {never} children
 */

/**
 * @typedef {import('./AwardList').AwardListNormalizedItem & AwardListTableRowMobilePartialProps} AwardListTableRowMobileProps
 */

/** @type {import('react').FC<AwardListTableRowMobileProps>} */
export const AwardListTableRowMobile = (props) => {
  const {
    award,
    category,
    href,
    title
  } = props;

  const { isLastGroup } = useAwardListTableContext();
  const { isFirstRow, isLastRow } = useAwardListTableRowContext();

  return (
    <>
      <tr>
        <td className={classes.cell}>
          <Link
            target="_blank"
            to={href}
          >
            {isFirstRow && title}
          </Link>
        </td>

        <td className={classes.cell}>
          <div className={classes.title}>{award}</div>
          <div className={classes.category}>{category}</div>
        </td>
      </tr>

      {!isLastGroup && isLastRow && (
        <tr>
          <td
            className={classes.lastCell}
            colSpan={2}
          />
        </tr>
      )}
    </>
  );
};

