/* eslint-disable jsx-a11y/anchor-is-valid */
import classnames from 'classnames';
import Color from 'color';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Easings } from '../animation';
import './NavigateBackButton.scss';

export const sizeToClassNameMap = {
  m: 'NavigateBackButton--medium',
  l: 'NavigateBackButton--large'
}

const BackgroundColor = Color('#2b292c');

const MotionVariants = {
  arrow: {
    normal: {},
    hover: {
      x: ['0%', '-100%', '100%', '0%'],
      transition: {
        x: {
          duration: 0.72,
          ease: Easings.easeOutCubic,
          times: [0, 0.5, 0.5, 1]
        }
      }
    }
  },
  backdrop: {
    normal: {
      backgroundColor: BackgroundColor.hex()
    },
    hover: {
      backgroundColor: BackgroundColor.darken(0.25).hex()
    }
  }
};

function NavigateBackButton({
  className,
  size
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHover, setHover] = useState(false);
  const animate = isHover ? 'hover' : 'normal';

  const routerState = useMemo(() => ({
    from: location.state?.from ?? null
  }), [location.state?.from]);

  const handleHover = useCallback((isHover) => () => {
    setHover(isHover);
  }, []);

  const handleNavigate = useCallback(() => {
    navigate(-1, routerState)
  }, [navigate, routerState]);

  return (
    <motion.div
      className={classnames([
        'NavigateBackButton',
        sizeToClassNameMap[size],
        className
      ])}
      animate={animate}
      transition={{ duration: 0.24, ease: 'easeInOut' }}
      variants={MotionVariants.backdrop}
      // onClick={handleBack}
      onHoverEnd={handleHover(false)}
      onHoverStart={handleHover(true)}
    >
      <a
        href="#"
        className="NavigateBackButton__link"
        onClick={handleNavigate}
      >
        <div style={{ overflow: 'hidden' }}>
          <motion.div
            className="NavigateBackButton__arrow"
            variants={MotionVariants.arrow}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" fill="none"><path fill="#fff" d="M2.628 4.044h6.708v1.368H2.64L3.912 7.02 5.4 9H3.816L.588 4.728 3.816.456H5.4L3.912 2.424l-1.284 1.62Z"/></svg>
          </motion.div>
        </div>
      </a>
    </motion.div >
  );
}

NavigateBackButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizeToClassNameMap))
};

NavigateBackButton.defaultProps = {
  size: 'm'
};


export default NavigateBackButton;
