import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { Draggable, ScrollTrigger } from "../../gsap";

const Wrapper = styled.div`
  width: auto;
`

const Scroll = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  box-sizing: border-box;
  gap: ${props => props.gap};
`

const Drag = (props) => {
  const el = useRef(null);
  const elScroll = useRef(null);
  
  useEffect(() => {
    if (!ScrollTrigger.isTouch) return;
    Draggable.create(elScroll.current, {
      type: "x",
      bounds: el.current,
      inertia: true,
      force3D: true,
      dragResistance: -0.3,
      minimumMovement: 5,
      lockAxis: "x"
    });
  }, []);

  return (
    <Wrapper ref={el} className={props.className}>
      <Scroll gap={props.gap} ref={elScroll}>
        {props.children}
      </Scroll>
    </Wrapper>
  );
}

export default Drag;
