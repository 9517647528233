import React from "react";
import styled, { css } from "styled-components";
import pxToRem from "../Utils/pxToRem";
import AnimTextPerline from "./Motion/Textperline";

const List = styled.ul`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  letter-spacing: 0.021em;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: ${pxToRem(50)};
  row-gap: ${pxToRem(8)};
  list-style: none;
  padding: ${(props) => props.padding || 0};

  @media screen and (max-width: 620px) {
    column-gap: ${pxToRem(36)};
    font-size: ${pxToRem(12)};
    letter-spacing: 0.02em;
    line-height: 2;
  }
`;

const ListItem = styled.li`
  position: relative;
  color: ${(props) => props.color};

  ${({ lastInRow }) =>
    !lastInRow &&
    css`
      &:not(:last-child)&::after {
        content: "/";
        font-size: ${pxToRem(12)};
        letter-spacing: 0em;
        line-height: 2.916;
        position: absolute;
        top: 50%;
        right: ${pxToRem(-25)};
        transform: translateY(-50%);
        color: ${(props) => props.borderColor};
        opacity: ${(props) => props.separatorOpacity || "0.2"};

        @media (max-width: 620px) {
          right: ${pxToRem(-18)};
        }
      }
    `}
`;

const Menunav = (props) => {
  const listRef = React.useRef(null);

  const isLastItemInRow = (index) => {
    if (listRef.current) {
      const items = listRef.current.children;
      if (index + 1 < items.length) {
        const currentItem = items[index].getBoundingClientRect();
        const nextItem = items[index + 1].getBoundingClientRect();
        const tolerance = 1; // 1px tolerance
  
        return Math.abs(currentItem.top - nextItem.top) > tolerance;
      }
      // If it's the last item, consider it as the last in its row.
      return true;
    }
    return false;
  };

  return (
    <List className={props.className} ref={listRef} padding={props.padding}>
      {props.items.map((item, index) => (
        <ListItem
          key={index}
          color={props.color}
          separatorOpacity={props.separatorOpacity}
          borderColor={props.borderColor}
          lastInRow={isLastItemInRow(index)}
        >
          <AnimTextPerline>
            <span>{item}</span>
          </AnimTextPerline>
        </ListItem>
      ))}
    </List>
  );
};

export default Menunav;
