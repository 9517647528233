import classNames from 'classnames';
import { motion, useInView, useIsPresent } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Durations, Easings } from '../../animation';
import { useNormalizeValue } from '../../hooks';

const resolveDelay = (delay, withoutDelay) => (
  withoutDelay ? {} : { delay }
);

const defaultMotionProps = {
  enter: {
    y: '0%',
  },
  exit: {
    y: '110%',
  }
};

const RevealVariants = {
  enter: ([
    [delay],
    [duration],
    [motionProps],
    { withoutDelay }
  ]) => ({
    ...motionProps,
    transition: {
      ease: Easings.easeOutCubic,
      duration,
      ...resolveDelay(delay, withoutDelay)
    }
  }),
  exit: ([
    [, delay],
    [, duration],
    [, motionProps],
    { withoutDelay }
  ]) => ({
    ...motionProps,
    transition: {
      ease: Easings.easeInCubic,
      duration,
      ...resolveDelay(delay, withoutDelay)
    }
  }),
};

function MaskedReveal({
  as,
  auto,
  children,
  className,
  delay,
  duration,
  elStyle,
  withInView = false,
  motionProps,
  style,
  withoutDelay,
  withPresence = true,
  onRevealComplete
}) {
  const rootElement = useRef();
  const domElement = useRef();
  const [delayEnter, delayExit] = useNormalizeValue(delay);
  const [durationEnter, durationExit] = useNormalizeValue(duration, true);
  const [isRevealed, setIsRevealed] = useState(false);
  const isPresent = useIsPresent();
  const _inView = useInView(rootElement, { once: true });

  const animationTargets = useMemo(() => {
    if (auto) {
      return {};
    }

    if (withInView && _inView) {
      return {
        initial: 'exit',
        animate: 'enter',
        exit: 'exit'
      };
    }
    else if (withInView && !_inView) {
      return {
        initial: 'exit'
      };
    }

    return {
      animate: 'enter',
      initial: 'exit',
      exit: 'exit'
    };
  }, [auto, _inView, withInView]);

  const handleAnimationStart = useCallback((variant) => {
    if (!isPresent || withPresence) {
      return;
    }

    if (variant === 'exit') {
      setIsRevealed(false);
    }
  }, [isPresent, withPresence]);

  const handleAnimationComplete = useCallback((variant) => {
    if (!isPresent) {
      return;
    }

    if (variant === 'enter') {
      const event = new CustomEvent('onrevealcomplete');
      domElement.current?.dispatchEvent(event);
      setIsRevealed(true);
    }
  }, [isPresent]);

  const handleUpdate = useCallback((values) => {
    const event = new CustomEvent('onrevealupdate', { detail: values });
    domElement.current?.dispatchEvent(event);
  }, []);

  const element = useMemo(() => (
    React.isValidElement(children)
      ? React.cloneElement(
        children,
        {
          ref: domElement
        }
      )
      : children
  ), [children]);

  useEffect(() => {
    if (onRevealComplete instanceof Function && isRevealed) {
      onRevealComplete();
    }
  }, [isRevealed, onRevealComplete]);

  useLayoutEffect(() => {
    const el = domElement.current;

    if (el) {
      // Create dummy event handler to check if the element has a reveal animation.
      el.onrevealcomplete = () => { };
      el.onrevealupdate = () => { };
    }

    return () => {
      if (el) {
        delete el.onrevealcomplete;
        delete el.onrevealupdate;
      }
    }
  }, []);

  return (
    React.createElement(
      as,
      {
        ref: rootElement,
        className: classNames('MaskedReveal', className),
        style: {
          ...style,
          overflow: isRevealed && isPresent ? null : 'hidden'
        }
      },
      <motion.div
        {...animationTargets}
        style={elStyle}
        custom={[
          [delayEnter, delayExit],
          [durationEnter, durationExit],
          [
            {
              ...defaultMotionProps.enter,
              ...motionProps?.enter
            },
            {
              ...defaultMotionProps.exit,
              ...motionProps?.exit
            }
          ],
          { withoutDelay }
        ]}
        variants={RevealVariants}
        onAnimationComplete={handleAnimationComplete}
        onAnimationStart={handleAnimationStart}
        onUpdate={handleUpdate}
      >
        {element}
      </motion.div>
    )
  );
}

MaskedReveal.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  auto: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  duration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  elStyle: PropTypes.object,
  style: PropTypes.object,
  withoutDelay: PropTypes.bool
};

MaskedReveal.defaultProps = {
  as: 'div',
  auto: false,
  className: null,
  delay: 0,
  duration: Durations.base,
  elStyle: {},
  style: {},
  withoutDelay: false
};

export default MaskedReveal;
