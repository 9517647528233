import { useEffect, useState } from 'react';

/**
 * @typedef {object} DelayRenderProps
 * @property {import('react').ReactNode} [children]
 * @property {number} [delay=250]
 */

/**
 * Renders the children component after a specified delay.
 *
 * @param {DelayRenderProps} props - The component props.
 * @returns {import('react').ReactNode} - The rendered component after the delay.
 */
export function DelayRender(props) {
  const { children, delay = 250 } = props;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsReady(true);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return isReady ? children : null;
}
