export const Tags = [
  {
    label: 'All',
    value: 0
  },
  ...[
    {
      label: 'Fintech',
      value: 1
    },
    {
      label: 'Architecture',
      value: 2
    },
    {
      label: 'Healthcare',
      value: 3
    },
    {
      label: 'Tech',
      value: 4
    },
    {
      label: 'Environmental',
      value: 5
    },
    {
      label: 'Digital Platform',
      value: 6
    },
    {
      label: 'Wearable',
      value: 7
    },
    {
      label: 'Chartering',
      value: 8
    },
    {
      label: 'Telecommunication',
      value: 9
    },
    {
      label: 'AI',
      value: 10
    },
    {
      label: 'Automative',
      value: 11
    }
  ].sort((a, b) => a.label.localeCompare(b.label))
];

export const Projects = [
  {
    comingSoon: true,
    href: 'https://happyring.fiftyseven.co/',
    type: 'Website',
    image: {
      src: '/img/projects/happyring.jpg',
      preSrc: require('./assets/placeholder/projects/happyring.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    video: {
      src: '/assets/projects/Happy Ring Hover Preview.mp4',
      poster: ''
    },
    title: 'Happy Ring',
    year: 2023,
    tags: [
      'Healthcare',
      'Wearable',
      'Tech',
      'AI'
    ],
    awards: [
      {
        award: 'CSSDA',
        category: 'Site of the Day'
      },
      {
        award: 'Awwwards',
        category: 'Honorable Mention'
      }
    ]
  },
  {
    href: '/case/depoly-se',
    type: 'Case Study',
    image: {
      src: '/img/projects/depoly.jpg',
      preSrc: require('./assets/placeholder/projects/depoly.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    video: {
      src: '/assets/projects/Work Reel Video Thumb 001.mp4',
      poster: ''
    },
    title: 'DePoly SA',
    year: 2022,
    tags: [
      'Environmental',
      'Tech'
    ],
    awards: [
      {
        award: 'Awwwards',
        category: 'Developer Award'
      },
      {
        award: 'Awwwards',
        category: 'Site of the Day'
      },
      {
        award: 'CSSDA',
        category: 'Site of the Day'
      },
      {
        award: 'FWA',
        category: 'FWA of the Day'
      },
      {
        award: 'Communication Arts',
        category: 'Webpick'
      }
    ]
  },
  {
    href: '/case/roswell-biotech',
    type: 'Case Study',
    image: {
      src: '/img/projects/roswellbiotech.jpg',
      preSrc: require('./assets/placeholder/projects/roswellbiotech.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    video: {
      src: '/assets/projects/Work Reel Video Thumb 002.mp4',
      poster: ''
    },
    title: 'Roswell Biotech',
    year: 2021,
    tags: [
      'Healthcare',
      'Tech'
    ],
    awards: [
      {
        award: 'Awwwards',
        category: 'Honorable Mention'
      },
      {
        award: 'CSSDA',
        category: 'Special Kudos'
      },
      {
        award: 'Mindsparkle Mag',
        category: 'Site of the Day'
      }
    ]
  },
  {
    href: '/case/1910-genetics',
    type: 'Case Study',
    image: {
      src: '/img/projects/1910genetics.jpg',
      preSrc: require('./assets/placeholder/projects/1910genetics.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    video: {
      src: '/assets/projects/Work Reel Video Thumb 004.mp4',
      poster: ''
    },
    title: '1910 Genetics',
    year: 2020,
    tags: [
      'Healthcare',
      'Tech',
      'AI'
    ],
    awards: [
      {
        award: 'Awwwards',
        category: 'Honorable Mention'
      },
      {
        award: 'CSSDA',
        category: 'Special Kudos'
      }
    ]
  },
  {
    href: '/case/enzian-health',
    type: 'Case Study',
    image: {
      src: '/img/projects/enzian.jpg',
      preSrc: require('./assets/placeholder/projects/enzian.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    video: {
      src: '/assets/projects/Work Reel Video Thumb 005.mp4',
      poster: ''
    },
    title: 'Enzian Health',
    year: 2020,
    tags: [
      'Digital Platform',
      'Healthcare'
    ]
  },
  {
    href: '/case/kollabo-ag',
    type: 'Case Study',
    image: {
      src: '/img/projects/kollabo.jpg',
      preSrc: require('./assets/placeholder/projects/kollabo.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    video: {
      src: '/assets/projects/Work Reel Video Thumb 003.mp4',
      poster: ''
    },
    title: 'Kollabo AG',
    year: 2019,
    tags: [
      'Digital Platform',
      'SAAS'
    ]
  },
  {
    legacy: true,
    onRequest: true,
    href: '/contact',
    type: 'Contact',
    image: {
      src: '/img/projects/volvo.jpg',
      preSrc: require('./assets/placeholder/projects/volvo.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'Volvo FL',
    year: 2019,
    tags: [
      'Automative'
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/1834313-Yova-AG-2019',
    type: 'Dribbble Case Study',
    image: {
      src: '/img/projects/yova.jpg',
      preSrc: require('./assets/placeholder/projects/yova.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'Yova AG',
    year: 2019,
    tags: [
      'Environmental',
      'Fintech',
      'SAAS'
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/2687034-DARA-2018',
    type: 'Dribbble Case Study',
    image: {
      src: '/img/projects/dara.jpg',
      preSrc: require('./assets/placeholder/projects/dara.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'Dara Architects',
    year: 2018,
    tags: [
      'Architecture'
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/1980988-Healthx-2018',
    type: 'Dribble Case Study',
    image: {
      src: '/img/projects/healthx.jpg',
      preSrc: require('./assets/placeholder/projects/healthx.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'HealthX',
    year: 2018,
    tags: [
      'Healthcare',
      'Tech'
    ],
    awards: [
      {
        award: 'Awwwards',
        category: 'Honorable Mention'
      },
      {
        award: 'CSSDA',
        category: 'Special Kudos'
      },
      {
        award: 'Mindsparkle Mag',
        category: 'Site of the Day'
      }
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/2083138-KaiOS-Technologies-2017',
    type: 'Dribble Case Study',
    image: {
      src: '/img/projects/kaios.jpg',
      preSrc: require('./assets/placeholder/projects/kaios.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'KaiOS Technologies',
    year: 2017,
    tags: [
      'Telecommunication'
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/589021-Koniku-Inc-2017',
    type: 'Dribble Case Study',
    image: {
      src: '/img/projects/koniku.jpg',
      preSrc: require('./assets/placeholder/projects/koniku.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'Koniku',
    year: 2017,
    tags: [
      'Healthcare',
      'Tech'
    ],
    awards: [
      {
        award: 'Awwwards',
        category: 'Honorable Mention'
      },
      {
        award: 'CSSDA',
        category: 'Site of the Day'
      },
      {
        award: 'The Lovie Awards',
        category: 'Webiste Gold Winner'
      },
      {
        award: 'Communication Arts',
        category: 'Webpick'
      }
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/518131-invstr-Ltd-2016',
    type: 'Dribble Case Study',
    image: {
      src: '/img/projects/invstr.jpg',
      preSrc: require('./assets/placeholder/projects/invstr.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'Invstr Ltd',
    year: 2016,
    tags: [
      'Fintech',
      'SAAS'
    ],
    awards: [
      {
        award: 'Awwwards',
        category: 'Site of the Day'
      },
      {
        award: 'CSSDA',
        category: 'Site of the Day'
      }
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/522138-Yachteo-2015',
    type: 'Dribble Case Study',
    image: {
      src: '/img/projects/yachteo.jpg',
      preSrc: require('./assets/placeholder/projects/yachteo.jpg?placeholder&format=blurred-svg&blurQuality=4').default
    },
    title: 'Yachteo',
    year: 2015,
    tags: [
      'Digital Platform',
      'Chartering'
    ],
    awards: [
      {
        award: 'Awwwards',
        category: 'Honorable Mention'
      },
      {
        award: 'CSSDA',
        category: 'Special Kudos'
      }
    ]
  }
];
