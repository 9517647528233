import { AnimatePresence } from "framer-motion";
import React from "react";
import { useAppContext } from "./AppContext";
import MainFooter from "./MainFooter";
import SiteHeader from "./SiteHeader";
import { SiteLayoutGuard } from "./SiteLayoutGuard/SiteLayoutGuard";

export default function SiteLayout({ children }) {
  const { footerVisible } = useAppContext();

  return (
    <SiteLayoutGuard>
      <SiteHeader />
      <div className="clone-transition" data-flip-id="fullscreen"></div>

      {children}

      <AnimatePresence initial={false}>
        {footerVisible && (
          <MainFooter />
        )}
      </AnimatePresence>

    </SiteLayoutGuard>
  );
}
