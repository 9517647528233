import { createPortal } from 'react-dom';
import { useDisposable } from 'use-disposable';
import classes from './Portal.module.scss';
import classNames from 'classnames';

/**
 * @typedef {object} PortalProps
 * @property {import('react').ReactNode} children
 * @property {string} [className]
 * @property {HTMLElement} [mountNode]
 */

/** @type {import('react').FC<PortalProps>} */
export const Portal = (props) => {
  const {
    children,
    className,
    mountNode
  } = props;

  const domNode = useDisposable(() => {
    const element = document.createElement('div');
    const mountTo = mountNode ?? document.body;
    element.className = classNames(classes.root, className);
    mountTo.appendChild(element);

    return [element, () => element.remove()];
  }, [className, mountNode]);

  return createPortal(children, domNode);
}
