import { useControllableValue, useHover } from 'ahooks';
import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import classes from './Select.module.scss';

/**
 * @typedef {object} Option
 * @property {string} label
 * @property {string} value
 */

/**
 * @typedef {object} SelectPartialProps
 * @property {string} [label]
 * @property {Option[]} options
 */

/**
 * @typedef {SelectPartialProps & import('react').SelectHTMLAttributes<HTMLSelectElement>} SelectProps
 */

/** @type {import('framer-motion').ForwardRefComponent<HTMLSelectElement, SelectProps>} */
export const Select = React.forwardRef((props, ref) => {
  const {
    className,
    label,
    options = [],
    ...rest
  } = props;

  /** @typedef {import('react').MutableRefObject<HTMLSelectElement>} */
  const selectRef = useRef(null);
  const isHovering = useHover(selectRef);

  const [state, setState] = useControllableValue(props, {
    defaultValue: options?.[0].value,
  });

  const dataValue  = useMemo(() => (
    // eslint-disable-next-line eqeqeq
    options.find(({ value }) => state == value)?.label
  ), [state, options]);

  return (
    <div
      data-is-hovering={isHovering ? '' : null}
      className={classes.root} ref={ref}
    >
      {typeof label === 'string' && (
        <label
          className={classes.label}
          htmlFor={rest.id}
        >
          {label}&#58;
        </label>
      )}

      <div
        className={classes.controlWrapper}
        data-value={dataValue}
      >
        <select
          ref={selectRef}
          {...rest}
          className={classNames(classes.control, className)}
          onChange={(e) => setState(e.target.value)}
        >
          {options.map(({ label, value }, idx) => (
            <option
              key={idx}
              value={value}
            >
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
});

Select.displayName = 'Select';
