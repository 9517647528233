import React, { useState, useRef, useEffect } from "react";
import { useMedia } from "react-recipes";
import { isTablet } from 'react-device-detect';
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  EffectCreative,
  EffectFade,
} from "swiper/modules";
import { Swiper } from "swiper";
import SwiperCore from "swiper";

import { gsap, CustomEase, useGSAP } from "../../../gsap";
import convertToCircledNumber from "../../Utils/convertToCircledNumber";
import Paragraph4 from "../Text/Paragraph4";
import ButtonCursor from "../ButtonCursor";
import Drag from "../Drag";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  EffectCreative,
  EffectFade,
]);

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  user-select: none;
  cursor: pointer;
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: 620px) {
    width: calc((100% + var(--case-container)) * ${pxToRem(1)});
    margin-left: calc(var(--case-container) * -${pxToRem(1)});
    margin-right: calc(var(--case-container) * -${pxToRem(1)});
    padding-left: calc(var(--case-container) * ${pxToRem(1)});
    padding-right: calc(var(--case-container) * ${pxToRem(1)});
  }

  .swiper-wrapper {
    order: ${(props) => (props.paginationTop ? "2" : undefined)};
  }

  .swiper-pagination-wrapper {
    position: relative;
    justify-content: ${(props) => props.paginationJustify};

    @media (max-width: 620px) {
      flex-direction: column;
    }
  }

  .swiper-pagination-flex {
    position: relative;

    @media (max-width: 620px) {
      display: ${(props) => (props.paginationMobile ? "flex" : "none")};
      order: 2;

      ${(props) =>
        props.paginationTitle &&
        `
        width: calc((100% + var(--case-container)) * ${pxToRem(1)});
        margin-left: calc(var(--case-container) * -${pxToRem(1)});
        margin-right: calc(var(--case-container) * -${pxToRem(1)});
        padding-left: calc(var(--case-container)  * ${pxToRem(1)} + ${pxToRem(
          15
        )});
        padding-right: calc(var(--case-container) * ${pxToRem(1)} + ${pxToRem(
          15
        )});
        white-space: nowrap;
        flex-wrap: nowrap;
      `}
    }
  }

  .swiper-pagination {
    width: auto;
    position: relative;
    font-family: ${(props) =>
      props.paginationTitle ? "Suisse Intl" : "Helvetica Now Text"};
    font-weight: ${(props) => (props.paginationTitle ? 900 : 800)};
    font-size: ${(props) =>
      props.paginationTitle ? pxToRem(12) : pxToRem(18)};
    letter-spacing: ${(props) => (props.paginationTitle ? "0.04em" : "")};
    line-height: normal;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ${(props) => (props.paginationTitle ? pxToRem(64) : pxToRem(40 - 5))};
    list-style: none;
    text-transform: uppercase;
    white-space: nowrap;
    padding: ${(props) => (props.paginationTitle ? 0 : `0 ${pxToRem(5)}`)};

    @media (max-width: 620px) {
      ${(props) =>
        props.paginationTitle &&
        `
        flex-wrap: nowrap;
        width: 100%;
        margin-left: calc(var(--case-container) * -${pxToRem(1)} - ${pxToRem(
          15
        )});
        margin-right: calc(var(--case-container) * -${pxToRem(1)} - ${pxToRem(
          15
        )});
        padding-left: calc(var(--case-container)  * ${pxToRem(1)} + ${pxToRem(
          15
        )});
        padding-right: calc(var(--case-container) * ${pxToRem(1)} + ${pxToRem(
          15
        )});
      `}
    }
  }

  .swiper-pagination-bullet {
    width: auto;
    height: auto;
    background: none;
    border-radius: 0;
    color: ${(props) => props.bulletHighlightColor || "currentColor"};
    cursor: pointer;
    width: ${(props) => (props.paginationTitle ? "auto" : pxToRem(18))};
    padding-bottom: ${(props) => (props.paginationTitle ? "" : pxToRem(12))};
    margin-bottom: ${(props) => (props.paginationTitle ? pxToRem(16) : "")};
    opacity: 0.25;
    transition: opacity 0.3s ease;
    position: relative;

    &:not(.swiper-pagination-bullet-active):hover {
      opacity: 0.5;
    }

    span {
      opacity: 0.25;
      transition: opacity 0.3s ease;
      position: relative;
    }

    &:not(:last-child) {
      &::after {
        display: ${(props) => (props.paginationTitle ? "" : "none")};
        content: "";
        position: absolute;
        right: -${pxToRem(31)}; // half of the gap
        top: 0;
        width: 1px;
        height: 100%;
        background-color: currentColor;
        opacity: 1;
        transition: opacity 0.1ms step-start;
      }
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;

    &:not(:last-child) {
      &::after {
        opacity: 0.25;
      }
    }
  }

  .swiper-pagination-active {
    position: absolute;
    left: 0;
    width: 100%;
    height: clamp(2px, ${pxToRem(3)}, 3px);
    bottom: 0;
    z-index: 2;
    background-color: ${(props) =>
      props.bulletHighlightColor || "currentColor"};
    transition: background-color 0.3s ease;
    will-change: transform;
  }

  .swiper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    img {
      width: ${(props) => (props.perfectSize ? "100%" : "calc(100% + 2px)")};
      height: 100%;
      object-fit: cover;
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }

    ${(props) =>
      !props.paginationTitle &&
      !props.noPeek &&
      `@media (max-width: 620px) {
        width: calc((100% + var(--case-container)) * ${pxToRem(1)});
        margin-left: calc(var(--case-container) * -${pxToRem(1)});
        margin-right: calc(var(--case-container) * -${pxToRem(1)});
        padding-left: calc(var(--case-container) * ${pxToRem(1)});
        padding-right: calc(var(--case-container) * ${pxToRem(1)});
      }`}
  }

  .swiper-slide {
    width: calc(100% + 2px);
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }

  .swiper-cursor {
    position: absolute;
    top: 50%;
    left: 80%;
    width: ${pxToRem(84)};
    height: ${pxToRem(84)};
    border-radius: 100%;
    background-color: ${(props) => props.cursorColor || "#000"};
    z-index: 2;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: scale(0);

    @media (max-width: 620px) {
      display: none;
    }

    .swiper-cursor-inner {
      font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
      font-weight: 700;
      font-size: ${pxToRem(8)};
      letter-spacing: 0.07em;
      line-height: normal;
      overflow: hidden;
      width: 100%;
      color: ${(props) => props.cursorColorText || "#fff"};
      position: relative;
      text-transform: uppercase;
      padding-top: 1px;
    }

    .swiper-cursor-text {
      display: block;
      transition: transform var(--transition-default);

      &.prev {
        position: relative;
        transform: translateY(-140%);

        &.active {
          transform: translateY(0%);
        }
      }

      &.next {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 105%);

        &.active {
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;

// Fake box
const Content = styled.div`
  padding-top: ${(props) => (props.paginationTop ? undefined : pxToRem(50))};
  padding-bottom: ${(props) => (props.paginationTop ? pxToRem(50) : undefined)};
  margin-top: -${(props) => (props.paginationTop ? undefined : pxToRem(50))};
  margin-bottom: -${(props) => (props.paginationTop ? pxToRem(50) : undefined)};
  padding-right: ${pxToRem(100)};
  padding-left: ${pxToRem(100)};
  margin-right: ${pxToRem(-100)};
  margin-left: ${pxToRem(-100)};
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${pxToRem(61)};

  @media (max-width: 620px) {
    margin: 0;
    padding: 0;
  }
`;

const StyledLayoutFlex = styled.div`
  display: flex;
  justify-content: space-between;
  order: ${(props) => (props.paginationTop ? "1" : undefined)};
  padding: ${(props) => props.paginationMargin || `${pxToRem(17)} 0 0 0`};
  box-sizing: border-box;

  @media (max-width: 620px) {
    display: none;
  }
`;

const Caption = styled.div`
  flex: 0.8;
  position: relative;
  text-align: ${(props) => props.captionTextAligned || "right"};

  @media (max-width: 620px) {
    flex: auto;
    width: 100%;
    height: 4.5rem;
    overflow: hidden;
    text-align: ${(props) => props.captionTextAlignedM || "right"};
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
`;

const SliderRightPagination = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [area, setArea] = useState("right");
  const [slideIndex, setSlideIndex] = useState(0);
  const [paginationHover, setPaginationHover] = useState(false);
  // const [firstEnter, setFirstEnter] = useState(false);

  let el = useRef();
  let elSwiper = useRef();
  let elPaginationWrapper = useRef();
  let elPagination = useRef();
  let elPaginationActive = useRef();
  let elCursor = useRef();
  const elCursorDrag = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const { contextSafe } = useGSAP({ scope: el });

  const paginationChange = contextSafe((init = false) => {
    const paginationItem = [
      ...elPagination.current.querySelectorAll(".swiper-pagination-bullet"),
    ];
    const paginationActive = elPagination.current.querySelector(
      ".swiper-pagination-bullet-active"
    );

    if (!paginationActive) return;

    if (!props.paginationTitle) {
      paginationItem.forEach((item) => {
        if (item.classList.contains("swiper-pagination-bullet-active")) return;
        item.textContent = item.dataset.text;
      });

      paginationActive.textContent = convertToCircledNumber(
        paginationActive.textContent
      );
    }

    const getWidth = paginationActive.offsetWidth;
    const getLeft = paginationActive.offsetLeft;

    if (init) {
      // slide init
      gsap.set(elPaginationActive.current, {
        width: getWidth,
        x: getLeft,
      });
    } else {
      // slide chage
      gsap.to(elPaginationActive.current, {
        width: getWidth,
        x: getLeft,
        duration: 1.2,
        ease: CustomEase.create("custom", "M0,0 C0.33,0.22 0,1.01 1,1"),
      });
    }
  });

  const slideInit = contextSafe((el) => {
    setSwiper(el);
    paginationChange(true);
  });

  const slideChange = contextSafe((el) => {
    paginationChange();
    setSlideIndex(el.activeIndex);
  });

  const hideDragBtn = () => {
    if (props.paginationTitle) return;
    if (!isMobile) return;
    if (!el.current) return;
    if (!elCursorDrag.current) return;

    gsap.to(elCursorDrag.current, {
      scale: 0,
      duration: 0.3,
    });
  };

  useEffect(() => {
    if (!elSwiper.current) return;
    const swiperr = new Swiper(elSwiper.current, {
      slidesPerView: props.slidesPerViewM || 1,
      grabCursor: false,
      spaceBetween: props.spaceBetweenM || 10,
      speed: props.fade ? 0 : isMobile ? 300 : 1200,
      rewind: false,
      followFinger: true,
      allowTouchMove: true,
      effect: props.fade ? "fade" : "slide",
      freeMode: props.paginationTitle ? false : true,
      roundLengths: true,
      threshold: 0.2,
      resistanceRatio: 0.1,
      touchRatio: 1.5,
      touchAngle: 60,
      touchEventsTarget: props.paginationTitle ? "wrapper" : "container",
      pagination: {
        el: elPagination.current,
        clickable: true,
        renderBullet: function (index, className) {
          if (props.paginationTitle) {
            return `<span class="${className} pagination-index-${index}" data-text="${props.slides[index].paginationTitle}">${props.slides[index].paginationTitle}</span>`;
          } else {
            return `<span class="${className} pagination-index-${index}" data-text="${
              index + 1
            }">${index + 1}</span>`;
          }
        },
      },
      breakpoints: {
        // when window width is >= 640px
        640: {
          slidesPerView: props.slidesPerView || 1,
          spaceBetween: props.spaceBetween || 0,
          rewind: true,
          followFinger: isTablet ? true : false,
          allowTouchMove: isTablet ? true : false,
          freeMode: isTablet ? true : false,
        },
      },
      on: {
        init: (el) => {
          slideInit(el);
        },
        slideChange: (el) => {
          slideChange(el);
        },
        sliderFirstMove: () => {
          hideDragBtn();
        },
      },
    });

    return () => {
      swiperr.destroy();
    };
  }, []);

  const handleMouseMove = contextSafe((event) => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (paginationHover) return;

    const elRect = el.current.getBoundingClientRect();
    const cursorRect = elCursor.current.getBoundingClientRect();

    const x = event.clientX - elRect.left - cursorRect.width / 2;
    const y = event.clientY - elRect.top - cursorRect.height / 2;

    // if (!firstEnter) {
    //   gsap.set(elCursor.current, {
    //     left: x,
    //     top: y,
    //   });

    //   setFirstEnter(true);
    // }

    gsap.to(elCursor.current, {
      scale: 1,
      left: x,
      top: y,
      duration: 0.6,
      delay: 0.01,
      ease: "power4.out",
    });

    if (x < elRect.width / 2) {
      // Left area
      setArea("left");
    } else {
      // Right area
      setArea("right");
    }
  });

  const handleMouseClick = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (paginationHover) return;

    const tl = gsap.timeline({
      defaults: {
        duration: 0.2,
        ease: "power1.out",
      },
    });

    tl.to(elCursor.current, {
      scale: 0.8,
    });

    tl.to(elCursor.current, {
      scale: 1,
    });

    if (area === "left") {
      // Left area
      if (swiper) {
        swiper.slidePrev();
      }
    } else {
      // Right area
      if (swiper) {
        swiper.slideNext();
      }
    }
  });

  const handleMouseEnter = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 1,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
    });
  });

  const handleMouseLeave = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 0,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
      // onComplete: () => {
      //   setFirstEnter(false);
      // },
    });
  });

  const handlePaginationHover = contextSafe(() => {
    if (isMobile) return;
    if (paginationHover) return;
    setPaginationHover(true);
    setTimeout(() => {
      handleMouseLeave();
    }, 1);
  });

  const handlePaginationLeave = contextSafe(() => {
    if (isMobile) return;
    if (!paginationHover) return;
    setPaginationHover(false);
  });

  return (
    <Wrapper
      ref={el}
      padding={props.padding}
      backgroundColor={props.backgroundColor}
      bulletHighlightColor={props.bulletHighlightColor}
      cursorColor={props.cursorColor}
      paginationTop={props.paginationTop}
      paginationTitle={props.paginationTitle}
      paginationMobile={props.paginationMobile}
      paginationJustify={props.paginationJustify}
      perfectSize={props.perfectSize}
      noPeek={props.noPeek}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      cursorColorText={props.cursorColorText}
    >
      <Content paginationTop={props.paginationTop}>
        <div className="swiper" ref={elSwiper}>
          <div className="swiper-wrapper">
            {isMobile && !props.paginationTitle && (
              <ButtonCursor
                ref={elCursorDrag}
                text="Drag"
                fontSize={pxToRem(8)}
                sizeMobile={pxToRem(74)}
                topMobile={props.cursorDragTop || "50%"}
                leftMobile={props.cursorDragLeft || "89%"}
                bgColor={props.cursorColor}
                textColor={props.cursorColorText}
              />
            )}
            {props.slides.map((slide, index) => (
              <div
                className="swiper-slide"
                style={{ "--aspect": props.aspect || "16/9" }}
                key={index}
                zindex={props.slides.length - 1 - index}
              >
                <img
                  src={slide.src}
                  alt={slide.alt ?? `Slide ${index + 1}`}
                  draggable="false"
                />
              </div>
            ))}
          </div>
        </div>
        <StyledLayoutFlex
          className="swiper-pagination-wrapper"
          paginationTop={props.paginationTop}
          paginationMargin={props.paginationMargin}
        >
          <Drag>
            <div className="swiper-pagination-flex">
              <div
                ref={elPaginationWrapper}
                onMouseEnter={handlePaginationHover}
                onMouseLeave={handlePaginationLeave}
              >
                <div className="swiper-pagination" ref={elPagination}></div>
                <div
                  className="swiper-pagination-active"
                  ref={elPaginationActive}
                ></div>
              </div>
            </div>
          </Drag>

          {props.slides[0].caption && (
            <Caption
              captionTextAligned={props.captionTextAligned}
              captionTextAlignedM={props.captionTextAlignedM}
            >
              {props.slides.map(
                (slide, index) =>
                  slide.caption && (
                    <Paragraph4
                      className={slideIndex === index ? "active" : ""}
                      key={index}
                      noSplit={true}
                      noAnimated={true}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: slide.caption }}
                      />
                    </Paragraph4>
                  )
              )}
            </Caption>
          )}
        </StyledLayoutFlex>
      </Content>

      {!isMobile && (
        <div className="swiper-cursor" ref={elCursor}>
          <div className="swiper-cursor-inner">
            <span
              className={`swiper-cursor-text prev ${
                area === "left" && "active"
              }`}
            >
              Prev
            </span>
            <span
              className={`swiper-cursor-text next ${
                area === "right" && "active"
              }`}
            >
              Next
            </span>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default SliderRightPagination;
