import React from 'react';
import styled from 'styled-components';
import pxToRem from '../Utils/pxToRem';

const Container = styled.div`
  width: ${props => props.width || "100%"};
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  column-gap: ${pxToRem(15)};
  row-gap: ${props => props.rowGap || pxToRem(15)};
  margin: ${props => props.margin};

  @media (max-width: 620px) {
    grid-template-columns: repeat(6, 1fr);
    column-gap: ${props => props.colGapM || pxToRem(10)};
    row-gap: ${props => props.rowGapM || pxToRem(10)};
  }
`;

const Grid = (props) => {
  return (
    <Container className={props.className} width={props.width} rowGap={props.rowGap} colGapM={props.rowGapM} rowGapM={props.rowGapM} margin={props.margin}>{props.children}</Container>
  );
};

export default Grid;
