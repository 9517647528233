import React, { useMemo } from 'react';

/**
 * @typedef {import('../AwardList').AwardListNormalizedItem & { groupIndex: string }} ActiveGroup
 */

/**
 * @typedef {object} AwardListTableContextValue
 * @property {ActiveGroup?} activeGroup
 * @property {boolean} inView
 * @property {boolean} isLastGroup
 * @property {(group: string) => void} setActiveGroup
 */


/** @type {AwardListTableContextValue} */
const defaultContextValue = {
  activeGroup: null,
  inView: false,
  isLastGroup: false
};
export const AwardListTableContext = React.createContext(defaultContextValue);

/**
 * @typedef {object} AwardListTableContextProviderProps
 * @property {ActiveGroup?} activeGroup
 * @property {import('react').ReactNode} children
 * @property {boolean} inView
 * @property {boolean} lastGroup
 */

/** @type {import('react').FC<AwardListTableContextProviderProps>} */
export const AwardListTableContextProvider = (props) => {
  const {
    activeGroup,
    children,
    inView = false,
    lastGroup = false
  } = props;

  /** @type {AwardListTableContextValue} */
  const context = useMemo(() => {
    return {
      activeGroup: activeGroup,
      inView,
      isLastGroup: lastGroup,
    };
  }, [activeGroup, inView, lastGroup]);

  return (
    <AwardListTableContext.Provider value={context}>
      {children}
    </AwardListTableContext.Provider>
  );
};

export const useAwardListTableContext = () => React.useContext(AwardListTableContext) || defaultContextValue;
