import classNames from 'classnames';
import { motion, useIsPresent } from 'framer-motion';
import { kebabCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Easings } from '../../animation';
import classes from './ModalContent.module.scss';

const ModalContent = React.forwardRef((
  {
    body,
    contentProps: { theme = {}, ...contentProps } = {},
    hideModal,
    onBeforeOpen,
    onBeforeClose,
    onClose,
    onOpen
  },
  ref
) => {
  /** @type {import('react').MutableRefObject<Function>} */
  const closeCallBackRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isClosing, setClosing] = useState(false);
  const isPresent = useIsPresent();

  const computedStyle = useMemo(() => (
    Object.entries(theme).reduce((style, [key, value]) => ({
      ...style,
      [`--${kebabCase(key)}`]: value
    }), {})
  ), [theme]);

  const variants = useMemo(() => ({
    visible: () => ({
      y: 0,
      transition: {
        duration: 0.72,
        ease: Easings.brunoIn
      }
    }),
    hidden: () => ({
      y: '120%',
      transition: {
        duration: 0.72,
        ease: Easings.easeInCubic
      }
    })
  }), []);

  const handleShowModal = useCallback(() => {
    if (!isClosing && !isOpen) {
      setOpen(true);
      onOpen();
    }
    else {
      if (closeCallBackRef.current instanceof Function) {
        closeCallBackRef.current();
        closeCallBackRef.current = null;
      }
      setClosing(false);
      onClose();
    }
  }, [isClosing, isOpen, onClose, onOpen]);

  const close = useCallback((callback) => {
    if (!isClosing) {
      closeCallBackRef.current = callback;
      setOpen(false);
      setClosing(true);
      hideModal();

      if (onBeforeClose instanceof Function) {
        onBeforeClose();
      }
    }
  }, [hideModal, isClosing, onBeforeClose]);

  useEffect(() => {
    if (isPresent) {
      onBeforeOpen?.();
    }
  }, [isPresent, onBeforeOpen]);


  const Body = useMemo(() => {
    return React.createElement(body, {
      close,
      content: contentProps,
      isClosing,
      isOpen,
      theme
    })
  }, [body, close, contentProps, isClosing, isOpen, theme]);

  return (
    <div
      ref={ref}
      className={classNames([
        'Modal',
        classes.root
      ])}
      style={computedStyle}
    >
      <motion.div
        className={classes.contentWrapper}
        animate="visible"
        exit="hidden"
        initial="hidden"
        variants={variants}
        onAnimationComplete={handleShowModal}
      >
        {Body}
      </motion.div>
    </div>
  );
});

export default ModalContent;
