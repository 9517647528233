import React, { useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { gsap, useGSAP } from "../../gsap";

const Section = styled.div`
  background-color: ${(props) => props.backgroundColor || "#fff"};
  color: ${(props) => props.color || "#000"};
  margin-top: 92vh; // fallback
  margin-top: 92dvh;
  position: relative;
  z-index: 1;
  visibility: hidden;
`;

const Content = (props) => {
  const el = useRef();

  const location = useLocation();

  useGSAP(
    () => {
      gsap.to(el.current, {
        delay: location.state?.casetocase ? 0.8 : 1.4,
        autoAlpha: 1,
        duration: 0.01,
      });
    },
    {
      dependencies: [location.state?.casetocase],
      scope: el,
    }
  );

  return (
    <Section
      ref={el}
      className={props.className}
      color={props.color}
      backgroundColor={props.backgroundColor}
    >
      {props.children}
    </Section>
  );
};

export default Content;
