import { useGSAP } from "@gsap/react";
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { Draggable } from 'gsap/dist/Draggable';
import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin';
import { Flip } from 'gsap/dist/Flip';
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';

gsap.registerPlugin(
  CustomEase,
  Draggable,
  DrawSVGPlugin,
  Flip,
  InertiaPlugin,
  ScrollToPlugin,
  ScrollTrigger,
  SplitText,
  useGSAP
);

CustomEase.create(
  'customEase',
  'M0,0 C0.06,0 0,1 1,1'
);

CustomEase.create(
  'customEase2',
  'M0,0 C0.28,0 0,1 1,1'
);

CustomEase.create(
  'customEase3',
  'M0,0 C0.835,0 0.335,0.995 1,1'
);

gsap.config({
  nullTargetWarn: false
})

gsap.defaults({
  ease: "customEase",
  duration: 1.0,
  // Breaks the initialization of ScrollSmoother
  stagger: 0.1
});

// ScrollTrigger.normalizeScroll({ allowNestedScroll: true })

ScrollTrigger.matchMedia({
  // desktop
  '(min-width: 620px)': () => {
    ScrollTrigger.defaults({
      start: "top bottom-=10%",
      // markers: process.env.NODE_ENV === "development",
    });
  },

  // mobile
  '(max-width: 619px)': () => {
    ScrollTrigger.defaults({
      start: "top bottom",
      // markers: process.env.NODE_ENV === "development",
    });
  }
})

export * from 'gsap';

export {
  CustomEase,
  Draggable,
  DrawSVGPlugin,
  Flip,
  ScrollTrigger,
  SplitText,
  useGSAP
};
