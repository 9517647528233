import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @typedef {Object} UseUserSelectionColorOptions
 * @property {string} [color] - The color to use for the selection.
 * @property {string} [background] - The background color to use for the selection.
 */

/**
 * A hook that sets the color variables for the user selection.
 * @param {UseUserSelectionColorOptions} options - The options to use.
 * @return {void}.
 */
export function useUserSelectionColor(options) {
  const { key } = useLocation();

  const {
    color = '#fff',
    background = '#413f42'
  } = options;

  useEffect(() => {
    document.documentElement.setAttribute('data-user-selection-key', key);
    document.documentElement.style.setProperty('--user-selection-color', color);
    document.documentElement.style.setProperty('--user-selection-background', background);

    return () => {
      if (document.documentElement.getAttribute('data-user-selection-key') !== key) {
        return;
      }

      document.documentElement.removeAttribute('data-user-selection-key');
      document.documentElement.style.removeProperty('--user-selection-color');
      document.documentElement.style.removeProperty('--user-selection-background');
    };
  }, [background, color, key]);
}
