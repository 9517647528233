import React from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";

const Container = styled.div`
  padding: 0 calc(var(--case-container) * ${pxToRem(1)});
`;


/** @type {import('framer-motion').ForwardRefComponent<HTMLDivElement, import('react').ComponentProps<'div'>>} */
const LayoutContainer = React.forwardRef((props, ref) => {
  const {
    children,
    ...restProps
  } = props;

  return (
    <Container
      ref={ref}
      {...restProps}
    >
      {props.children}
    </Container>
  );
});

LayoutContainer.displayName = 'LayoutContainer';

export default LayoutContainer;
