import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import { Flex } from '../Flex';
import classes from './Details.module.scss';

function DetailsMobile({
  close,
  client,
  industry,
  services,
  year,
  link
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { href, target, text } = link || {};

  const buttonClickHandler = useMemo(() => {
    if (!href) {
      return null;
    }

    const isInternal = href.startsWith('/')

    if (isInternal) {
      return (
        () => close(() => navigate(href, { state: { from: location.pathname } }))
      );
    }

    return null;
  }, [close, href, navigate, location.pathname]);

  const LinkElement = useMemo(() => {
    if (!href) {
      return null;
    }

    return (
      <Button
        asLink
        href={href}
        target={target}
        noAnimation
        onClick={buttonClickHandler}
      >
        {text ?? href}
      </Button>
    )
  }, [buttonClickHandler, href, target, text]);

  return (
    <Flex
      className={classNames(
        classes.rootMobile,
        !LinkElement && classes.rootMobileNoBtn
      )}
      direction="column"
      grow={1}
    >
      <hr className={classes.hr} />

      <Flex
        className={classes.mobileSection}
        direction="column"
      >
        <Flex justifyContent="space-between">
          <div className={classes.label}>
            Client:
          </div>
          <div className={classes.value}>
            {client}
          </div>
        </Flex>

        <Flex justifyContent="space-between">
          <div className={classes.label}>
            Year:
          </div>
          <div className={classes.value}>
            {year}
          </div>
        </Flex>

        <Flex justifyContent="space-between">
          <div className={classes.label}>
            Industry:
          </div>
          <div className={classes.value}>
            {industry}
          </div>
        </Flex>
      </Flex>

      <hr className={classes.hr} />

      <Flex
        className={classes.mobileSection}
        justifyContent="space-between"
      >
        <div className={classes.label}>
          Services:
        </div>
        <Flex
          className={classes.list}
          direction="column"
          el="ul"
          style={{ textAlign: 'right' }}
        >
          {services.map((service, idx) => (
            <li
              key={idx}
              className={classes.value}
              style={{ overflow: 'hidden ' }}
            >
              {service}
            </li>
          ))}
        </Flex>
      </Flex>

      {LinkElement && <hr className={classes.hr} />}

      {LinkElement && (
        <Flex
          className={classes.linkMobileSection}
          direction="column"
        >
          {LinkElement}
        </Flex>
      )}
    </Flex>
  );
}

DetailsMobile.propTypes = {
  close: PropTypes.func,
  client: PropTypes.string,
  industry: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

DetailsMobile.defaultProps = {
  close: null,
  client: '',
  industry: '',
  services: [],
  year: 0
};

export default DetailsMobile;
