import React, { useRef, forwardRef } from "react";
import { useMedia } from "react-recipes";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import pxToRem from "../../Utils/pxToRem";

import Container from "../Layout/Container";
import Image from "../Media/Image";

import { gsap, useGSAP } from "../../../gsap";

const Wrapper = styled.section`
  background-color: ${(props) => props.backgroundColor || "#fff"};
  color: ${(props) => props.color || "#000"};
  margin: ${(props) => props.margin} !important;
  padding: ${(props) => props.padding} !important;
  position: ${(props) => props.position} !important;
  transform: translateY(7%);

  .overview__hero {
    margin-bottom: ${pxToRem(120)};

    @media screen and (max-width: 620px) {
      margin-bottom: calc(-44px + ${pxToRem(60)});
    }
  }

  /* .overview__hero img {
    display: none;

    &.active {
      display: block;
    }
  } */
`;

const ImageWrapper = styled.div`
  position: relative;
  top: -44px;

  @media screen and (max-width: 620px) {
    top: -40px;
  }
`;

const Section = forwardRef((props, el) => {
  // Responsive
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const location = useLocation();

  // const [isNextTransition, setIsNextTransition] = useAtom(nextTransition);

  // const el = useRef();
  const elImg = useRef();

  useGSAP(
    () => {
      if (!el.current) return;
      const tl = gsap.timeline({
        delay: location.state?.casetocase ? 3.5 : 2,
      });

      tl.to(el.current, {
        y: 0,
        duration: 1.4,
        ease: "customEase2",
      });

      if (!isMobile) {
        gsap.to(elImg.current, {
          y: 200,
          duration: 1.0,
          ease: "none",
          scrollTrigger: {
            trigger: el.current,
            start: "clamp(top bottom)",
            end: "clamp(bottom top)",
            endTrigger: el.current,
            scrub: true,
          },
        });
      } else {
        const img = elImg.current.querySelector("img");
        gsap.to(img, {
          scale: 1.2,
          duration: 1.0,
          ease: "none",
          scrollTrigger: {
            trigger: elImg.current,
            start: "clamp(top bottom)",
            end: "clamp(bottom top)",
            scrub: true,
          },
        });
      }
    },
    {
      dependencies: [el, location.state?.casetocase],
      scope: el,
    }
  );

  // TODO: not yet done
  // useLayoutEffect(() => {
  //   if (!isNextTransition) return;
  //     const clone = document.querySelector(".clone-transition");
  //     const img = elImg.current.querySelector("img");

  //     const flipTransition = () => {
  //       let state = Flip.getState([clone, img]);
  //       img.classList.add("active");
  //       clone.classList.remove("active");

  //       Flip.from(state, {
  //         zIndex: 98,
  //         fade: true,
  //         absolute: true,
  //         toggleClass: "flipping",
  //         duration: 1.4,
  //         ease: "customEase2",
  //         onComplete: () => {
  //           clone.innerHTML = "";
  //         }
  //       });
  //     };

  //     setTimeout(() => {
  //       flipTransition();
  //     }, 3000);
  // }, [isNextTransition]);

  return (
    <Wrapper
      ref={el}
      id={props.id}
      className={props.className}
      backgroundColor={props.backgroundColor}
      color={props.color}
      margin={props.margin}
      padding={props.padding}
      position={props.position}
    >
      <Container>
        <ImageWrapper ref={elImg}>
          <Image
            src={props.img}
            className="overview__hero"
            aspect="12/7"
            noAnimated={true}
            parallax={isMobile ? false : true}
            loading="eager"
            flipId="fullscreen"
          />
        </ImageWrapper>

        {props.children}
      </Container>
    </Wrapper>
  );
});

export default Section;
