import React, { useRef } from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import pxToRem from "../Utils/pxToRem";

// Component
import Label1 from "./Text/Label1";
import Label2 from "./Text/Label2";
import Paragraph2 from "./Text/Paragraph2";
import Border from "./Border";
import AnimTextPerline from "./Motion/Textperline";

import { gsap, ScrollTrigger, Draggable, useGSAP } from "../../gsap";
import LayoutFlex from "./Layout/Flex";
import ButtonCursor from "./ButtonCursor";

const MasterWrapper = styled.div`
  position: relative;
  @media (max-width: 620px) {
    width: auto;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 620px) {
    display: flex;
    gap: ${pxToRem(80)};
    width: max-content;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${pxToRem(108)};
  row-gap: ${pxToRem(108)};
  list-style: none;
  padding: 0;
  position: relative;

  @media (max-width: 620px) {
    column-gap: ${pxToRem(80)};
    row-gap: ${pxToRem(80)};
  }

  @media screen and (max-width: 620px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &:not(:first-child) {
    li {
      &:first-child {
        &:before {
          content: "";
          width: 1px;
          height: 100%;
          background-color: ${(props) =>
            props.borderColor || "rgba(27, 26, 28, 0.2)"};
          position: absolute;
          top: 0;
          left: -${pxToRem(54)};
          transform-origin: top;
          display: none;

          @media (max-width: 620px) {
            left: -${pxToRem(40)};
            display: block;
          }
        }
      }
    }
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${pxToRem(110)};
  position: relative;

  @media (max-width: 620px) {
    width: ${(props) => props.itemWidthMobile || pxToRem(230)};
    gap: ${pxToRem(82)};
  }

  &.active {
    &:nth-child(3n + 1),
    &:nth-child(3n + 2) {
      &:after {
        transform: scaleY(1);
      }
    }
  }

  &:nth-child(3n + 1),
  &:nth-child(3n + 2) {
    &:after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: ${(props) =>
        props.borderColor || "rgba(27, 26, 28, 0.2)"};
      position: absolute;
      top: 0;
      right: -${pxToRem(54)};
      transform-origin: top;
      transform: scaleY(0);
      transition: transform var(--transition-default);

      @media (max-width: 620px) {
        right: -${pxToRem(40)};
      }
    }
  }

  .number-text {
    font-family: "Suisse Intl", sans-serif;
    font-weight: 600;
    font-size: clamp(12px, ${pxToRem(13)}, ${pxToRem(13)});
    letter-spacing: 0.05em;
    line-height: 1.769;
    text-transform: uppercase;
  }
`;

const Pointnumber = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();
  const elWrapper = useRef();
  const itemRefs = useRef([]);
  const itemRefs2 = useRef([]);

  const elCursor = useRef();

  // This function is used to assign a ref for each item
  const addItemRef = (el, index) => {
    itemRefs.current[index] = el;
  };

  const addItemRef2 = (el, index) => {
    itemRefs2.current[index] = el;
  };

  let draghide = false;

  const { contextSafe } = useGSAP({ scope: el });

  const handleMouseClick = contextSafe(() => {
    if (!isMobile) return;
    if (!el.current) return;
    if (!elCursor.current) return;

    gsap.to(elCursor.current, {
      scale: 0,
      duration: 0.3,
      display: "none",
      overwrite: true,
    });

    draghide = true;
  });

  useGSAP(
    () => {
      if (isMobile) {
        Draggable.create(elWrapper.current, {
          type: "x",
          bounds: el.current,
          inertia: true,
          force3D: true,
          dragResistance: -0.3,
          minimumMovement: 5,
          lockAxis: "x",
          onDragStart: () => {
            if (draghide) return;
            handleMouseClick();
          },
        });
      }

      gsap.utils
        .toArray([itemRefs.current, itemRefs2.current])
        .forEach((ref, index) => {
          if (ref.length <= 0) return;
          ScrollTrigger.create({
            trigger: el.current,
            onEnter: () => {
              gsap.delayedCall(index * 0.2, () => {
                ref.classList.add("active");
              });
            },
          });
        });
    },
    {
      dependencies: [isMobile],
      scope: el,
    }
  );


  return (
    <div ref={el}>
      <Label1 text={props.title} />

      {props.title && (
        <Border
          className={isMobile && props.title ? "mt-4" : "mt-4 mb-5"}
          color={props.borderColor}
        />
      )}

      {!props.title && (
        <Border
          className={isMobile ? "mt-9" : "mt-7 mb-5"}
          color={props.borderColor}
        />
      )}

      <MasterWrapper>
        {isMobile && (
          <ButtonCursor
            ref={elCursor}
            text="Drag"
            fontSize={pxToRem(8)}
            sizeMobile={pxToRem(74)}
            topMobile="50%"
            leftMobile="83%"
            bgColor={props.cursorBgColor}
            textColor={props.cursorTextColor}
          />
        )}
        <Wrapper ref={elWrapper} className={isMobile ? "my-9" : ""}>
          <List className={props.className} borderColor={props.borderColor}>
            {props.items.map((item, index) => (
              <Item
                ref={(el) => addItemRef(el, index)}
                key={index}
                borderColor={props.borderColor}
                itemWidthMobile={props.itemWidthMobile}
              >
                <AnimTextPerline
                  right={true}
                  trigger={el.current}
                  delay={index * 0.15}
                >
                  <LayoutFlex
                    justify="flex-start"
                    align="center"
                    gap={pxToRem(8)}
                  >
                    <Label2 text={item.number} />
                    {item.numbertext && (
                      <p className="number-text">{item.numbertext}</p>
                    )}
                  </LayoutFlex>
                </AnimTextPerline>

                <AnimTextPerline trigger={el.current}>
                  <Paragraph2>{item.desc}</Paragraph2>
                </AnimTextPerline>
              </Item>
            ))}
          </List>

          {!isMobile && props.items2 && (
            <Border className="my-5" color={props.borderColor} />
          )}

          {props.items2 && (
            <List className={props.className} borderColor={props.borderColor}>
              {props.items2.map((item, index) => (
                <Item
                  ref={(el) => addItemRef2(el, index)}
                  key={index}
                  borderColor={props.borderColor}
                  itemWidthMobile={props.itemWidthMobile}
                >
                  <AnimTextPerline
                    right={true}
                    trigger={el.current}
                    delay={0.15 * props.items.length + 0.15 * index}
                  >
                    <LayoutFlex
                      justify="flex-start"
                      align="center"
                      gap={pxToRem(8)}
                    >
                      <Label2 text={item.number} />
                      {item.numbertext && (
                        <p className="number-text">{item.numbertext}</p>
                      )}
                    </LayoutFlex>
                  </AnimTextPerline>

                  <AnimTextPerline trigger={el.current}>
                    <Paragraph2>{item.desc}</Paragraph2>
                  </AnimTextPerline>
                </Item>
              ))}
            </List>
          )}
        </Wrapper>
      </MasterWrapper>

      {isMobile && (
        <Border className={isMobile ? "" : "my-5"} color={props.borderColor} />
      )}
    </div>
  );
};

export default Pointnumber;
