import { useMouse } from 'ahooks';
import { AnimatePresence, useForceUpdate, useInView, useIsPresent } from 'framer-motion';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMedia } from 'react-recipes';
import { lenisAtom } from '../../atom/transitionAtom';
import { MotionBadge } from '../Badge';
import { Portal } from '../Portal/Portal';
import { AwardListTableRow } from './AwardListTableRow';
import { AwardListTableRowMobile } from './AwardListTableRow.mobile.';
import classes from './AwardsListTable.module.scss';
import { AwardListTableContextProvider, AwardListTableRowContextProvider } from './contexts';
import { Easings } from '../../animation';

/**
 * @typedef {object} AwardListBadgeProps
 * @property {number} clientX
 * @property {number} clientY
 * @property {string} type
 */

/** @type {import('react').FC<AwardListBadgeProps>} */
const AwardListBadge = (props) => {
  const {
    clientX = 0,
    clientY = 0,
    type
  } = props;

  const rootEl = useRef(null);
  const y = clientY + (rootEl.current?.offsetHeight || 0);
  const x = clientX - (rootEl.current?.offsetWidth || 0) / 2

  const isPresent = useIsPresent();

  return (
    <MotionBadge
      ref={rootEl}
      style={{
        visibility: rootEl.current ? 'visible' : 'collapse',
        position: 'absolute',
        x,
        y
      }}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{
        duration: isPresent ? 0.8 : 0.4,
        ease: Easings.easeOutCubic
      }}
    >
      {type}
    </MotionBadge>
  );
}

/**
 * @typedef {object} AwardListTableProps
 * @property {never} children
 * @property {import('./AwardList').AwardListItem[]} items
 */

/** @type {import('react').FC<AwardListTableProps>} */
export const AwardListTable = (props) => {
  const {
    items = []
  } = props;

  /** @type {HTMLTableElement} */
  const tableRef = useRef(null);

  const [activeGroup, setActiveGroup] = useState(null);

  const inView = useInView(tableRef);
  const [forceRender] = useForceUpdate();
  const { clientX = 0, clientY = 0 } = useMouse();
  const isMobile = useMedia(['(max-width: 640px)'], [true], false);

  /** @type {[import('@studio-freight/lenis').default]} */
  const [lenis] = useAtom(lenisAtom);

  /** @type {import('./AwardList').AwardListNormalizedItem[][]} */
  const data = useMemo(() => {
    return (
      items.reduce(
        (acc, { awards, ...rest }) => ([
          ...acc,
          awards.map((award) => ({ ...rest, ...award }))
        ]),
        []
      )
    );
  }, [items]);

  /** @type {(row: import('./AwardList').AwardListNormalizedItem) => () => void} */
  const handleClick = useCallback((row) => () => {
    window.open(row.href, '_blank');
  }, []);

  const handleMouseEnter = useCallback((nextActiveGroup) => () => {
    setActiveGroup(nextActiveGroup);
  }, [])

  const handleMouseLeave = useCallback(() => {
    setActiveGroup(null);
  }, []);

  const handleScrollEnd = useCallback((e) => {
    forceRender();
    // if (!e.isScrolling) {
    //   forceRender();
    // }
  }, [forceRender]);

  useEffect(() => {
    if (!inView) {
      return;
    }

    lenis.on('scroll', handleScrollEnd);

    return () => {
      lenis.off('scroll', handleScrollEnd);
    };
  }, [lenis, inView, handleScrollEnd]);

  return (
    <table
      ref={tableRef}
      className={classes.root}
    >
      <tbody>
        {data.map((group, groupIndex) => (
          <AwardListTableContextProvider
            key={groupIndex}
            activeGroup={activeGroup}
            inView={inView}
            lastGroup={data.length === groupIndex + 1}
          >
            {group.map((row, index) => (
              <AwardListTableRowContextProvider
                key={`${groupIndex}-${index}`}
                value={{
                  group: groupIndex,
                  isFirstRow: index === 0,
                  isLastRow: group.length === index + 1
                }}
              >
                {!isMobile && (
                  <AwardListTableRow
                    key={`row-${groupIndex}-${index}`}
                    data-group-id={groupIndex}
                    award={row.award}
                    category={row.category}
                    href={row.href}
                    title={row.title}
                    type={row.type}
                    onClick={handleClick(row)}
                    onMouseEnter={handleMouseEnter({ groupIndex, ...row })}
                    onMouseLeave={handleMouseLeave}
                  />
                )}

                {isMobile && (
                  <AwardListTableRowMobile {...row} />
                )}
              </AwardListTableRowContextProvider>
            ))}

            <AnimatePresence key={`portal-${groupIndex}`}>
              {activeGroup?.groupIndex === groupIndex && (
                <Portal>
                  <AwardListBadge
                    clientX={clientX}
                    clientY={clientY}
                    type={activeGroup.type}
                  />
                </Portal>
              )}
            </AnimatePresence>
          </AwardListTableContextProvider>
        ))}
      </tbody>
    </table>
  );
}
