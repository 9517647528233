import { useDebounceFn, useMouse, useSize } from 'ahooks';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMedia } from 'react-recipes';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { useMergedRefs } from '../../hooks';
import pxToRem from '../Utils/pxToRem';
import VideoCustomPlayer, { VideoCustomPlayerEvents } from '../VideoCustomPlayer/VideoCustomPlayer';
import { useSliderRegularContext } from './Motion/contexts';

const Root = styled.div`
  overflow: hidden;

  &[data-active='false'] {
    [class^="VideoCustomPlayer__VideoMedia"] {
      transform: scale(0.6) translate(-80%, -80%);
    }
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  bottom: 0;

  [class^="VideoCustomPlayer__OuterWrapper"],
  [class^="VideoCustomPlayer__Wrapper"],
  [class^="VideoCustomPlayer__VideoElement"] {
    height: 100%;
  }
`;

const MotionVideoContainer = motion(VideoContainer);

/**
 * @typedef {object} VideoSlideProps
 * @property {import('../VideoCustomPlayer/VideoCustomPlayer').VideoCustomPlayerPartialProps} player
 */

/** @type {import('framer-motion').ForwardRefComponent<HTMLElement, VideoSlideProps>} */
export const VideoSlide = React.forwardRef((props, ref) => {
  const {
    player: { ref: playerRef, ...playerProps } = {},
  } = props;

  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const rootRef = useRef(null);
  const captureRootRef = useMergedRefs(rootRef, ref);

  /** @type {import('react').MutableRefObject<HTMLVideoElement>} */
  const videoRef = useRef(null);
  const captureVideoRef = useMergedRefs(videoRef, playerRef);

  /** @type {import('react').MutableRefObject<import('../VideoCustomPlayer/VideoCustomPlayer').VideoCustomPlayerOutRef>} */
  const videoOutRef = useRef();

  const [isActive, setActive] = useState(false);

  const { hideCursor, showCursor } = useSliderRegularContext();
  const { isActive: isActiveSlide } = useSwiperSlide();
  const isMobile = useMedia(['(max-width: 640px)'], [true], false);
  const size = useSize(rootRef) ?? { width: 0, height: 0 };
  const { clientX, clientY } = useMouse();

  const { run, cancel } = useDebounceFn(
    useCallback(() => {
      const element = document.elementFromPoint(clientX, clientY);
      if (
        !rootRef.current.contains(element) ||
        element === videoRef.current ||
        videoRef.current.contains(element)
      ) {
        return;
      }

      showCursor();
    }, [clientX, clientY, showCursor]),
    {
      wait: 250
    }
  );

  const onVideoPlay = useCallback(() => {
    setActive(true);
  }, []);

  const onVideoPause = useCallback(() => {
    cancel();
    setActive(false);
    run();
  }, [cancel, run]);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const onMouseOverRoot = useCallback((e) => {
    if (videoRef.current.contains(e.target)) {
      hideCursor();
    }
  }, [hideCursor]);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const onClickContainer = useCallback((e) => {
    e.stopPropagation();
  }, []);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const onMouseMoveContainer = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    const videoEl = videoRef.current;

    videoEl?.addEventListener(VideoCustomPlayerEvents.pause, onVideoPause);
    videoEl?.addEventListener(VideoCustomPlayerEvents.play, onVideoPlay);

    return () => {
      videoEl?.removeEventListener(VideoCustomPlayerEvents.pause, onVideoPause);
      videoEl?.removeEventListener(VideoCustomPlayerEvents.play, onVideoPlay);
    };
  }, [videoRef, isMobile, onVideoPause, onVideoPlay]);

  useEffect(() => {
    setActive(false);
  }, [isMobile]);

  const animateProps = useMemo(() => ({
    y: isActive ? pxToRem(0) : pxToRem(-56),
    x: isActive ? pxToRem(0) : pxToRem(60),
    width: isActive ? size.width : size.width / 7,
    height: isActive ? size.height : size.height / 7
  }), [isActive, size.width, size.height]);

  useEffect(() => {
    if (!isActiveSlide && isActive) {
      videoOutRef.current?.pause({ dispatchCustomEvent: false })
      return;
    }

    if (isActiveSlide && isActive) {
      videoOutRef.current?.play({ dispatchCustomEvent: false });
    }
  }, [isActive, isActiveSlide]);

  return (
    <Root
      ref={captureRootRef}
      data-active={isActive}
      onMouseOver={onMouseOverRoot}
    >
      <img src={props.src} alt={props.src} />

      {!isMobile && (
        <MotionVideoContainer
          animate={animateProps}
          transition={{
            ease: 'easeInOut',
            delay: isActive ? 0 : 0.82,
          }}
          onClick={onClickContainer}
          onMouseMove={onMouseMoveContainer}
        >
          <VideoCustomPlayer
            {...playerProps}
            ref={captureVideoRef}
            outRef={videoOutRef}
            withViewportObserver={false}
          />
        </MotionVideoContainer>
      )}
    </Root>
  )
});

VideoSlide.displayName = 'VideoSlide';
