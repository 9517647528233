import React, {
  useState,
  useRef,
  useMemo,
  forwardRef,
  useEffect,
} from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";

import Chapter from "./Text/Chapter";

import { gsap } from "../../gsap";

const Wrapper = styled.div`
  position: relative;
  font-size: clamp(14px, ${pxToRem(16)}, 16px);
  top: -0.1em;
  /* min-width: 197px; */
  width: 197px;
  padding-bottom: 5rem;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 620px) {
    padding-bottom: 2rem;
    min-width: inherit;
    width: ${pxToRem(200)};
    transform: scaleX(-100%);
  }

  &.active {
    .case-nav-box {
      background-color: var(--color-headerpanel);

      svg {
        rect {
          fill: var(--color-headertext);
        }
      }
    }

    .case-nav-list {
      background-color: var(--color-headerpanel);
    }
  }
`;

const Box = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;
  height: 54px;
  padding: clamp(14px, ${pxToRem(16)}, 16px);
  /* width: ${pxToRem(59)};
  height: ${pxToRem(54)}; */
  /* padding: ${pxToRem(14)}; */
  overflow: hidden;
  box-sizing: border-box;
  background-color: #2b292c;
  transition: background-color 0.3s ease;
  border-radius: var(--element-radius);

  @media (max-width: 620px) {
    margin-left: auto;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  overflow: hidden;
  backface-visibility: hidden;

  .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.8s var(--transition-ease);
    will-change: opacity;
  }

  .case-head {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.8s var(--transition-ease);
  }

  .chapter {
    position: relative;
    z-index: 2;
    margin-top: ${pxToRem(-2)};

    @media (max-width: 620px) {
      line-height: 1;
      margin-top: 0;
    }

    .numbertext,
    .numbericon {
      @media (max-width: 620px) {
        line-height: 1;
      }
    }
  }

  .hashtag {
    font-family: "Suisse Intl", sans-serif;
    font-weight: 400;
    font-size: clamp(5px, ${pxToRem(8)}, 7px);
    letter-spacing: 0.15em;
    line-height: 1.25;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 620px) {
      font-size: 5px;
    }

    @media screen and (min-width: 1800px) {
      font-size: 6px;
    }
  }

  .title {
    font-family: "Suisse Intl", sans-serif;
    font-weight: 300;
    font-size: clamp(14px, ${pxToRem(16)}, 16px);
    letter-spacing: 0.02em;
    line-height: 1;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    white-space: nowrap;

    @media (max-width: 1200px) {
      top: 45%;
      font-size: 16px;
      line-height: normal;
    }
  }

  .tap {
    display: none;

    @media (max-width: 1200px) {
      display: block;

      font-family: "Suisse Intl", sans-serif;
      font-weight: 300;
      font-size: 10px;
      letter-spacing: 0.03em;
      line-height: 1.25;
      text-decoration: underline;

      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      white-space: nowrap;
    }

    .line {
      transition-delay: 0.15s;
    }
  }

  .line {
    display: block;
    transition: transform 0.8s var(--transition-ease);
    transform: translateY(105%);
  }
`;

const List = styled.ul`
  position: relative;
  top: -54px;
  /* top: -${pxToRem(54)}; */
  margin-bottom: -${pxToRem(54)};
  display: flex;
  gap: ${pxToRem(8)};
  padding: ${pxToRem(16)} ${pxToRem(14)};
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  background-color: inherit;
  color: inherit;
  list-style: none;
  overflow: hidden;
  height: 0;
  clip-path: inset(0% 0% 100% 0%);
  background-color: #2b292c;
  transition: background-color 0.3s ease;
  border-radius: var(--element-radius);

  li {
    position: relative;
    transform: translate3d(0px, 30%, 0px);
    will-change: transform;
    transform-style: preserve-3d;

    &:hover,
    &:focus {
      .line {
        transform: translateY(0%);
      }

      .overlay-bg {
        opacity: 0.7;
      }

      .case-head {
        opacity: 1 !important;
      }

      img {
        transform: scale(1.2);
      }
    }

    &.active {
      display: none;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    overflow: hidden;
  }

  img {
    width: 100%;
    object-fit: cover;
    transition: transform 0.8s var(--transition-ease);
    will-change: transform;
  }

  .link {
    @media (max-width: 1200px) {
      pointer-events: none;
    }

    &.is--event {
      @media (max-width: 1200px) {
        pointer-events: auto;
      }
    }
  }
`;

const Casenav = forwardRef((props, ref) => {
  const location = useLocation();

  const [isOpen, setOpen] = useState(false);
  const [isEvent, setEvent] = useState(false);
  const [isEventIndex, setEventIndex] = useState();

  const elBox = useRef();
  const elList = useRef();

  const toggleEvent = (index, event) => {
    if (isEventIndex === index) {
      setEvent(false);
      setEventIndex(null);
    } else {
      setEvent(true);
      setEventIndex(index);
    }
  };

  const routerState = useMemo(
    () => ({
      from: location.pathname,
      casetocase: true,
      next: false,
    }),
    [location]
  );

  const isLinkActive = useMemo(
    (to) => {
      return (linkTo) => (linkTo === location.pathname ? "active" : "");
    },
    [location.pathname]
  );

  const mouseHover = () => {
    if (isOpen) return;
    setOpen(true);

    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        defaults: {
          duration: 0.8,
          ease: "customEase2",
          overwrite: true,
        },
      });

      tl.to(elBox.current, {
        yPercent: -100,
      });

      tl.to(
        elList.current,
        {
          height: "auto",
          clipPath: "inset(0% 0% 0% 0%)",
        },
        0
      );

      tl.to(
        ".case-nav-item",
        {
          y: 0,
          force3D: true,
          ease: "power2.out",
        },
        0
      );
    }, ref);

    return () => ctx.revert();
  };

  const mouseLeave = () => {
    if (!isOpen) return;
    setOpen(false);

    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        defaults: {
          duration: 0.8,
          ease: "customEase3",
          overwrite: true
        },
      });

      tl.to(elBox.current, {
        yPercent: 0,
      });

      tl.to(
        elList.current,
        {
          height: 0,
          clipPath: "inset(0% 0% 100% 0%)",
        },
        0
      );

      tl.to(
        ".case-nav-item",
        {
          y: "30%",
          force3D: true,
          stagger: {
            each: 0.1,
            from: "end",
          },
        },
        0
      );
    }, ref);

    return () => ctx.revert();
  };

  useEffect(() => {
    mouseLeave();
  }, [location.pathname]);
  return (
    <Wrapper
      ref={ref}
      className="case-nav"
      onMouseEnter={mouseHover}
      onClick={mouseHover}
      onMouseLeave={mouseLeave}
    >
      <Box
        ref={elBox}
        className="case-nav-box"
        backgroundColor={props.backgroundColor}
      >
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="5" width="2" height="2" fill="white" />
          <rect x="5" y="5" width="2" height="2" fill="white" />
          <rect width="2" height="2" fill="white" />
          <rect x="5" width="2" height="2" fill="white" />
        </svg>
      </Box>

      <List ref={elList} className="case-nav-list">
        {props.items.map((item, index) => (
          <li
            key={index}
            className={`${isLinkActive(item.route)} case-nav-item`}
            onClick={() => toggleEvent(index)}
          >
            <Link
              className={isEventIndex === index ? "is--event link" : "link"}
              state={routerState}
              to={item.route}
            >
              <Overlay>
                <div className="overlay-bg"></div>
                <div className="case-head">
                  <Chapter
                    className="chapter"
                    color="#ffffff"
                    fontSize={`clamp(8px, ${pxToRem(11)}, 9px)`}
                    fontSizeM={`${pxToRem(8)}`}
                    fontSizeWide={`8px`}
                    number={index + 1}
                  />
                  <div className="hashtag">
                    {item.hashtag.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </div>
                </div>
                <p className="title">
                  <span className="masking-text">
                    <span className="line">{item.name}</span>
                  </span>
                </p>
                <p className="tap">
                  <span className="masking-text">
                    <span className="line">Tap to OPEN</span>
                  </span>
                </p>
              </Overlay>
              <img src={item.img} alt={item.name} />
            </Link>
          </li>
        ))}
      </List>
    </Wrapper>
  );
});

export default Casenav;
