import { motion } from 'framer-motion';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { NavLink, useLocation } from 'react-router-dom';
import { Easings } from '../animation';
import { getRouteObject } from '../routing';
import Panel from './Panel';
import './SiteHeader.scss';

/** @type {(color: Parameters<typeof rgba>['0']) => ReturnType<typeof rgba>} */
const colorFn = (color, opacity = 0.3) => {
  return rgba(color, opacity);
}

function resolveColor(inPanel, isHovered) {
  const color = getComputedStyle(document.documentElement).getPropertyValue("--color-headerlinks");
  if (!inPanel || (inPanel && isHovered)) {
    return color;
  }

  return colorFn(color);
}

/** @type {import('framer-motion').Variants} */
const variants = {
  closed: {
    y: "100%"
  },
  open: {
    y: 0
  }
};

const SiteHeaderLinks = forwardRef((
  {
    isOpen,
    links
  },
  ref
) => {
  const location = useLocation();

  const [inPanel, setInPanel] = useState(false);
  const [hoveredRoute, setHoveredRoute] = useState(null);

  const handler = useCallback((value, set) => () => set(value), []);

  const routerState = useMemo(() => ({
    from: location.pathname
  }), [location]);

  const activeRoute = useMemo(() => (
    getRouteObject(location.pathname) ?? {}
  ), [location.pathname]);

  const color = getComputedStyle(document.documentElement).getPropertyValue("--color-headerlinks");

  const getRouteColor = useCallback((route) => {
    if (isMobile) {
      return color;
    }

    return (
      activeRoute.route === route
        ? colorFn(color)
        : resolveColor(
          inPanel && !!hoveredRoute,
          hoveredRoute === route
        )
    );
  }, [activeRoute.route, color, hoveredRoute, inPanel])

  return (
    <Panel
      ref={ref}
      style={{
        paddingLeft: 40,
        paddingRight: 40
      }}
    >
      <motion.ul
        animate={isOpen ? "open" : "closed"}
        className="SiteHeaderLinks"
        variants={{
          open: {
            transition: { staggerChildren: 0.08, delayChildren: 0.2 }
          }
        }}
        onMouseEnter={handler(true, setInPanel)}
        onMouseLeave={handler(false, setInPanel)}
      >
        {links.map(({ route, name }) => (
          !route.includes('case') && (
            <NavLink
              key={route}
              data-disabled={activeRoute.route === route ? '' : null}
              to={route}
              state={routerState}
              onMouseEnter={handler(route, setHoveredRoute)}
              onMouseLeave={handler(null, setHoveredRoute)}
            >
              <div style={{ overflow: 'hidden' }}>
                <motion.div
                  initial={{ y: "100%" }}
                  animate={{ color: getRouteColor(route) }}
                  transition={{
                    duration: 0.6,
                    ease: Easings.easeOutCubic
                  }}
                  variants={variants}
                >
                  {name}
                </motion.div>
              </div>
            </NavLink>
          )
        ))}
      </motion.ul>
    </Panel>
  );
});

SiteHeaderLinks.propTypes = {
  isOpen: PropTypes.bool,
  links: PropTypes.array
};

SiteHeaderLinks.defaultProps = {
  isOpen: false,
  links: []
};

export default SiteHeaderLinks;
