import { getDisplayName, mergeCallbacks } from '../../../utils';
import { Flex } from '../../Flex';
import Popover from '../../Popover/Popover';
import React, { useCallback, useRef } from 'react';
import classes from './WithPopover.module.scss';
import { useMergedRefs } from '../../../hooks';

/**
 * @typedef {object} WithPopoverPartialProps
 * @property {import('react').ReactNode} content
 * @property {string} cloneChildren
 */

/**
 * @template {import('../Button').ButtonProps} P
 * @param {import('react').ComponentType<P>} Component
 */
export function withPopover(Component) {

  const Opener = (props) => {
    const {
      ref,
      elRef,
      boundEvents,
      children,
      // eslint-disable-next-line no-unused-vars
      isVisible,
      onClick,
      ...rest
    } = props;

    ref(elRef.current);

    return (
      <Component
        {...rest}
        ref={elRef}
        onClick={mergeCallbacks(boundEvents?.onClick, onClick)}
      >
        {children}
      </Component>
    );
  }

  /**
   * @type {ReturnType<typeof import('react').forwardRef<HTMLElement, P & WithPopoverPartialProps>}
   **/
  const WithPopover = React.forwardRef((props, ref) => {
    const {
      cloneChildren,
      content,
      ...componentProps
    } = props;

    /** @type {import('react').MutableRefObject<HTMLElement>} */
    const elRef = useRef(null);

    /** @type {import('react').MutableRefObject<HTMLElement>} */
    const cloneRef = useRef(null);

    const captureRef = useMergedRefs(ref, elRef);

    const calculateOffset = useCallback(() => {
      const offTop = cloneRef.current?.offsetTop ?? 0;
      const offsetHeight = cloneRef.current?.offsetHeight ?? 0;
      const offY = -1 * (offTop + offsetHeight);
      const wRef = elRef.current?.getBoundingClientRect?.().width ?? 0;
      const wClone = cloneRef.current?.getBoundingClientRect?.().width ?? 0;
      const offX = Math.floor((wRef - wClone) / 2);

      return [offX, offY];
    }, [elRef]);

    return (
      <Popover
        focusTrapOptions={{ active: false }}
        offset={calculateOffset}
        opener={Opener}
        openerProps={{
          ...componentProps,
          elRef: captureRef
        }}
        overlayProps={{
          invisible: true,
          noPointerEvents: true
        }}
        placement="bottom"
      >
        <Flex
          className={classes.root}
          direction="column"
        >
          <div className={classes.content}>
            {content}
          </div>

          {(
            React.createElement(
              Component,
              {
                ...componentProps,
                ref: cloneRef,
                pointerEvents: 'none',
                noAnimation: true
              },
              props.cloneChildren ?? props.children
            )
          )}
        </Flex>
      </Popover>
    )
  });

  WithPopover.displayName = `WithPopover(${getDisplayName(Component)})`;

  return WithPopover;
}
