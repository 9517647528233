import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { useAppContext } from '../AppContext';
import { ClipPathMask } from '../ClipPathMask';
import { Flex } from '../Flex';
import { Image } from '../Image';
import Modal from '../Modal/Modal';
import { MaskedReveal } from '../Motion';
import { MotionFlex } from '../MotionFlex';
import classes from './ListItemMobile.module.scss';
import ModalBody from './ModalBody';
import ModalContent from './ModalContent';

function ListItemMobile({
  className,
  idx,
  listItemData
}) {
  const {
    card: image,
    client,
    industry,
    name,
    title
  } = listItemData;
  /** @type {import('react').MutableRefObject<HTMLElement | null>} */
  const rootRef = useRef(null);
  const { set } = useAppContext();

  const handleOnBeforeClose = useCallback(() => {
    set('navButtonVisible', true);
    set('footerVisible', true);
    document.documentElement.style.overflowY = 'auto';
  }, [set]);

  const handleOnBeforeOpen = useCallback(() => {
    set('navButtonVisible', false);
    set('footerVisible', false);
    document.documentElement.style.overflowY = 'hidden';
  }, [set]);

  return (
    <Modal
      body={ModalBody}
      contentEl={ModalContent}
      contentProps={listItemData}
      onBeforeClose={handleOnBeforeClose}
      onBeforeOpen={handleOnBeforeOpen}
      overlayProps={{ className: classes.overlay, invisible: false, alpha: 0.82 }}
      withOverlay
    >
      {({ showModal }) => (
        <Flex
          ref={rootRef}
          className={className}
          direction="column"
          onClick={showModal}
        >
          <Flex
            className={classes.body}
            direction="column"
            alignItems="center"
          >
            <ClipPathMask
              as={MotionFlex}
              className={classes.imageContainer}
              maskDirection="bottom"
              duration={1.8}
            >
              <Image
                className={classes.image}
                alt={name}
                src={image.src}
                preSrc={image.preSrc}
              />

              <div className={classes.imageMask} />
            </ClipPathMask>

            <Flex
              className={classes.nameContainer}
              alignItems="center"
              justifyContent="space-between"
            >
              <MaskedReveal auto delay={0.4}>
                <Flex
                  className={classNames(
                    classes.decorator,
                    classes.decoratorLeft
                  )}
                >
                  N&#176;
                </Flex>
              </MaskedReveal>

              <MaskedReveal auto delay={0.1}>
                <Flex
                  className={classes.name}
                >
                  {name}
                </Flex>
              </MaskedReveal>

              <MaskedReveal auto delay={0.4}>
                <Flex
                  className={classNames(
                    classes.decorator,
                    classes.decoratorRight
                  )}
                  justifyContent="flex-end"
                >
                  <Flex
                    className={classes.rounded}
                    alignItems="center"
                    el="span"
                    justifyContent="center"
                  >
                    {idx}
                  </Flex>
                </Flex>
              </MaskedReveal>
            </Flex>

            <Flex className={classes.separator}>
              <MaskedReveal auto delay={0.1}>
                /
              </MaskedReveal>
            </Flex>
          </Flex>

          <Flex
            className={classes.footer}
            alignItems="center"
            direction="column"
            gap={2}
          >
            <MaskedReveal auto delay={0.6}>
              <Flex>
                {client}
              </Flex>
            </MaskedReveal>

            <MaskedReveal auto delay={0.7}>
              <Flex>
                {title}
              </Flex>
            </MaskedReveal>

            <MaskedReveal auto delay={0.8}>
              <Flex
                className={classes.industry}
              >
                #{industry}
              </Flex>
            </MaskedReveal>
          </Flex>
        </Flex>
      )}
    </Modal >
  );
}

ListItemMobile.propTypes = {
  className: PropTypes.string,
  idx: PropTypes.number,
  listItemData: PropTypes.object
};

ListItemMobile.defaultProps = {
  className: null,
  idx: 0,
  listItemData: {}
};

export default ListItemMobile;
