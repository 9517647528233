import React, { useRef, useState } from "react";
import { isMobile, isTablet } from 'react-device-detect';
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";
import { Durations, Easings } from "../../animation";
import { ListItem } from "../../components/Testimonial";
import ButtonCursor from "./ButtonCursor";

import { gsap, useGSAP } from "../../gsap";

const List = styled.ul`
  list-style: none;
  padding: ${(props) => props.padding};
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  pointer-events: ${(props) => (props.nodetail ? "none" : "auto")};

  .testimonials {
    padding: 11rem;
    margin: -11rem;

    .testimonials__name {
      font-family: "Suisse Intl", sans-serif;
      font-weight: 100;
      font-style: italic;
      font-size: ${pxToRem(57)};
      letter-spacing: 0em;
      line-height: 1.2;
      padding: 0;
      text-transform: none;
      text-align: center;

      &::after {
        display: none;
      }

      @media screen and (max-width: 620px) {
        font-size: ${pxToRem(28)};
        letter-spacing: 0em;
        line-height: 1.1;
      }
    }
  }
`;

const Testimonial = (props) => {
  const [, setFirstEnter] = useState(false);

  const el = useRef();
  const elCursor = useRef();

  const { contextSafe } = useGSAP({ scope: el });

  const handleMouseMove = contextSafe((event) => {
    if (!elCursor.current) return;
    if (isMobile) return;

    const elRect = el.current.getBoundingClientRect();
    const cursorRect = elCursor.current.getBoundingClientRect();

    const x = event.clientX - elRect.left - cursorRect.width / 2;
    const y = event.clientY - elRect.top - cursorRect.height / 2;

    // if (!firstEnter) {
    //   gsap.set(elCursor.current, {
    //     left: x,
    //     top: y,
    //   });

    //   setFirstEnter(true);
    // }

    gsap.to(elCursor.current, {
      scale: 1,
      left: x,
      top: y,
      duration: 0.6,
      delay: 0.01,
      ease: "power4.out",
    });
  });

  const handleMouseClick = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;

    const tl = gsap.timeline({
      defaults: {
        duration: 0.3,
        ease: "power2.out",
      },
    });

    tl.to(elCursor.current, {
      scale: 0.9,
    });

    tl.to(elCursor.current, {
      scale: 1,
    });
  });

  const handleMouseEnter = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;

    gsap.to(elCursor.current, {
      scale: 1,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
    });
  });

  const handleMouseLeave = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;

    gsap.to(elCursor.current, {
      scale: 0,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
      onComplete: () => {
        setFirstEnter(false);
      },
    });
  });

  return (
    <List
      ref={el}
      padding={props.padding}
      nodetail={props.nodetail}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
    >
      {!props.nodetail && (
        <ButtonCursor
          ref={elCursor}
          text="Read Review"
          fontSize={isMobile ? pxToRem(7.6) : pxToRem(10)}
          size={pxToRem(134)}
          sizeMobile={pxToRem(93)}
          top={isTablet ? "80%" : null}
          left={isTablet ? "95%" : "50%"}
          topMobile="70%"
          leftMobile="85%"
          bgColor={props.cursorBgColor}
          textColor={props.cursorTextColor}
        />
      )}
      {props.items.map((item, key, c) => (
        <ListItem
          key={key}
          appearMotionPropVariants={{
            enter: () => ({
              y: "0%",
              transition: {
                delay: key * Durations.gap,
                duration: Durations.base,
                ease: Easings.easeOutCubic,
              },
            }),
            exit: () => ({
              y: "100%",
              transition: {
                delay: (c.length - key) * Durations.gap,
                duration: Durations.base,
                ease: Easings.easeInCubic,
              },
            }),
          }}
          idx={key + 1}
          listItemData={item}
        />
      ))}
    </List>
  );
};

export default Testimonial;
