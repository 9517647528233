import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

/**
 * @typedef {Object} CurrentTime
 * @property {number} h - Current hour
 * @property {number} m - Current minute
 * @property {number} s - Current second
 * @property {number} w - Current weekday
 * @property {string} formatted - Current time formatted as string
 */

/**
 * @return {CurrentTime}
 */
const getCurrentTime = () => {
  const dateTime = DateTime.local().setZone('Europe/Belgrade');

  return {
    h: dateTime.hour,
    m: dateTime.minute,
    s: dateTime.second,
    w: dateTime.weekday,
    formatted: dateTime.toLocaleString(DateTime.TIME_SIMPLE).replace(/\s/g, ''),
  };
}

export function useCurrentTime() {
  const [time, setTime] = useState(getCurrentTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getCurrentTime);
    }, 1000 * 60);

    return () => {
      clearInterval(intervalId)
    }
  }, []);

  return time;
}
