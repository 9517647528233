import classnames from 'classnames';
import React, { useCallback } from 'react';
import './AudioPlayer.scss';
import { useAudio } from './audioContext';

function AudioPlayer() {
  const {
    loaded,
    playing,
    toggle,
  } = useAudio();

  const handlePlayback = useCallback(() => {
    toggle();
  }, [toggle]);

  return (
    <div className={classnames([
      'AudioPlayer',
      {
        'AudioPlayer--playing': playing,
        'AudioPlayer--loaded': loaded
      }
    ])}>

      <div
        className="AudioPlayer__controls"
        onClick={handlePlayback}
      >
        <div className="AudioPlayerToggle">
          <div className="AudioPlayerToggle__label AudioPlayerLabel">
            <div className="AudioPlayerLabel__text">sound</div>
            <div className="AudioPlayerLabel__state">
              <span className="AudioPlayerState">{playing ? 'on' : 'off'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AudioPlayer;
