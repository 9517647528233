import React from 'react';

/**
 * @typedef {object} AudioContextValue
 * @property {boolean} loaded
 * @property {boolean} playing,
 * @property {() => void} play
 * @property {() => void} pause
 */

/** @type {AudioContextValue} */
const defaultAudioCodeContextValue = {
  loaded: false,
  playing: false,
  play: () => {},
  pause: () => {}
};

/** @type {import('react').Context<AudioContextValue>} */
const AudioContext = React.createContext(defaultAudioCodeContextValue);

export const AudioContextProvider = AudioContext.Provider;

export const useAudio = () => {
  const context = React.useContext(AudioContext);

  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }

  return context;
}
