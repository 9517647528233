import React from 'react';
import styled from 'styled-components';
import pxToRem from '../../Utils/pxToRem';

const Text = styled.p`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 450;
  font-size: ${pxToRem(18)};
  letter-spacing: 0.028em;
  line-height: 1.44;
  text-transform: ${(props) => props.transform};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin || '0'};

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(16)};
    letter-spacing: 0.04em;
    line-height: 1.55;
  }
`;

const Paragraph5 = (props) => {
  return (
    <Text className={props.className} color={props.color} align={props.align} transform={props.transform} margin={props.margin}>{props.children}</Text>
  );
};

export default Paragraph5;
