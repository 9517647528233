import { useUpdateEffect } from 'ahooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useState } from 'react';
import { useMedia } from 'react-recipes';
import { Flex } from '../Flex';
import classes from './AwardList.module.scss';
import { BodyVariants, LabelVariants } from './AwardList.motion';
import { AwardListTable } from './AwardListTable';

/**
 * @typedef {CustomEvent} OpenChangeEvent
 */

/**
 * @typedef {object} OpenChangeEventData
 * @property {boolean} open
 */

/**
 * @callback OpenChangeCallback
 * @param {OpenChangeEvent} event
 * @param {OpenChangeEventData} data
 */

/**
 * @typedef {object} Award
 * @property {string} award
 * @property {string} category
 */

/**
 * @typedef {object} AwardListItem
 * @property {string} title
 * @property {string} type
 * @property {string} href
 * @property {Award[]} awards
 */

/**
 * @typedef {Omit<AwardListItem, 'awards'> & Award} AwardListNormalizedItem
 */

/**
 * @typedef {object} AwardListProps
 * @property {never} children
 * @property {boolean} [defaultOpen=false]
 * @property {AwardListItem[]} items
 * @property {OpenChangeCallback} onOpenChange
 */

/** @type {import('react').FC<AwardListProps>} */
export const AwardList = (props) => {
  const {
    defaultOpen = false,
    items = [],
    onOpenChange
  } = props;

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const isMobile = useMedia(['(max-width: 640px)'], [true], false);

  const awardsCount = useMemo(() => (
    items.reduce((acc, item) => acc + item.awards.length, 0)
  ), [items]);

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  useUpdateEffect(() => {
    if (!(onOpenChange instanceof Function) || typeof onOpenChange !== 'function') {
      return;
    }

    const event = new CustomEvent('openChange');

    onOpenChange?.(event, { open: isOpen });
  }, [isOpen, onOpenChange]);


  return (
    <div
      className={classes.root}
    >
      <Flex
        className={classes.header}
        el="header"
        justifyContent="space-between"
        onClick={handleToggle}
      >
        <span>
          Awards &amp; Recognition ({awardsCount})
        </span>

        <div
          className={classes.toggle}
        >
          <AnimatePresence
            initial={false}
            mode="popLayout"
          >
            {isOpen && (
              <motion.span
                key="close"
                className={classes.toggleLabel}
                custom={{ y: '-100%' }}
                animate="show"
                exit="hide"
                initial="hide"
                variants={LabelVariants}
              >
                Close
              </motion.span>
            )}

            {!isOpen && (
              <motion.span
                key="open"
                className={classes.toggleLabel}
                custom={{ y: '100%' }}
                animate="show"
                exit="hide"
                initial="hide"
                variants={LabelVariants}
              >
                Open
              </motion.span>
            )}
          </AnimatePresence>

          {!isMobile && (
            <span>&nbsp;List</span>
          )}
        </div>
      </Flex>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="body"
            className={classes.body}
            animate="open"
            exit="collapsed"
            initial="collapsed"
            variants={BodyVariants}
          >
            <div className={classes.bodyInner}>
              <AwardListTable items={items} />
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
}
