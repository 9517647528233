import React, { useContext, useMemo } from 'react';
import { useMedia } from 'react-recipes';
import { Easings } from '../../../animation';

const DELAY = 0.075;

/** @type {(index: number, isMobile: boolean) => number} */
export function getDelay(index, isMobile = false) {
  return index > 0 ? DELAY : 0;
  // return index > 0 && !isMobile ? DELAY : 0;
}

/**
 * @typedef {object} CardSharedTrasitionsContextValue
 * @property {import('framer-motion').Variants} rootVariants
 * @property {import('framer-motion').Variants} contentVariants
 */

/** @type {import('react').Context<CardSharedTrasitionsContextValue>} */
const CardSharedTrasitionsContext = React.createContext();

/** @type {import('react').FC<>} */
export const CardSharedTrasitionsContextProvider = ({ children }) => {
  const isMobile = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  /** @type {CardSharedTrasitionsContextValue} */
  const contextValue = useMemo(() => ({
    rootVariants: {
      enter: ([{ index, duration = 0.4, durationHeight = 1.600, isMobile }] = []) => ({
        height: '100%',
        width: '100%',
        transition: {
          duration,
          ease: Easings.easeOutCubic,
          delay: isMobile && index === 0 ? 0.4 : getDelay(index, isMobile),
          height: {
            delay: (isMobile ? 0 : duration) + getDelay(index, isMobile),
            duration: durationHeight,
            ease: Easings.easeOutCubic
          }
        }
      }),
      exit: ([
        { index, isPresent },
        {
          duration = 1.4,
          // durationHeight = 0.8,
          // height = '4px',
          // width = '0%'
        } = {}
      ] = []) => ({
        height:  isMobile ? 0 : isPresent ? '4px' : '0%',
        width: isMobile ? '100%' : isPresent ? '0%' : '100%',
        transition: {
          duration,
          ease: Easings.easeOutCubic,
          delay: index * getDelay(index, isMobile)
        }
      })
    },
    contentVariants: {
      enter: ([{ delay = 0, duration = 0.8, index, ease = Easings.easeOutCubic, y = '0%', ...rest } = {}] = []) => ({
        ...rest,
        y,
        delay: getDelay(index, isMobile),
        transition: {
          ease,
          duration,
          delay: delay + getDelay(index, isMobile)
        }
      }),
      exit: ([, { index, delay = 0, duration = 0.8, ease = Easings.easeInCubic, y = '110%', ...rest } = {}] = []) => ({
        ...rest,
        y,
        transition: {
          ease,
          duration,
          delay: index * getDelay(index, isMobile)
        }
      })
    }
  }), [isMobile]);

  return (
    <CardSharedTrasitionsContext.Provider value={contextValue}>
      {children}
    </CardSharedTrasitionsContext.Provider>
  );
};

CardSharedTrasitionsContextProvider.displayName = 'CardSharedTrasitionContextProvider';

export const useCardSharedTransitions = () => useContext(CardSharedTrasitionsContext);
