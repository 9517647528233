import React, { useRef } from "react";
import { useMedia } from "react-recipes";
import { isTablet } from 'react-device-detect';
import { gsap, ScrollTrigger, useGSAP } from "../../../gsap";

const Sticky = (props) => {
  // Responsive
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();

  useGSAP(
    () => {
      if (isMobile) return;
      const stickyContentText = el.current.querySelector(
        ".sticky-content-text"
      );

      const textHeight = stickyContentText.getBoundingClientRect().height;

      const calcStart = `${parseInt(
        200 - (props.offsetStart ? props.offsetStart : 0)
      )}px`;

      const checkNumber = (value) => {
        if (value > 0) {
          return +parseInt(calcStart);
        } else {
          return -parseInt(calcStart);
        }
      };

      const calculateContentCalc = () => {
        const contentCalc =
          el.current.getBoundingClientRect().height -
          textHeight +
          checkNumber(calcStart);
        return contentCalc;
      };

      ScrollTrigger.create({
        trigger: ".sticky-content-text",
        start: () => `top top+=${calcStart}`,
        end: () =>
          `+=${
            calculateContentCalc() +
            (props.offsetEnd ? parseInt(props.offsetEnd) : null)
          }px`,
        scrub: true,
        pin: ".sticky-content",
        pinSpacing: false,
        invalidateOnRefresh: true,
      });

      if (isTablet) {
        gsap.set(".sticky-content", {
          transition: "transform 0.25s ease-out",
        });
      }
    },
    {
      dependencies: [isMobile, props.offsetStart, props.offsetEnd],
      scope: el,
      revertOnUpdate: true,
    }
  );

  return <div ref={el}>{props.children}</div>;
};

export default Sticky;
