import React from 'react';

export function useMergedRefs(...refs) {
  const mergedCallback = React.useCallback(
    (value) => {
      // Update the "current" prop hanging on the function.
      mergedCallback.current = value;

      for (const ref of refs) {
        if (typeof ref === 'function') {
          ref(value);
        } else if (ref) {
          // work around the immutability of the React.Ref type
          ref.current = value;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- already exhaustive
    [...refs],
  );

  return mergedCallback;
}
