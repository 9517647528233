import React, { useRef } from "react";
import styled from "styled-components";
import { gsap, useGSAP } from "../../gsap";

const Line = styled.hr`
  border: none;
  width: ${(props) => (props.isVertical ? "1px" : "100%")};
  height: ${(props) => (props.isVertical ? props.height || "100%" : "1px")};
  background-color: ${(props) => props.color || "currentColor"};
  opacity: ${(props) => props.opacity};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;

const Border = (props) => {
  const el = useRef();

  useGSAP(
    () => {
      gsap.from(el.current, {
        [props.isVertical ? "scaleY" : "scaleX"]: 0,
        transformOrigin: props.isVertical ? "top" : "left",
        scrollTrigger: {
          trigger: el.current,
        },
      });
    },
    {
      dependencies: [props.isVertical],
      scope: el,
    }
  );

  return (
    <Line
      ref={el}
      className={props.className}
      isVertical={props.isVertical}
      height={props.height}
      color={props.color}
      opacity={props.opacity}
      margin={props.margin}
      padding={props.padding}
    />
  );
};

export default Border;
