export const Easings = {
  easeInCubic: [0.32, 0, 0.67, 0],
  // easeOutCubic: [0.215, 0.61, 0.355, 1],
  easeOutCubic: [0.23, 1, 0.68, 1],
  easeOutQuint: [0.22, 1, 0.36, 1],
  brunoIn: [0.665, 0.005, 0.165, 1],
  brunoOut: [0.835, 0.000, 0.335, 0.995],
  concreteClub: [0.16, 1, 0.3, 1],
  circIn: [0.55, 0, 1, 0.45],
  circOut: [0, 0.55, 0.45, 1],
  circInOut: [0, 0.55, 0.45, 1],
  expoOut: [0.16, 1, 0.3, 1],
  quartOut: [0.25, 1, 0.5, 1]
};

export const Durations = {
  gap: 0.150,
  base: 1.400
};
