import { useAsyncEffect, useRafTimeout } from 'ahooks';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { Easings } from '../../animation';
import { ResourceLoader } from '../../api/resourceLoader';
import classes from './Image.module.scss';

/**
 * @typedef {import('react').ImgHTMLAttributes<HTMLImageElement> & { preSrc?: string }} ImageProps
 */

/** @type {import('react').FC<ImageProps>} */
export const Image = React.memo(React.forwardRef((props, ref) => {
  const {
    alt,
    className,
    delay = 0,
    preSrc,
    src,
    style,
    ...rest
  } = props;

  /** @type {import('react').MutableRefObject<HTMLImageElement>} */
  const elRef = useRef();
  const [loaded, setLoaded] = useState(!preSrc);
  const [internalSrc, setInternalSrc] = useState(() => loaded ? props.src : null);
  const [timeoutEnded, setTimeoutEnded] = useState(() => delay <= 0 || loaded);

  useAsyncEffect(async function* () {
    if (loaded) {
      return;
    }

    setLoaded(false);
    const { data } = await ResourceLoader.get(props.src);
    yield;
    setLoaded(true);
    setInternalSrc(data);
  }, [props.src]);

  useRafTimeout(() => {
    if (timeoutEnded) {
      return;
    }

    setTimeoutEnded(true);
  }, delay);

  if (!preSrc) {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        {...rest}
        alt={alt}
        className={className}
        ref={ref}
        src={src}
        style={style}
      />
    );
  };

  return (
    <figure ref={ref} className={classNames(classes.root, className)} style={style}>
      {timeoutEnded && (
        <img alt={props.alt} className={classes.preSrc} src={props.preSrc} />
      )}
      <motion.img
        {...rest}
        alt={alt}
        animate={{ opacity: loaded ? 1 : 0 }}
        className={classes.base}
        initial={{ opacity: 0 }}
        ref={elRef}
        src={internalSrc}
        transition={{ ease: Easings.easeInCubic }}
      />
    </figure>
  );
}));

Image.displayName = 'Image';
