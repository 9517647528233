import { ModalProvider } from '@liholiho/react-modal-hook';
import { useDebounceFn, useEventListener, useRequest, useThrottleFn } from 'ahooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import 'simplebar-react/dist/simplebar.min.css';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import 'swiper/css/parallax';
import './App.scss';
import { Routes } from './Routes';
import { SiteConfigurationAPI } from './api/siteConfiguration';
import video from './assets/media/45fps-slower.mp4';
import soundSrc from "./assets/media/loop.mp3";
import mobileCover from './assets/media/mobile-bg.jpg';
import mobileVideo from './assets/media/mobile-bg.mp4';
import { AppProvider } from './components/AppContext';
import { AudioProvider } from './components/AudioPlayer/AudioProvider';
import { CookiePrompt } from './components/CookiePrompt/CookiePrompt';
import { GlobalModalStateProvider } from './components/Modal/GlobalModalProvider';
import { usePageTransition } from './components/PageTransitionProvider';
import SiteLayout from './components/SiteLayout';
import VideoBackgroundProvider from './components/VideoBackgroundProvider/provider';
import { SiteConfigurationProvider } from './contexts/siteConfigurationContext';
import { ScrollTrigger } from "./gsap";
import { getRouteObject } from './routing';

// const mobilePoster = require('./assets/placeholder/media/mobile-bg.jpg?placeholder&format=blurred-svg&blurQuality=4').default;

if (typeof window !== 'undefined' && window.history) {
  // Reset the state of the history object on page load.
  delete window.history.state?.usr?.from;
}

function App() {
  const { mode } = usePageTransition();
  const { data: siteConfigurationContextValue } = useRequest(SiteConfigurationAPI.get);

  const location = useLocation();
  const videoRef = useRef();
  const [shouldRenderCookiePrompt, setShouldRenderCookiePrompt] = useState(() => (
    'cookie' in window && !window.cookie('consent')
  ));

  const routeObject = useMemo(() => (
    getRouteObject(location.pathname)
  ), [location.pathname]);

  const routeOptions = useMemo(() => (
    routeObject?.options ?? {}
  ), [routeObject]);

  const helmetProps = useMemo(() => {
    const props = { meta: [], script: [] };

    if (routeObject.description) {
      props.meta.push({ name: 'description', content: routeObject.description });
      props.meta.push({ name: 'og:description', content: routeObject.description });
      props.meta.push({ name: 'twitter:description', content: routeObject.description });
    }

    if (routeObject.jsonld) {
      props.script.push(helmetJsonLdProp(routeObject.jsonld));

      if (routeObject.jsonld.image) {
        props.meta.push({ name: 'og:image', content: routeObject.jsonld.image.url });
        props.meta.push({ name: 'twitter:image:src', content: routeObject.jsonld.image.url });
      }
    }

    return props;
  }, [routeObject]);

  const Video = useMemo(() => {
    if (!routeOptions?.withVideoBackground) return;
    const videoProps = {
      autoPlay: true,
      // poster: isMobileVideo ? mobileCover : cover,
      key: isMobileOnly ? 'small' : 'large',
      playsInline: true,
      loop: true,
      muted: true,
      src: isMobileOnly ? mobileVideo : video,
      style: {
        zIndex: -1
      }
    };

    if (isMobileOnly) {
      return (
        <div
          className="backgroundHolder"
          dangerouslySetInnerHTML={{
            __html: `

            <video
              id="backgroundVideo"
              src="${mobileVideo}"
              poster="${mobileCover}"
              loop
              muted
              playsInline
              autoPlay
            >
            </video>
            <div className="overlay"></div>
            `,
          }}
        />
      );
    }

    return (
      <div className="backgroundHolder">
        <motion.video
          {...videoProps}
          ref={videoRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        // onPlaying={playingHandler}
        />
        <div className="overlay"></div>
      </div>
    );
  }, [routeOptions?.withVideoBackground]);

  const { run: setViewportVariable } = useThrottleFn(
    useCallback(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, []),
    {
      wait: 150,
    }
  );

  useEventListener("resize", setViewportVariable);

  useEffect(() => {
    setViewportVariable();
  }, [setViewportVariable]);

  // Refresh browser when switching between mobile and desktop screen
  // This is to make user still got the best experience layout and motion
  const defaultMediaQuery = 620;

  const resizeHandlerDesktop = () => {
    const mediaQuery = window.matchMedia(
      `(max-width: ${defaultMediaQuery - 1}px)`
    );
    if (mediaQuery.matches) {
      window.location.reload();
    }
  };

  const resizeHandlerMobile = () => {
    const mediaQuery = window.matchMedia(`(min-width: ${defaultMediaQuery}px)`);
    if (mediaQuery.matches) {
      window.location.reload();
    }
  };

  const debouncedResizeHandlerDesktop = useDebounceFn(resizeHandlerDesktop, {
    wait: 500,
  });
  const debouncedResizeHandlerMobile = useDebounceFn(resizeHandlerMobile, {
    wait: 500,
  });

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;
    if (ScrollTrigger.isTouch) return;

    const Windowresize = () => {
      ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 620px)": () => {
          window.addEventListener("resize", debouncedResizeHandlerDesktop.run);
        },

        // mobile
        "(max-width: 619px)": () => {
          window.addEventListener("resize", debouncedResizeHandlerMobile.run);
        },
      });
    };

    Windowresize();

    return () => {
      window.removeEventListener("resize", debouncedResizeHandlerDesktop.run);
      window.removeEventListener("resize", debouncedResizeHandlerMobile.run);
    };
  }, []);

  useEffect(() => {
    console.log(
      `Route from: ${location.state?.from} to: ${location.pathname}\t mode: ${mode}`
    );
  }, [location.pathname, location.state?.from, mode]);

  useEffect(() => {
    if ('cookie' in window && 'gtag') {
      window.gtag('event', 'page_view', {
        title: document.title,
        page_location: window.location.href
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, []);

  useEffect(() => {
    /** @type {HTMLElement?} */
    let bodyEl = null;

    const playVideo = async () => {
      /** @type {HTMLVideoElement?} */
      const videoEl = document.getElementById('backgroundVideo');

      if (videoEl) {
        try {
          await videoEl.play();
        }
        catch (err) {
          console.log(err);
        }
      }
    };

    if (isMobileOnly) {
      bodyEl = document.querySelector('body');
      bodyEl.addEventListener('click', playVideo, { once: true });
    }

    return () => bodyEl?.removeEventListener('click', playVideo);
  }, []);

  return (
    <SiteConfigurationProvider value={siteConfigurationContextValue}>
      <AudioProvider src={soundSrc}>
        <ModalProvider rootComponent={AnimatePresence}>
          <GlobalModalStateProvider>
            <AppProvider>
              <VideoBackgroundProvider ref={videoRef}>
                <SiteLayout>
                  <Helmet {...helmetProps} />
                  {Video}
                  <Routes mode={mode} location={location} />
                  <AnimatePresence>
                    {shouldRenderCookiePrompt && (
                      <CookiePrompt onResolved={() => setShouldRenderCookiePrompt(false)} />
                    )}
                  </AnimatePresence>
                </SiteLayout>
              </VideoBackgroundProvider>
            </AppProvider>
          </GlobalModalStateProvider>
        </ModalProvider>
      </AudioProvider>
    </SiteConfigurationProvider>
  );
}

export default App;
