import axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';

/**
 * @typedef {object} BusinessHours
 * @property {number[]} days
 * @property {[number, number]} hours
 */

/**
 * @typedef {object} SiteConfiguration
 * @property {string|null} businessInquiries
 * @property {BusinessHours} businessHours
 */

const SiteConfigurationRequest = axios.create();

setupCache(SiteConfigurationRequest, {
  storage: buildWebStorage(localStorage, 'site-configuration:'),
});

export const SiteConfigurationAPI = {
  /**
   * @async
   * @returns {Promise<SiteConfiguration>}
   */
  get: async () => (
    await SiteConfigurationRequest.get('/site-configuration.json', {
      cache: {
        interpretHeader: false,
        ttl: 1000 * 60 * 60 * 24 * 1 // 1 day
      }
    })
  ).data
};
