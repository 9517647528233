import React from 'react';
import useClipboard from 'react-use-clipboard';
import { getDisplayName } from '../../../utils';
import { Flex } from '../../Flex';
import { Icon } from '../../icons';
import classes from './WithEmailInquiry.module.scss';

/**
 * @typedef {object} WithEmailInquiryProps
 * @property {string} email
 */

/**
 * @template {import('../Button').ButtonProps & import('./withPopover').WithPopoverPartialProps} P
 * @param {import('react').ComponentType<P>} Component
 * @returns
 */
export function withEmailInquiry(Component) {

  /**
   * @template {import('../Button').ButtonProps} T
   * @type {ReturnType<typeof import('react').forwardRef<HTMLElement, P  & WithEmailInquiryProps>}
   **/
  const WithEmailInquiry = React.forwardRef((props, ref) => {
    const {
      children = 'Copy Email',
      cloneChildren = 'Email Copied',
      email,
      ...rest
     } = props;

    const [, copyToClipboard] = useClipboard(email);

    return (
      <Component
        ref={ref}
        {...rest}
        cloneChildren={cloneChildren}
        content={(
          <Flex
          gap={12}
        >
          <div
            className={classes.icon}
          >
            <Icon
              type="infoCircle"
            />
          </div>
          <p className={classes.paragraph}>
            Due to limited availability, please include an estimate of your budget and timeline in your email.
          </p>
        </Flex>
        )}
        onClick={copyToClipboard}
      >
        {children}
      </Component>
    )
  });

  WithEmailInquiry.displayName = `WithEmailInquiry(${getDisplayName(Component)})`;

  return WithEmailInquiry;
}
