import { motion, usePresence } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import classes from "./Page.module.scss";
import { usePageTransition } from "./PageTransitionProvider";
import { gsap, ScrollTrigger, useGSAP } from "../gsap";
import Lenis from "@studio-freight/lenis";
import { isFirefox } from "react-device-detect";

import { lenisAtom } from "../atom/transitionAtom";
import { useAtom } from "jotai";
import classNames from "classnames";
import { isFunction } from 'lodash';

function Page({ children, className, dimOnExit, style, title }) {
  const page = useRef();
  const location = useLocation();
  const { getOptions } = usePageTransition();
  const { motionProps, style: motionStyle } = getOptions(location.pathname);
  const [isPresent, safeToRemove] = usePresence();

  const elLenis = useRef();
  const elContent = useRef();

  const [, setLenis] = useAtom(lenisAtom);
  const [isLenis, setIsLenis] = useState(false);

  let clickedScrollbar = false;
  const scrollbar = useRef(null);
  const scrollbarInner = useRef(null);
  const scrollbarThumb = useRef(null);

  useEffect(() => {
    if (!isPresent) {
      if (page.current instanceof HTMLElement) {
        page.current.classList.add(classes.hideScrollbars);
      }

      document.documentElement.classList.add("wait-cursor");
      safeToRemove();
    }
  }, [isPresent, safeToRemove]);

  useGSAP(
    () => {
      document.documentElement.classList.remove("wait-cursor");

      if (!dimOnExit) {
        setIsLenis(true);
      } else {
        if (!elLenis) return;
        const initLenis = new Lenis({
          duration: isFirefox ? 1.1 : 0.9,
          easing: (t) => Math.min(1, 1.001 - Math.pow(2, -11 * t)), // https://www.desmos.com/calculator/brs54l4xou
          orientation: "vertical",
          wheelMultiplier: isFirefox ? 1.3 : 1,
          gestureOrientation: "vertical",
          wrapper: elLenis.current,
          content: elContent.current,
          syncTouch: location.pathname.includes("/studio") ? true : false,
          syncTouchLerp: 0.05,
        });

        setLenis(initLenis);

        const onScroll = ({ progress, isScrolling }) => {
          ScrollTrigger.update();

          if (!scrollbar.current) return;
          gsap.set(scrollbarThumb.current, {
            y: progress * (window.innerHeight - thumbHeight - 14 * 2),
            force3D: true,
          });

          if (!scrollbar.current.classList.contains("active")) {
            scrollbar.current.classList.add("active");
          }

          if (!isScrolling) {
            scrollbar.current.classList.remove("active");
          }
        };

        const update = (time) => {
          initLenis.raf(time * 1000);
        };

        gsap.ticker.add(update);
        initLenis.on("scroll", ({ progress, isScrolling }) => {
          onScroll({ progress, isScrolling });
        });

        gsap.ticker.lagSmoothing(0);

        const thumbHeight =
          scrollbarThumb.current.getBoundingClientRect().height;
        const innerHeight =
          scrollbarInner.current.getBoundingClientRect().height;

        // Reset thumb to top
        scrollbarThumb.current.style.transform = `translate3d(0,0px,0)`;

        const clamp = (num, min, max) =>
          num <= min ? min : num >= max ? max : num;

        const mapRange = (inMin, inMax, input, outMin, outMax) => {
          return (
            ((input - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
          );
        };

        const onPointerMove = (e) => {
          if (!clickedScrollbar) return;
          e.preventDefault();

          const offset = (window.innerHeight - innerHeight) / 2;
          const y = mapRange(
            0,
            window.innerHeight,
            e.clientY,
            -offset,
            innerHeight + offset
          );

          const progress = clamp(0, y / innerHeight, 1);
          const newPos = initLenis.limit * progress;

          initLenis.scrollTo(newPos, {
            duration: 1.8,
            force: true,
          });
        };

        const currentScrollbarThumb = scrollbarThumb.current;

        const onPointerDown = () => {
          clickedScrollbar = true;
        };
        const onPointerUp = () => {
          clickedScrollbar = false;
        };

        window.addEventListener("pointermove", onPointerMove, false);
        window.addEventListener("pointerup", onPointerUp, false);
        currentScrollbarThumb.addEventListener(
          "pointerdown",
          onPointerDown,
          false
        );

        if (
          elContent.current.getBoundingClientRect().height ===
          window.innerHeight
        ) {
          scrollbar.current.classList.add("is--hidden");
        } else {
          scrollbar.current.classList.remove("is--hidden");
        }

        ScrollTrigger.defaults({
          scroller: elLenis.current,
        });

        ScrollTrigger.scrollerProxy(initLenis, {
          scrollTop(value) {
            return arguments.length
              ? initLenis.scrollTo(value, { duration: 0 })
              : initLenis.scroll;
          },
          getBoundingClientRect() {
            return {
              top: 0,
              left: 0,
              width: window.innerWidth,
              height: window.innerHeight,
            };
          },
        });

        setIsLenis(true);

        const stopOverscroll = (element) => {
          element = gsap.utils.toArray(element)[0] || window;
          (element === document.body || element === document.documentElement) &&
            (element = window);
          let lastScroll = 0,
            lastTouch,
            forcing,
            forward = true,
            isRoot = element === window,
            scroller = isRoot ? document.scrollingElement : element,
            ua = window.navigator.userAgent + "",
            getMax = isRoot
              ? () => scroller.scrollHeight - window.innerHeight
              : () => scroller.scrollHeight - scroller.clientHeight,
            addListener = (type, func) =>
              element.addEventListener(type, func, { passive: false }),
            revert = () => {
              scroller.style.overflowY = "auto";
              forcing = false;
            },
            kill = () => {
              forcing = true;
              scroller.style.overflowY = "hidden";
              !forward && scroller.scrollTop < 1
                ? (scroller.scrollTop = 1)
                : (scroller.scrollTop = getMax() - 1);
              setTimeout(revert, 1);
            },
            handleTouch = (e) => {
              let evt = e.changedTouches ? e.changedTouches[0] : e,
                forward = evt.pageY <= lastTouch;
              if (
                ((!forward && scroller.scrollTop <= 1) ||
                  (forward && scroller.scrollTop >= getMax() - 1)) &&
                e.type === "touchmove"
              ) {
                e.preventDefault();
              } else {
                lastTouch = evt.pageY;
              }
            },
            handleScroll = (e) => {
              if (!forcing) {
                let scrollTop = scroller.scrollTop;
                forward = scrollTop > lastScroll;
                if (
                  (!forward && scrollTop < 1) ||
                  (forward && scrollTop >= getMax() - 1)
                ) {
                  e.preventDefault();
                  kill();
                }
                lastScroll = scrollTop;
              }
            };
          if (
            "ontouchend" in document &&
            !!ua.match(/Version\/[\d\.]+.*Safari/)
          ) {
            addListener("scroll", handleScroll);
            addListener("touchstart", handleTouch);
            addListener("touchmove", handleTouch);
          }
          scroller.style.overscrollBehavior = "none";
        };

        stopOverscroll(elLenis.current);

        return () => {
          document.documentElement.classList.remove("wait-cursor");
          initLenis.on("scroll", onScroll);
          window.removeEventListener("pointermove", onPointerMove);
          window.removeEventListener("pointerup", onPointerUp);
          currentScrollbarThumb.removeEventListener(
            "pointerdown",
            onPointerDown
          );
          gsap.ticker.remove(update);
        };
      }
    },
    {
      dependencies: [dimOnExit, setLenis],
      scope: page,
    }
  );

  return (
    <motion.div
      {...motionProps}
      ref={page}
      className={classNames(classes.root, className)}
      style={motionStyle}
    >
      <div
        className="scrollbar"
        ref={scrollbar}
        onMouseEnter={() => {
          scrollbar.current.classList.add("is--hover");
        }}
        onMouseLeave={() => {
          scrollbar.current.classList.remove("is--hover");
        }}
      >
        <div className="inner" ref={scrollbarInner}>
          <div className="thumb" id="thumb" ref={scrollbarThumb}></div>
        </div>
      </div>

      <main
        className="Page__content"
        style={{
          ...style,
        }}
      >
        <div className="linsa" ref={elLenis}>
          <div id="content" ref={elContent}>
            {isLenis && (isFunction(children) ? children({ lenisEl: elLenis }) : children)}
          </div>
        </div>
      </main>

      {!isPresent && !location?.state?.next && (
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.6,
          }}
        />
      )}
    </motion.div>
  );
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
  dimOnExit: PropTypes.bool,
};

Page.defaultProps = {
  style: {},
  title: "FIFTYSEVEN — Branding and Design Agency",
  dimOnExit: false,
};

export default Page;
