import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutationObserver } from '../../hooks';
import { Flex } from '../Flex';
import { MaskedReveal } from '../Motion';
import Panel from '../Panel';
import { Icon } from '../icons';
import classes from './CookiePropmpt.module.scss';
import { useSize } from 'ahooks';

/** @type {import('react').FC<{ onResolved: (accepted: boolean) => void }>} */
export const CookiePrompt = React.forwardRef(({ onResolved }, ref) => {
  /** @type {import('react').MutableRefObject<HTMLButtonElement>} */
  const initialFocus = useRef(null);

  const [isFooterVisible, setFooterVisibile] = useState(true);
  const [isFooterActive, setFooterActive] = useState(false);

  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const footerRef = useRef(null);
  const { height = 0 } = useSize(footerRef.current) ?? {};

  const iconColor = useMemo(() => (
    isFooterActive
      ? document.documentElement.style.getPropertyValue('--color-footertext')
      : undefined
  ), [isFooterActive]);

  const handleRevealComplete = useCallback(() => {
    initialFocus.current.focus();
  }, []);

  /** @type {(accepted: boolean) => void} */
  const handleResolved = useCallback((...args) => {
    if (onResolved instanceof Function) {
      onResolved(...args);
    }
  }, [onResolved]);

  const handleAccept = useCallback(() => {
    if ('cookie' in window && 'gtag' in window) {
      window.cookie('consent', 'yes', { expires: 365, path: '/' });

      window.gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });

      handleResolved(true);
    }
  }, [handleResolved]);

  const handleDecline = useCallback(() => {
    if ('cookie' in window && 'gtag' in window) {
      window.cookie('consent', 'no', { expires: 365, path: '/' });
      handleResolved(false);
    }
  }, [handleResolved]);

  useEffect(() => {
    footerRef.current = document.querySelector('.MainFooter');

    return () => {
      footerRef.current = null;
    };
  }, []);

  useMutationObserver(
    footerRef,
    (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.attributeName === 'class') {
          setFooterVisibile(!mutation.target.classList.contains('hide'));
          setFooterActive(mutation.target.classList.contains('active'));
        }
      }
    },
    {
      attributeFilter: ['class'],
      subtree: false
    }
  );

  return (
    <MaskedReveal
      delay={2}
      className={classes.wrapper}
      style={{
        '--cookie-prompt-offset-y': `${isFooterVisible ? height : 0}px`
      }}
      onRevealComplete={handleRevealComplete}
    >
      <Panel
        ref={ref}
        className={classes.root}
        data-apply-theme={isFooterActive ? '' : null}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Flex alignItems="center">
            <Icon
              color={iconColor}
              className={classes.icon}
              type="info"
            />
            <span className={classes.text}>
              we use cookies
            </span>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="flex-end"
          >
            <button
              className={classes.button}
              onClick={handleDecline}
            >
              Decline
            </button>

            <div className={classes.separator} />

            <button
              ref={initialFocus}
              className={classes.button}
              onClick={handleAccept}
            >
              Accept
            </button>
          </Flex>
        </Flex>
      </Panel>
    </MaskedReveal>
  );
});

CookiePrompt.displayName = 'CookiePrompt';
