import { useMedia } from 'react-recipes';
import { Durations, Easings } from '../animation';
import Appear from '../components/Appear';
import Container from '../components/Container';
import { List } from '../components/List/index';
import { ListItem, ListItemMobile } from '../components/Testimonial';

import { useIsPresent } from 'framer-motion';
import { useGlobalModalState } from '../components/Modal/globalModalStateContext';
import { withRegisterRoute } from '../routing';
import { data } from '../testimonials';
import classes from './Testimonials.module.scss';
import Page from '../components/Page';

const filteredData = data.filter(({ inactive = false } = {}) => !inactive);

function Testimonials() {
  const isPresent = useIsPresent();
  const { open } = useGlobalModalState();
  const shouldRender = isPresent || (!isPresent && !open);

  const isMobile = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  return (
    <Page>
      <Container
        className={classes.container}
        frame
      >
        {shouldRender && isMobile && (
          <List
            className={classes.mobileList}
            direction="column"
            listItemProps={{
              className: classes.mobileListItem
            }}
          >
            {filteredData.map((item, key) => (
              <Appear
                key={key}
                forceRenderOnExit
                threshold={0.4}
              >
                <ListItemMobile
                  idx={key + 1}
                  listItemData={item}
                />
              </Appear>
            ))}
          </List>
        )}

        {shouldRender && !isMobile && (
          <List
            className={classes.list}
            direction="column"
            justifyContent="center"
          >
            {filteredData.map((item, key, c) => (
              <Appear
                key={key}
              >
                <ListItem
                  appearMotionPropVariants={{
                    enter: () => ({
                      y: '0%',
                      transition: {
                        delay: key * Durations.gap,
                        duration: Durations.base,
                        ease: Easings.easeOutCubic
                      }
                    }),
                    exit: () => ({
                      y: '100%',
                      transition: {
                        delay: (c.length - key) * Durations.gap,
                        duration: Durations.base,
                        ease: Easings.easeInCubic
                      }
                    })
                  }
                  }
                  appearAnimation
                  hideFooter
                  idx={key + 1}
                  listItemData={item}
                  withHover
                />
              </Appear>
            ))}
          </List>
        )}
      </Container>
    </Page>
  );
}

export default withRegisterRoute(Testimonials, {
  route: '/clients',
  name: 'Clients',
  description: "All our clients trust us to design their brands, increase their online presence and reach of their products, services, platforms and innovative technologies. Their trust is our best sales pitch. Read for yourself.",
  jsonld: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    'name': 'FIFTYSEVEN',
    'description': "All our clients trust us to design their brands, increase their online presence and reach of their products, services, platforms and innovative technologies. Their trust is our best sales pitch. Read for yourself.",
    'url': 'https://fiftyseven.co/clients',
    'image': {
      '@type': 'ImageObject',
      'url': 'https://www.fiftyseven.co/thumbnail/clients.png',
      'width': 1200,
      'height': 628
    }
  },
  weight: 10,
  options: {
    navBackOptions: { mobile: true },
    layoutGuard: {
      mobile: { landscape: true }
    }
  }
});
