import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { Durations, Easings } from '../../animation';
import { useNormalizeValue } from '../../hooks';
import { Flex } from '../Flex';
import './ContactInfo.scss';
import { useMedia } from 'react-recipes';
import { MaskedReveal } from '../Motion';
import classNames from 'classnames';

// const ANIMATABLE_COUNT = 15;
const DELAY = 1;

/**
 * @typedef {object} ContactInfoProps
 * @property {number | [number, number]} [delay=0]
 * @property {boolean} [inline=false]
 * @property {React.ReactNode} [slot]
 * @property {React.CSSProperties} [style]
 */

/** @type {import('react').FC<ContactInfoProps>} */
const ContactInfo = (props) => {
  const { delay = 0, inline = false, slot, style } = props;

  const isMobile = useMedia(['(max-width: 640px)'], [true], false);
  const [enterDelay, exitDelay] = useNormalizeValue(delay);
  const className = useMemo(() => classNames('infoHolder', { 'infoHolder--inline': inline }), [inline]);

  const variants = useMemo(() => ({
    enter: i => ({
      y: 0,
      transition: {
        delay: enterDelay + i * Durations.gap,
        duration: Durations.base,
        ease: Easings.easeOutCubic
      }
    }),
    exit: i => ({
      y: '110%',
      transition: {
        // delay: exitDelay + (ANIMATABLE_COUNT - i) * Durations.gap,
        delay: exitDelay + 0.2,
        duration: Durations.base,
        ease: Easings.easeInCubic
      }
    })
  }), [enterDelay, exitDelay]);

  return (
    <Flex className="ContactInfo" style={style}>
      <div className={className}>
        <div className="label" style={{ overflow: 'hidden' }}>
          <motion.div
            custom={0}
            variants={variants}
          >
            Location:
          </motion.div>
        </div>

        <div className="ContactInfo__contentContainer">
          <div className="content">
            <div className="row">
              <motion.div
                custom={1 * DELAY}
                variants={variants}
              >
                Subotica 24000
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={2 * DELAY}
                variants={variants}
              >
                Samoborska 29
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={3 * DELAY}
                variants={variants}
              >
                Serbia
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={4 * DELAY}
                variants={variants}
              >
                Est. 2016
              </motion.div>
            </div>
          </div>

          {isMobile && (
            <MaskedReveal elStyle={{ height: '100%' }} delay={[enterDelay]}>
              <div className="ContactInfoDivider" />
            </MaskedReveal>
          )}
        </div>
      </div>

      <div className={className}>
        <div className="label">
          <motion.div
            custom={5 * DELAY}
            variants={variants}
          >
            Contact:
          </motion.div>
        </div>

        <div className="ContactInfo__contentContainer">
          <div className="content">
            <div className="row">
              <motion.div
                custom={6 * DELAY}
                variants={variants}
                data-motion
              >
                We are GMT +2
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={7 * DELAY}

                variants={variants}
              >
                <a href="tel:+381607455394">
                  +381 (60) 7455 394
                </a>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={8 * DELAY}
                variants={variants}
              >
                <a
                  href="mailto:hello@fiftyseven.co"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hello@fiftyseven.co
                </a>
              </motion.div>
            </div>

            <div className="row">
              <motion.div
                custom={9 * DELAY}
                variants={variants}
              >
                MON - to - THU
              </motion.div>
            </div>
          </div>

          {isMobile && (
            <MaskedReveal elStyle={{ height: '100%' }} delay={[enterDelay + DELAY * 1]}>
              <div className="ContactInfoDivider" />
            </MaskedReveal>
          )}
        </div>
      </div>

      <div className={className}>
        <div className="label">
          <motion.div
            custom={10 * DELAY}
            variants={variants}
          >
            Follow us:
          </motion.div>
        </div>

        <div className="ContactInfo__contentContainer">
          <div className="content">
            <div className="row">
              <motion.div
                custom={11 * DELAY}
                variants={variants}
              >
                <a
                  href="https://www.facebook.com/hellofiftyseven"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={12 * DELAY}
                variants={variants}
              >
                <a
                  href="https://twitter.com/hellofiftyseven"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  X (Twitter)
                </a>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={13 * DELAY}
                variants={variants}
              >
                <a
                  href="https://dribbble.com/fiftyseven"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dribbble
                </a>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={14 * DELAY}
                variants={variants}
              >
                <a
                  href="https://www.linkedin.com/company/fiftyseven"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Linkedin
                </a>
              </motion.div>
            </div>
            <div className="row">
              <motion.div
                custom={15 * DELAY}
                variants={variants}
              >
                <a
                  href="https://www.instagram.com/hellofiftyseven"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </motion.div>
            </div>

            {slot}
          </div>
        </div>
      </div>
    </Flex>
  );
}

export default ContactInfo;
