import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import VideoBg from "./VideoBg";
import Video from "./Video";
import pxToRem from "../../Utils/pxToRem";
import ButtonCursor from "../ButtonCursor";
import ImagePadding from "./ImagePadding";

import { gsap, ScrollTrigger, useGSAP } from "../../../gsap";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, FreeMode]);

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .swiper-cursor {
    position: absolute;
    top: 50%;
    left: 80%;
    width: ${pxToRem(84)};
    height: ${pxToRem(84)};
    border-radius: 100%;
    background-color: ${(props) => props.cursorColor || "#000"};
    z-index: 2;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: scale(0);

    @media (max-width: 620px) {
      display: none;
    }

    .swiper-cursor-inner {
      font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
      font-weight: 700;
      font-size: ${pxToRem(8)};
      letter-spacing: 0.07em;
      line-height: normal;
      overflow: hidden;
      width: 100%;
      color: ${(props) => props.cursorColorText || "#fff"};
      position: relative;
      text-transform: uppercase;
      padding-top: 1px;
    }

    .swiper-cursor-text {
      display: block;
    }
  }

  .swiper-wrapper {
    transition-timing-function: ease-out !important;
  }
`;

const StyledSwiper = styled(Swiper)`
  padding: 0 calc(var(--case-container) * ${pxToRem(1)}) !important;
  width: ${(props) =>
    props.offsetcontainer
      ? `calc((100% + var(--case-container)) * ${pxToRem(1)}) !important`
      : "100%"};
  margin: ${(props) =>
    props.offsetcontainer
      ? `0 calc(var(--case-container) * -${pxToRem(1)}) !important`
      : undefined};
  box-sizing: border-box;
`;

const IconMedia = styled.div`
  position: absolute;
  width: clamp(20px, ${pxToRem(32)}, 32px);
  height: clamp(20px, ${pxToRem(32)}, 32px);
  bottom: ${pxToRem(30)};
  right: ${pxToRem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.iconMediaBorderColor || "rgba(255, 255, 255, 0.2)"};
  transition: transform 0.3s ease;
  will-change: transform;
  transform-style: preserve-3d;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .softbox {
    padding: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    overflow: hidden;

    @media (max-width: 620px) {
      padding: 80%;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 620px) {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    bottom: ${pxToRem(15)};
    right: ${pxToRem(15)};
  }

  .play,
  .pause {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: none;
    -webkit-tap-highlight-color: transparent;

    &.active {
      display: flex;
    }

    svg {
      height: 35%;
    }
  }

  .play {
    svg {
      margin-left: 1.5px;
    }
  }

  .pause {
    svg {
      @media (max-width: 620px) {
        transform: translateX(0.3px);
      }
    }
  }
`;

const SwiperSlider = (props) => {
  // const [firstEnter, setFirstEnter] = useState(false);
  const [dragHover, setDragHover] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const el = useRef(null);
  const videoRef = useRef([]);
  const elPlay = useRef([]);
  const elPause = useRef([]);
  let elCursor = useRef();
  const elCursorDrag = useRef();
  const elIconMedia = useRef([]);

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const pauseVideo = useCallback(
    (index) => {
      videoRef.current[index].pause();

      if (props.hideIconMedia) return;
      if (!elPause.current[index] && !elPlay.current[index]) return;
      elPause.current[index].classList.remove("active");
      elPlay.current[index].classList.add("active");
    },
    [props.hideIconMedia]
  );

  const playVideo = useCallback(
    (index) => {
      videoRef.current[index].play();

      if (props.hideIconMedia) return;
      if (!elPause.current[index] && !elPlay.current[index]) return;
      elPause.current[index].classList.add("active");
      elPlay.current[index].classList.remove("active");
    },
    [props.hideIconMedia]
  );

  const toggleVideo = useCallback(
    (index) => {
      if (!videoRef.current[index]) return;

      if (videoRef.current[index].isPaused()) {
        playVideo(index);
      } else {
        pauseVideo(index);
      }
    },
    [playVideo, pauseVideo]
  );

  const { contextSafe } = useGSAP({ scope: el });

  useGSAP(
    () => {
      gsap.from(".swiper-slide", {
        xPercent: 50,
        ease: "customEase2",
        willChange: "transform",
        stagger: gsap.defaults().stagger,
        scrollTrigger: {
          trigger: el.current,
        },
      });
    },
    {
      scope: el,
    }
  );

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: el.current,
        start: "top-=1750px bottom+=200px",
        onEnter: () => {
          if (!isMobile) {
            playVideo(slideIndex);
          } else {
            videoRef.current.forEach((item, index) => {
              playVideo(index);
            });
          }
        },
        onEnterBack: () => {
          if (!isMobile) {
            playVideo(slideIndex);
          } else {
            videoRef.current.forEach((item, index) => {
              playVideo(index);
            });
          }
        },
        onLeave: () => {
          if (!isMobile) {
            pauseVideo(slideIndex);
          } else {
            videoRef.current.forEach((item, index) => {
              pauseVideo(index);
            });
          }
        },
        onLeaveBack: () => {
          if (!isMobile) {
            pauseVideo(slideIndex);
          } else {
            videoRef.current.forEach((item, index) => {
              pauseVideo(index);
            });
          }
        },
      });
    }, el);

    return () => ctx.revert();
  }, [isMobile, pauseVideo, playVideo, slideIndex]);

  const slideChange = contextSafe((el) => {
    if (isMobile) return;
    el.slides.forEach((slide, idx) => {
      pauseVideo(idx);
    });

    playVideo(el.activeIndex);
    setSlideIndex(el.activeIndex);
  });

  const hideDragBtn = contextSafe(() => {
    if (!isMobile) return;
    if (!el.current) return;
    if (!elCursorDrag.current) return;

    gsap.to(elCursorDrag.current, {
      scale: 0,
      duration: 0.3,
    });
  });

  const handleMouseMove = contextSafe((event) => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (dragHover) return;

    const elRect = el.current.getBoundingClientRect();
    const cursorRect = elCursor.current.getBoundingClientRect();

    const x = event.clientX - elRect.left - cursorRect.width / 2;
    const y = event.clientY - elRect.top - cursorRect.height / 2;

    // if (!firstEnter) {
    //   gsap.set(elCursor.current, {
    //     left: x,
    //     top: y,
    //   });

    //   setFirstEnter(true);
    // }

    gsap.to(elCursor.current, {
      scale: 1,
      left: x,
      top: y,
      duration: 0.6,
      delay: 0.01,
      ease: "power4.out",
    });
  });

  const handleMouseClick = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (dragHover) return;

    const tl = gsap.timeline({
      defaults: {
        duration: 0.2,
        ease: "power1.out",
      },
    });

    tl.to(elCursor.current, {
      scale: 0.8,
    });

    tl.to(elCursor.current, {
      scale: 1,
    });
  });

  const handleMouseEnter = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 1,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
    });
  });

  const handleMouseLeave = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 0,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
      // onComplete: () => {
      //   setFirstEnter(false);
      // },
    });
  });

  const mediaHandleEnter = contextSafe(() => {
    if (isMobile) return;
    if (dragHover) return;
    setDragHover(true);

    setTimeout(() => {
      handleMouseLeave();
    }, 1);
  });

  const mediaHandleLeave = contextSafe(() => {
    if (!dragHover) return;
    setDragHover(false);
  });

  return (
    // className={isMobile ? "pl-5 mt-1" : "pl-10 mt-1"}
    <Wrapper
      ref={el}
      className={props.className}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      cursorColor={props.cursorBgColor}
      cursorColorText={props.cursorColorText}
    >
      <StyledSwiper
        offsetcontainer={props.offsetcontainer}
        spaceBetween={props.spaceBetween ?? isMobile ? 10 : 15}
        slidesPerView={isMobile ? props.slidesPerView : 1.5}
        freeMode={true}
        grabCursor={props.grabCursor ?? true}
        onSlideChange={(el) => slideChange(el)}
        onSliderFirstMove={() => hideDragBtn(el)}
        threshold={0.2}
        resistanceRatio={0.1}
        touchRatio={1.5}
        touchAngle={60}
        touchEventsTarget="container"
        // pagination={{ clickable: true }}
      >
        {isMobile && (
          <ButtonCursor
            ref={elCursorDrag}
            text="Drag"
            fontSize={pxToRem(8)}
            sizeMobile={pxToRem(74)}
            topMobile="50%"
            leftMobile="89%"
            bgColor={props.cursorBgColor}
            textColor={props.cursorColorText}
          />
        )}

        {props.slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <VideoBg backgroundColor={slide.backgroundColor}>
              {props.borderCorner ? (
                <>
                  {slide.src ? (
                    <Video
                      ref={(el) => (videoRef.current[index] = el)}
                      noScrollTrigger={true}
                      src={slide.src}
                      poster={slide.poster}
                      autoplay={true}
                      aspect={slide.aspect}
                      paddingVideo={slide.paddingVideo}
                      borderColor={props.borderColor}
                      hideIconMedia={true}
                    />
                  ) : (
                    <ImagePadding
                      noAnimated={true}
                      src={slide.poster}
                      aspect={slide.aspect}
                      padding={slide.paddingVideo}
                    />
                  )}
                </>
              ) : (
                <>
                  {slide.src ? (
                    <Video
                      ref={(el) => (videoRef.current[index] = el)}
                      noScrollTrigger={true}
                      src={slide.src}
                      poster={slide.poster}
                      aspect={slide.aspect}
                      autoplay={true}
                      paddingVideo={slide.paddingVideo}
                      borderRadius="unset"
                      borderWidth="unset"
                      borderColor="unset"
                      borderStyle="unset"
                      hideIconMedia={true}
                    />
                  ) : (
                    <ImagePadding
                      noAnimated={true}
                      src={slide.poster}
                      aspect={slide.aspect}
                      padding={slide.paddingVideo}
                    />
                  )}
                  {!props.hideIconMedia && (
                    <IconMedia
                      iconMediaBorderColor={props.iconMediaBorderColor}
                      onClick={() => toggleVideo(index)}
                      ref={(el) => (elIconMedia.current[index] = el)}
                      onMouseEnter={mediaHandleEnter}
                      onMouseLeave={mediaHandleLeave}
                    >
                      <span className="softbox"></span>
                      <span
                        ref={(el) => (elPlay.current[index] = el)}
                        className="play active"
                      >
                        <svg
                          width="6"
                          height="9"
                          viewBox="0 0 6 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 4.5L1.09788e-07 8.39711L4.50484e-07 0.602885L6 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>

                      <span
                        ref={(el) => (elPause.current[index] = el)}
                        className="pause"
                      >
                        <svg
                          width="6"
                          height="8"
                          viewBox="0 0 6 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="1.5" height="8" fill="currentColor" />
                          <rect
                            x="4"
                            width="1.5"
                            height="8"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </IconMedia>
                  )}
                </>
              )}
            </VideoBg>
          </SwiperSlide>
        ))}
      </StyledSwiper>

      {!isMobile && (
        <div className="swiper-cursor" ref={elCursor}>
          <div className="swiper-cursor-inner">
            <span className="swiper-cursor-text prev">Drag</span>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default SwiperSlider;
