import { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const MotionLink = motion(Link);

/**
 * @typedef {Pick<import('react').ButtonHTMLAttributes, 'onClick'>} ButtonEventHandlers
 */

/**
 * @typedef {object} UseButtonAttributes
 * @property {Parameters<typeof import('react').createElement>[0]} [el]
 * @property {string} [href]
 * @property {string} [rel]
 * @property {{ from: string }} [state]
 * @property {import('../Button').ButtonPartialProps['target']} [target]
 * @property {string} [to]
 */

/**
 * @typedef {object} UseButtonOptions
 * @property {boolean} [asLink=false]
 * @property {ButtonEventHandlers} [eventHandlers]
 * @property {string} [href]
 * @property {boolean} [interactive=true]
 * @property {boolean} [internal=false]
 * @property {import('../Button').ButtonPartialProps['target']} [target]
 */

/**
 * @typedef {object} UseButtonReturnValue
 * @property {UseButtonAttributes} attributes
 * @property {ButtonEventHandlers} bind
 */

/** @type {UseButtonOptions} */
const defaultUseButtonOptions = {
  asLink: false,
  eventHandlers: {},
  href: undefined,
  interactive: true,
  internal: false,
  target: '_self'
};

/**
 *
 * @param {UseButtonOptions} options
 * @returns {UseButtonReturnValue}
 */
export function useButton(options) {
  const { pathname } = useLocation();
  const {
    asLink,
    eventHandlers,
    href,
    interactive,
    internal,
    target
  } = useMemo(() => ({ ...defaultUseButtonOptions, ...options }), [options]);

  /** @type {UseButtonAttributes} */
  const attributes = useMemo(() => {
    if (asLink) {
      const isInternal = internal || (href?.startsWith('/') ?? false);
      const link = isInternal ? { to: href } : { href };
      const state = isInternal ? { from: pathname } : undefined;

      return {
        ...link,
        el: isInternal ? MotionLink : 'a',
        rel: 'noopener noreferrer',
        state,
        target
      };
    }

    return {};
  }, [asLink, href, internal, pathname, target]);

  const onClick = useCallback((e) => {
    if (eventHandlers?.onClick instanceof Function && interactive) {
      e.preventDefault();
      eventHandlers.onClick(e);
    }
  }, [eventHandlers, interactive]);

  return {
    attributes,
    bind: { onClick }
  }
}
