import { useMemo } from "react";
import { useMedia } from "react-recipes";
import classNames from "classnames";
import PropTypes from "prop-types";
import ContextMenu from "../../ContextMenu";
import contextMenuClasses from "../../ContextMenu/ContextMenu.module.scss";
import Dropdown, { DropdownToggle } from "../../Dropdown";
import classes from "../../Testimonial/Services.module.scss";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import IconInfo from "./Info";

const Icon = styled.div`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  letter-spacing: 0.02em;
  /* line-height: 1; */
  display: inline-flex;
  align-items: center;
  gap: ${pxToRem(4)};
  color: ${(props) => props.color || "currentColor"};
  /* cursor: pointer; */
  position: relative;

  span {
    opacity: 0.4;
  }

  .iconinfo {
    position: relative;
    color: ${(props) => props.svgColor || "currentColor"};

    svg {
      @media (max-width: 620px) {
        width: 13px;
        height: 13px;
      }

      circle {
        fill: ${(props) => (props.filled ? "currentColor" : "none")};
      }

      path {
        fill: ${(props) => (props.filled ? "#1b1a1c" : "currentColor")};
      }
    }
  }
`;

function Opener({ isDropdownVisible, serviceCount, theme }) {
  const fill = useMemo(
    () =>
      isDropdownVisible
        ? "var(--info-icon-hover, #fff)"
        : "var(--info-icon, #565656)",
    [isDropdownVisible]
  );

  // const color = useMemo(() => (
  //   isDropdownVisible ? '#fff' : '#939393'
  // ), [isDropdownVisible]);

  return (
    <Icon svgColor={isDropdownVisible ? 'currentColor' : '#fff'} filled={isDropdownVisible}>
      <span>{serviceCount}</span>
      <IconInfo className="iconinfo" />
    </Icon>
  );
}
function InfoHover({ services, theme }) {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  return (
    <Dropdown
      opener={DropdownToggle}
      openerProps={{
        children: Opener,
        childrenProps: { serviceCount: services.length, theme },
      }}
      popoverProps={{
        event: isMobile ? "click" : "hover",
        placement: "top-start",
      }}
    >
      <ContextMenu
        className={classNames([classes.contextMenu])}
        menuItems={services.map((item, idx) => ({
          render: () => (
            <div
              className={classNames([
                contextMenuClasses.item,
                classes.contextMenuItem,
              ])}
            >
              {idx + 1}. {item}
            </div>
          ),
        }))}
        style={{
          "--details-popup-text": theme?.detailsPopupText,
          "--details-popup-bg": theme?.detailsPopupBg,
        }}
      />
    </Dropdown>
  );
}

InfoHover.propTypes = {
  services: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object,
};

InfoHover.defaultProps = {
  services: [],
  theme: {},
};

export default InfoHover;
