import { motion } from 'framer-motion';
import React from 'react';

export const ComingSoonIcon = (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18.391 18.391"
  >
    <path fill="#fff" fillRule="evenodd" d="m14.16.045 4.23 3.514-1.195 1.415-4.23-3.514zM4.185 0l1.195 1.414-4.184 3.56L0 3.559ZM.919 10.176c0-4.518 3.679-8.214 8.276-8.214 4.552 0 8.276 3.696 8.276 8.214 0 4.518-3.724 8.215-8.276 8.215-4.597 0-8.276-3.697-8.276-8.215zm8.736-4.563h-1.38v5.476l4.369 2.602.69-1.141-3.679-2.145z" clipRule="evenodd" />
  </motion.svg>
);

export const OnRequestIcon = (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 19"
  >
    <rect width="17" height="10" y="9" fill="#fff" rx="1.5" />
    <path fill="#fff" fillRule="evenodd" d="M14 7V4.935C14 2.214 11.533 0 8.5 0S3 2.214 3 4.935V7h1.941V4.935c0-1.761 1.597-3.193 3.56-3.193 1.961 0 3.558 1.432 3.558 3.193V7H14Z" clipRule="evenodd" />
  </motion.svg>
);
