import React from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useMedia } from 'react-recipes';
import Appear from '../components/Appear';
import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import { MaskedReveal } from '../components/Motion';
import { MotionTrail } from '../components/Motion/MotionTrail';
import Page from '../components/Page';
import { withRegisterRoute } from '../routing';
import { getRandomIntRange } from '../utils';
import './Home.scss';

const MotionTrailSettings = {
  dir: '/img/trail',
  numberOfItems: 30,
  total: 30
};

const set = getRandomIntRange(1, MotionTrailSettings.total, MotionTrailSettings.numberOfItems);

// eslint-disable-next-line no-unused-vars
const srcSet = [...Array(MotionTrailSettings.numberOfItems)].map(
  (_, i) => `/img/trail/${set[i].toString().padStart(2, '0')}.jpg`
);

function Home() {
  const isMobile = useMedia(['(max-width: 640px)'], [true], false);

  return (
    <Page>
      {!isMobileOnly && !isTablet && <MotionTrail srcSet={srcSet} />}

      <div className="App Home">
        <Appear reverse>
          <div className="centerHolder centerHolder--blend">
            <MaskedReveal
              className="est"
              delay={[0, 0.5]}
            >
              EST.&nbsp;&nbsp;—&nbsp;&nbsp;2016
            </MaskedReveal>

            <Flex className="mainText" direction="column">
              <MaskedReveal
                delay={[0.15, 0.45]}
                style={{ paddingBottom: '0.075em' }}
              >
                Expression
              </MaskedReveal>

              <MaskedReveal
                delay={[0.3, 0.3]}
                style={{ paddingBottom: '0.07em' }}
              >
                designed.
              </MaskedReveal>
            </Flex>

            {!isMobile && (
              <MaskedReveal
                className="tagline"
                delay={[0.9, 0.15]}
              >
                your brand’s Story told through DIGITAL artistry
              </MaskedReveal>
            )}

            {isMobile && (
              <Flex className="tagline" direction="column">
                <MaskedReveal
                  delay={[0.9, 0.25]}
                >
                  your brand’s Story
                </MaskedReveal>

                <MaskedReveal
                  delay={[1, 0.2]}
                >
                  told through DIGITAL
                </MaskedReveal>

                <MaskedReveal
                  delay={[1.1, 0.15]}
                >
                  artistry
                </MaskedReveal>
              </Flex>
            )}

          </div>

          <div className="centerHolder centerHolder">
            <Button
              delay={1.15}
              asLink
              href="/work"
              variant="default"
            >
              our work
            </Button>
          </div>
        </Appear>
      </div>
    </Page>
  );
}

export default withRegisterRoute(Home, {
  route: '/',
  name: 'Index',
  title: "FIFTYSEVEN — Branding and Design Agency",
  description: 'Our intent is your ambition, integrated through design and expressed with integrity.',
  jsonld: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    'name': 'FIFTYSEVEN',
    'description': 'Our intent is your ambition, integrated through design and expressed with integrity.',
    'url': 'https://fiftyseven.co',
    'image': {
      '@type': 'ImageObject',
      'url': 'https://www.fiftyseven.co/thumbnail.jpg',
      'width': 1200,
      'height': 660
    }
  },
  weight: 0,
  options: {
    exact: true,
    navBackOptions: { mobile: true },
    layoutGuard: {
      mobile: { landscape: true }
    }
  }
});
