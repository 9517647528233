import React, { useLayoutEffect, useMemo } from "react";
import { useDimensions, useMedia } from "react-recipes";
import { useLocation } from "react-router-dom";
import audio from "../assets/media/loop.mp3";
import AudioPlayer from "./AudioPlayer";
import Container from "./Container";
import "./MainFooter.scss";
import Panel from "./Panel";

import { ScrollTrigger } from "../gsap";

import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { Durations, Easings } from "../animation";
import { lenisAtom } from "../atom/transitionAtom";
import { useMergedRefs } from "../hooks";

function MainFooter() {
  const [ref, containerDimensions] = useDimensions();
  const el = useMergedRefs(ref);

  const location = useLocation();

  const [lenis, setLenis] = useAtom(lenisAtom);

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const currentYear = useMemo(() => {
    return new Date().getFullYear().toString();
  }, []);

  useLayoutEffect(() => {
    if (!el.current) return;
    if (!lenis) return;
    if (location.pathname.includes("/case")) {
      ScrollTrigger.create({
        id: "footer",
        trigger: lenis.rootElement,
        start: "clamp(100vh top)",
        onEnter: () => {
          el.current.classList.add("active");
        },
        onLeaveBack: () => {
          el.current.classList.remove("active");
        },
      });
    }

    if (isMobile) {
      ScrollTrigger.create({
        id: "footerMobile",
        trigger: lenis.rootElement,
        start: "clamp(280vh top)",
        onEnter: () => {
          el.current.classList.add("hide");
        },
        onLeaveBack: () => {
          el.current.classList.remove("hide");
        },
      });
    }

    return () => {
      if (location.pathname.includes("/case")) {
        ScrollTrigger.getById("footer").kill();
      }

      if (isMobile) {
        ScrollTrigger.getById("footerMobile").kill();
      }
    };
  }, [el, location.pathname, lenis, isMobile]);

  useLayoutEffect(() => {
    document.documentElement.style.setProperty(
      "--footer-height",
      `${containerDimensions.height}px`
    );
  }, [containerDimensions.height]);

  return (
    <Container ref={el} className="MainFooter" size="l">
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        transition={{
          duration: Durations.base / 2,
        }}
        variants={{
          enter: {
            y: 0,
            transition: {
              ease: Easings.easeOutCubic,
            },
          },
          exit: {
            y: "100%",
            transition: {
              ease: Easings.easeInCubic,
            },
          },
        }}
      >
        <Panel>
          <div className="fs desktopOnly">F/S ©{currentYear}</div>
          <div className="text desktopOnly">INTEGRITY THROUGH EXPRESSION™</div>
          <div className="MainFooter__audio-player desktopOnly">
            <AudioPlayer src={audio} />
          </div>
          <div className="mobileFoot mobileOnly">
            INTEGRITY THROUGH EXPRESSION™ ©16/{currentYear.slice(2)}
          </div>
        </Panel>
      </motion.div>
    </Container>
  );
}

export default MainFooter;
