import React, { useState, useRef } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCreative,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import ImageRegular from "../Media/ImageRegular";
import ButtonCursor from "../ButtonCursor";

import { gsap, CustomEase, useGSAP } from "../../../gsap";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCreative]);

const WrapperMaster = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  user-select: none;
  cursor: pointer;

  .swiper-pagination-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: ${pxToRem(58)};
    width: max-content;
    z-index: 2;

    @media (max-width: 620px) {
      padding-bottom: ${(props) =>
        props.paginationPaddingMobile || pxToRem(24)};
    }
  }

  .swiper-pagination {
    gap: ${(props) => props.paginationGap || pxToRem(15)};
    display: flex;
    width: auto;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;

    @media (max-width: 620px) {
      gap: ${(props) => props.paginationGapMobile || pxToRem(5)};
    }
  }

  .swiper-pagination-active {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    bottom: ${pxToRem(48)};
    z-index: 2;
    background-color: ${(props) =>
      props.bulletHighlightColor || "rgba(255, 255, 255, 0.5)"};
    transition: background-color 0.3s ease;
    will-change: transform;

    @media (max-width: 620px) {
      height: 1px;
      bottom: ${(props) => props.paginationActiveBottomMobile || pxToRem(20)};
    }

    &.invert {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .swiper-pagination-bullet {
    opacity: 1;
    background-color: #fff;
    width: ${(props) => props.paginationBulletSize || pxToRem(11)};
    height: ${(props) => props.paginationBulletSize || pxToRem(11)};
    border-radius: 100%;
    border: 2px solid transparent;
    position: relative;
    transition: all var(--transition-default);

    @media (max-width: 620px) {
      border-width: ${pxToRem(1)};
      width: calc(
        ${(props) => props.paginationBulletSizeMobile || "5px"} - 2px
      );
      height: calc(
        ${(props) => props.paginationBulletSizeMobile || "5px"} - 2px
      );
    }
  }

  .swiper-pagination-bullet-active {
    background-color: transparent !important;
    border: 2px solid
      ${(props) => props.bulletHighlightColor || "rgba(255, 255, 255, 0.5)"};

    &.invert {
      border: 2px solid rgba(255, 255, 255, 0.5);

      @media (max-width: 620px) {
        border-width: 1px;
      }
    }

    @media (max-width: 620px) {
      border-width: 1px;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-wrapper {
    height: 100.5%;
  }

  .swiper-cursor {
    position: absolute;
    top: 45%;
    left: 80%;
    width: ${pxToRem(84)};
    height: ${pxToRem(84)};
    border-radius: 100%;
    background-color: ${(props) => props.cursorColor || "#000"};
    z-index: 2;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: scale(0);

    @media (max-width: 620px) {
      display: none;
    }

    .swiper-cursor-inner {
      font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
      font-weight: 700;
      font-size: ${pxToRem(8)};
      letter-spacing: 0.07em;
      line-height: normal;
      overflow: hidden;
      width: 100%;
      color: ${(props) => props.cursorColorText || "#fff"};
      position: relative;
      text-transform: uppercase;
      padding-top: 1px;
    }

    .swiper-cursor-text {
      display: block;
      transition: transform var(--transition-default);

      &.prev {
        position: relative;
        transform: translateY(-140%);

        &.active {
          transform: translateY(0%);
        }
      }

      &.next {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 105%);

        &.active {
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;

// Fake box
const Content = styled.div`
  padding-top: ${pxToRem(50)};
  padding-bottom: ${pxToRem(50)};
  margin-top: ${pxToRem(-50)};
  margin-bottom: ${pxToRem(-50)};
  padding-right: ${pxToRem(100)};
  padding-left: ${pxToRem(100)};
  margin-right: ${pxToRem(-100)};
  margin-left: ${pxToRem(-100)};
  box-sizing: content-box;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  z-index: ${(props) => props.zindex};
  transition-timing-function: cubic-bezier(0.67, 0, 0.06, 0.97) !important;
`;

const SwiperSliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: top;
`;

const SwiperSlideContent = styled.div`
  width: 100%;
  height: 100%;
  transform-origin: left;
`;

const Slider = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [area, setArea] = useState("right");
  const [paginationHover, setPaginationHover] = useState(false);
  // // const [firstEnter, setFirstEnter] = useState(false);

  let el = useRef();
  let elPaginationWrapper = useRef();
  let elPagination = useRef();
  let elPaginationActive = useRef();
  const elCursor = useRef();
  const elCursorDrag = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const params = {
    slidesPerView: 1,
    initialSlide: props.initialSlide,
    grabCursor: false,
    spaceBetween: 0,
    speed: 1400,
    rewind: true,
    followFinger: false,
    roundLengths: true,
    threshold: 0.2,
    resistanceRatio: 0.1,
    touchRatio: 1.5,
    touchAngle: 60,
    touchEventsTarget: "container",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return `<span class="${className} pagination-index-${index} ${
          props.slides[index].invert ? "invert" : null
        }" style="background-color: ${
          props.slides[index].bulletColor
        }"></span>`;
      },
    },
    effect: "creative",
    creativeEffect: {
      shadowPerProgress: true,
      prev: {
        translate: ["-30%", 0, -1],
      },
      next: {
        translate: ["125%", "5%", 0],
        rotate: [0, 0, 7],
        scale: 1.3,
      },
    },
  };

  const { contextSafe } = useGSAP({ scope: el });

  const slideInit = (el) => {
    setSwiper(el);

    const paginationActive = elPagination.current.querySelector(
      ".swiper-pagination-bullet-active"
    );

    if (!paginationActive) return;

    const getWidth = paginationActive.offsetWidth;
    const getLeft = paginationActive.offsetLeft;

    gsap.set(elPaginationActive.current, {
      width: getWidth,
      x: getLeft,
    });
  };

  const slideChange = (el) => {
    // Detect from beginning/end
    // if (el.previousIndex === el.slides.length - 1 && el.isBeginning) {
    //   if (el.slides.length > 3) {
    //     el.slideTo(props.initialSlide);
    //   }
    // }

    // if (el.previousIndex === 0 && el.isEnd) {
    //   if (el.slides.length > 3) {
    //     el.slideTo(props.initialSlide);
    //   }
    // }

    // Detect next active slide
    let slide = el.slides[el.activeIndex];
    const img = slide.querySelector(".img");

    // check invert slide
    let invert = slide.dataset.invert === "true" ? true : false;

    if (invert) {
      elPaginationActive.current.classList.add("invert");
    } else {
      elPaginationActive.current.classList.remove("invert");
    }

    const paginationActive = elPagination.current.querySelector(
      ".swiper-pagination-bullet-active"
    );
    if (!paginationActive) return;

    const getWidth = paginationActive.offsetWidth;
    const getLeft = paginationActive.offsetLeft;

    gsap.to(elPaginationActive.current, {
      width: getWidth,
      x: getLeft,
      duration: 1.4,
      ease: CustomEase.create("custom", "M0,0 C0.33,0.22 0,1.01 1,1"),
    });

    gsap.fromTo(
      img,
      {
        scale: 1.2,
      },
      {
        scale: 1,
        delay: 0.2,
        duration: 1.4,
        ease: CustomEase.create("custom", "M0,0 C0.33,0.22 0,1.01 1,1"),
        overwrite: true,
      }
    );
  };

  const hideDragBtn = contextSafe(() => {
    if (!isMobile) return;
    if (!el.current) return;
    if (!elCursorDrag.current) return;

    gsap.to(elCursorDrag.current, {
      scale: 0,
      duration: 0.3,
    });
  });

  const handleMouseMove = contextSafe((event) => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (paginationHover) return;

    const elRect = el.current.getBoundingClientRect();
    const cursorRect = elCursor.current.getBoundingClientRect();

    const x = event.clientX - elRect.left - cursorRect.width / 2;
    const y = event.clientY - elRect.top - cursorRect.height / 2;

    // if (!firstEnter) {
    //   gsap.set(elCursor.current, {
    //     left: x,
    //     top: y,
    //   });

    //   setFirstEnter(true);
    // }

    gsap.to(elCursor.current, {
      scale: 1,
      left: x,
      top: y,
      duration: 0.6,
      delay: 0.01,
      ease: "power4.out",
    });

    if (x < elRect.width / 2) {
      // Left area
      setArea("left");
    } else {
      // Right area
      setArea("right");
    }
  });

  const handleMouseClick = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    if (paginationHover) return;

    const tl = gsap.timeline({
      defaults: {
        duration: 0.3,
        ease: "power2.out",
      },
    });

    tl.to(elCursor.current, {
      scale: 0.9,
    });

    tl.to(elCursor.current, {
      scale: 1,
    });

    if (area === "left") {
      // Left area
      if (swiper) {
        swiper.slidePrev();
      }
    } else {
      // Right area
      if (swiper) {
        swiper.slideNext();
      }
    }
  });

  const handleMouseEnter = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 1,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
    });
  });

  const handleMouseLeave = contextSafe(() => {
    if (!elCursor.current) return;
    if (isMobile) return;
    gsap.to(elCursor.current, {
      scale: 0,
      duration: 0.6,
      overwrite: true,
      ease: "power4.out",
      // onComplete: () => {
      //   setFirstEnter(false);
      // },
    });
  });

  const handlePaginationHover = contextSafe(() => {
    if (isMobile) return;
    if (paginationHover) return;
    setPaginationHover(true);
    setTimeout(() => {
      handleMouseLeave();
    }, 1);
  });

  const handlePaginationLeave = contextSafe(() => {
    if (isMobile) return;
    if (!paginationHover) return;
    setPaginationHover(false);
  });

  return (
    <WrapperMaster
      ref={el}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
    >
      {isMobile && (
        <ButtonCursor
          ref={elCursorDrag}
          text="Drag"
          fontSize={pxToRem(8)}
          sizeMobile={pxToRem(74)}
          topMobile={props.cursorDragTop || "50%"}
          leftMobile={props.cursorDragLeft || "89%"}
          bgColor={props.cursorColor}
          textColor={props.cursorColorText}
        />
      )}
      <Wrapper
        style={{ "--aspect": "12/7" }}
        bulletHighlightColor={props.bulletHighlightColor}
        cursorColor={props.cursorColor}
        cursorColorText={props.cursorColorText}
        paginationBulletSize={props.paginationBulletSize}
        paginationBulletSizeMobile={props.paginationBulletSizeMobile}
        paginationGap={props.paginationGap}
        paginationGapMobile={props.paginationGapMobile}
        paginationPaddingMobile={props.paginationPaddingMobile}
        paginationActiveBottomMobile={props.paginationActiveBottomMobile}
      >
        <Content>
          <Swiper
            className={props.className}
            {...params}
            onInit={(el) => slideInit(el)}
            onSlideChange={(el) => slideChange(el)}
            onSliderFirstMove={() => hideDragBtn(el)}
          >
            {props.slides.map((slide, index) => (
              <StyledSwiperSlide
                data-invert={slide.invert}
                key={index}
                zindex={props.slides.length - 1 - index}
              >
                <SwiperSliderWrapper className="slide-wrapper">
                  <SwiperSlideContent className="slide-content">
                    <ImageRegular src={slide.src} alt={slide.alt} />
                  </SwiperSlideContent>
                </SwiperSliderWrapper>
              </StyledSwiperSlide>
            ))}

            <div
              className="swiper-pagination-wrapper"
              ref={elPaginationWrapper}
              onMouseEnter={handlePaginationHover}
              onMouseLeave={handlePaginationLeave}
            >
              <div className="swiper-pagination" ref={elPagination}></div>
              <div
                className="swiper-pagination-active"
                ref={elPaginationActive}
              ></div>
            </div>
          </Swiper>
        </Content>

        {!isMobile && (
          <div className="swiper-cursor" ref={elCursor}>
            <div className="swiper-cursor-inner">
              <span
                className={`swiper-cursor-text prev ${
                  area === "left" && "active"
                }`}
              >
                Prev
              </span>
              <span
                className={`swiper-cursor-text next ${
                  area === "right" && "active"
                }`}
              >
                Next
              </span>
            </div>
          </div>
        )}
      </Wrapper>
    </WrapperMaster>
  );
};

export default Slider;
