import classNames from 'classnames';
import { motion } from 'framer-motion';
import { omit } from 'lodash';
import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import classes from './Icon.module.scss';

const colorFn = ({ color }) => color;

const propBuilder = (props, options) => {
  return Object.entries(props).reduce((acc, [key, value]) => {
    acc[key] = typeof value === 'function' ? value(options) : value;

    return acc;
  }, {});
};

const pathBuilder = (definition, options) => {
  if (definition.path) {
    const paths = Array.isArray(definition.path) ? definition.path : [definition.path];

    return paths.map(([d, props], i) => (
      <path key={i} d={d} {...propBuilder(props, options)} />
    ));
  }

  if (definition.circle) {
    const [d, props] = definition.circle;

    return (
      <circle cx={d.cx} cy={d.cy} r={d.r} {...propBuilder(props, options)} />
    );
  }
};

const IconDefinitions = {
  link: {
    width: 7,
    height: 7,
    path: [
      ['M7.09 1.66L5.75 0.32L2.08 3.98L2.15 2.29V0.97H0.4V7H6.43V5.25H5.11L3.36 5.32L7.09 1.66Z', { fill: colorFn }]
    ]
  },
  linkFlip: {
    width: 7,
    height: 7,
    path: [
      ['M.86 2.07h1.32L3.93 2 .2 5.66 1.54 7l3.67-3.66-.07 1.69v1.32h1.75V.32H.86v1.75Z', { fill: colorFn }]
    ]
  },
  info: {
    width: 15,
    height: 15,
    path: [
      ['M12.227 12.217C13.472 10.966 14.1 9.391 14.1 7.5c0-1.89-.628-3.48-1.873-4.73C10.98 1.518 9.394.9 7.5.9c-1.894 0-3.48.618-4.727 1.87C1.528 4.02.9 5.596.9 7.5c0 1.891.628 3.466 1.873 4.717C4.02 13.482 5.606 14.1 7.5 14.1c1.894 0 3.48-.618 4.727-1.883Zm-8.77-.63C2.394 10.508 1.861 9.157 1.861 7.5c0-1.657.533-3.008 1.596-4.087C4.52 2.335 5.861 1.802 7.5 1.802c1.64 0 2.98.533 4.043 1.611 1.063 1.079 1.596 2.43 1.596 4.087 0 1.657-.533 3.008-1.596 4.087-1.063 1.078-2.404 1.611-4.043 1.611-1.64 0-2.98-.533-4.043-1.611Z', { stroke: colorFn, fill: colorFn, strokeWidth: '0.2' }],
      ['M8 4.91V4H7v.91h1ZM8 11V6.257H7V11h1Z', { fill: colorFn }]
    ]
  },
  plus: {
    width: 8,
    height: 8,
    path: [
      ['M4.876 2.904h2.639V4.75H4.876v2.691H2.913V4.75H.3V2.904h2.613V.213h1.963v2.691Z', { fill: colorFn }]
    ]
  },
  checkmark: {
    width: 22,
    height: 22,
    path: [
      ['M14.4525 8L9.75 12.2619L7.54667 10.4154L6 11.7844L9.75 15L16 9.36832L14.4525 8Z', { fill: colorFn }]
    ]
  },
  infoCircle: {
    width: 15,
    height: 15,
    path: [
      ['M12.2268 12.217C13.4719 10.9658 14.1 9.39103 14.1 7.5C14.1 5.60909 13.4719 4.02094 12.2266 2.76958C10.9808 1.5177 9.3939 0.9 7.5 0.9C5.6061 0.9 4.01918 1.5177 2.77336 2.76958C1.52814 4.02087 0.9 5.59575 0.9 7.5C0.9 9.391 1.52813 10.9658 2.77313 12.2169C4.01903 13.4823 5.60611 14.1 7.5 14.1C9.39386 14.1 10.9809 13.4823 12.2268 12.217ZM3.45712 11.5866C2.39382 10.5082 1.86091 9.15733 1.86091 7.5C1.86091 5.84267 2.39382 4.49185 3.45712 3.41339C4.51997 2.33538 5.86057 1.80163 7.5 1.80163C9.13943 1.80163 10.48 2.33538 11.5429 3.41339C12.6062 4.49185 13.1391 5.84267 13.1391 7.5C13.1391 9.15733 12.6062 10.5082 11.5429 11.5866C10.48 12.6646 9.13943 13.1984 7.5 13.1984C5.86057 13.1984 4.51997 12.6646 3.45712 11.5866Z', { fill: colorFn, stroke: colorFn, strokeWidth: '0.2' }],
      ['M8 4.90992V4H7V4.90992H8ZM8 11V6.25735H7V11H8Z', { fill: colorFn }]
    ]
  },
  circle: {
    width: 8,
    height: 8,
    circle: [{ cx: 4, cy: 4, r: 4 }, { fill: colorFn }]
  }
};

<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
  <path d="M12.2268 12.217C13.4719 10.9658 14.1 9.39103 14.1 7.5C14.1 5.60909 13.4719 4.02094 12.2266 2.76958C10.9808 1.5177 9.3939 0.9 7.5 0.9C5.6061 0.9 4.01918 1.5177 2.77336 2.76958C1.52814 4.02087 0.9 5.59575 0.9 7.5C0.9 9.391 1.52813 10.9658 2.77313 12.2169C4.01903 13.4823 5.60611 14.1 7.5 14.1C9.39386 14.1 10.9809 13.4823 12.2268 12.217ZM3.45712 11.5866C2.39382 10.5082 1.86091 9.15733 1.86091 7.5C1.86091 5.84267 2.39382 4.49185 3.45712 3.41339C4.51997 2.33538 5.86057 1.80163 7.5 1.80163C9.13943 1.80163 10.48 2.33538 11.5429 3.41339C12.6062 4.49185 13.1391 5.84267 13.1391 7.5C13.1391 9.15733 12.6062 10.5082 11.5429 11.5866C10.48 12.6646 9.13943 13.1984 7.5 13.1984C5.86057 13.1984 4.51997 12.6646 3.45712 11.5866Z" fill="white" stroke="white" strokeWidth="0.2" />
  <path d="M8 4.90992V4H7V4.90992H8ZM8 11V6.25735H7V11H8Z" fill="white" />
</svg>

/** @typedef {keyof IconDefinitions} IconType */

/**
 * @typedef {object} IconProps
 * @property {string} [className]
 * @property {IconType} type
 * @property {string} [color='#fff']
 * @property {import('react').CSSProperties} [style]
 * @property {import('react').CSSProperties} [svgStyle]
 */

/** @type {ReturnType<typeof import('react').forwardRef<HTMLElement, IconProps>>} */
export const Icon = React.forwardRef((
  {
    className,
    color = '#fff',
    style,
    svgStyle,
    type
  },
  ref
) => {
  const definition = useMemo(() => IconDefinitions[type], [type]);
  const svgProps = useMemo(() => propBuilder(omit(definition, ['path', 'circle']), {}), [definition]);
  const paths = useMemo(() => pathBuilder(definition, { color }), [definition, color]);

  const node = (
    <svg
      {...svgProps}
      viewBox={`0 0 ${definition.width} ${definition.height}`}
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyle}
    >
      {paths}
    </svg>
  );

  return (
    <div
      ref={ref}
      className={classNames([
        classes.root,
        className
      ])}
      style={style}
    >
      <span
        style={{
          display: 'flex',
          backgroundImage: `url(data:image/svg+xml;base64,${window.btoa(renderToString(node))})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          aspectRatio: 1,
          width: '100%'
        }}
      />
    </div>
  );

  // return (
  //   <svg
  //     {...svgProps}
  //     viewBox={`0 0 ${definition.width} ${definition.height}`}
  //     className={className}
  //     xmlns="http://www.w3.org/2000/svg"
  //     style={style}
  //   >
  //     {paths}
  //   </svg>
  // );
});

Icon.displayName = 'Icon';

export const MotionIcon = motion(Icon);
