import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: ${(props) => props.maxWidth};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  transform: ${(props) => props.transform};
  text-align: ${(props) => props.align || "center"};
  z-index: ${(props) => props.zIndex};
  background-color: ${(props) => props.backgroundColor};
`;

const AbsoluteText = (props) => {
  return (
    <Wrapper
      className={props.className}
      align={props.align}
      width={props.width}
      height={props.height}
      maxWidth={props.maxWidth}
      top={props.top}
      left={props.left}
      right={props.right}
      bottom={props.bottom}
      transform={props.transform}
      zIndex={props.zIndex}
      backgroundColor={props.backgroundColor}
    >
      {props.children}
    </Wrapper>
  );
};

export default AbsoluteText;
