import { useCreation, useMount, useSetState } from 'ahooks';
import { Howl, Howler } from 'howler';
import { useCallback, useEffect, useMemo } from 'react';
import { AudioContextProvider } from './audioContext';

Howler.autoUnlock = false;

/**
 * @typedef {object} AudioProviderProps
 * @property {React.ReactNode} children
 * @property {string} src
 */

export const AudioProvider = ({ children, src }) => {
  const [state, setState] = useSetState({ loaded: false, playing: false });
  const howl = useCreation(() => new Howl({
    src,
    loop: true,
    onload: () => setState({ loaded: true })
  }), [src]);

  const play = useCallback(() => {
    if (state.loaded && !state.playing) {
      howl.play();
      setState({ playing: true });
    }
  }, [howl, setState, state.loaded, state.playing]);

  const pause = useCallback(() => {
    if (state.loaded && state.playing) {
      howl.pause()
      setState({ playing: false });
    }
  }, [howl, setState, state.loaded, state.playing]);

  const toggle = useCallback(() => {
    if (!state.loaded) {
      return
    }

    if (state.playing) {
      pause();
    } else {
      play();
    }
  }, [pause, play, state.loaded, state.playing]);

  /** @type {import('./audioContext').AudioContextValue} */
  const contextValue = useMemo(() => ({
    ...state,
    pause,
    play,
    toggle
  }), [pause, play, state, toggle]);

  useEffect(() => {
    return () => {
      howl.unload();
    }
  }, [howl]);

  useMount(() => {
    if ('getAutoplayPolicy' in navigator && navigator.getAutoplayPolicy('mediaelement') === 'allowed') {
      play();
    }
  });

  return (
    <AudioContextProvider value={contextValue}>
      {children}
    </AudioContextProvider>
  );
};

AudioProvider.displayName = 'AudioProvider';
