import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";

import Label1 from "./Text/Label1";
import Border from "./Border";

import { ScrollTrigger } from "../../gsap";

const AccordionHeader = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${(props) => (props.nopaddingtop ? `0px` : `${pxToRem(24.5)}`)};
  padding-bottom: ${pxToRem(24.5)};
`;

const AccordionIcon = styled(motion.div)`
  cursor: pointer;

  path {
    fill: currentColor;
  }
`;

const AccordionContent = styled(motion.div)`
  overflow: hidden;
`;

const Accordion = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  return (
    <div className={props.className}>
      <AccordionHeader
        initial={false}
        onClick={() => setIsOpen(!isOpen)}
        nopaddingtop={props.nopaddingtop}
      >
        <Label1 margin="0" text={props.title} noAnimated={true} />

        <AccordionIcon
          animate={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
              fill="black"
            />
          </svg>
        </AccordionIcon>
      </AccordionHeader>
      <AnimatePresence initial={false}>
        <AccordionContent
          key="content"
          initial="collapsed"
          animate={isOpen ? "open" : "collapsed"}
          variants={{
            open: { height: "auto" },
            collapsed: { height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          onAnimationComplete={(definition) => {
            if (definition === "collapsed") {
              ScrollTrigger.refresh();
            }
          }}
        >
          {props.children}
        </AccordionContent>
        <Border className={isOpen ? "mt-7" : ""} color={props.borderColor} />
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
