import { motion } from 'framer-motion';
import React from 'react';
import classes from './MobileOverlay.module.scss';

/** @type {import('framer-motion').Variants} */
const RevealVariants = {
  show: () => ({
    y: '0%',
    transition: {
      delay: 0.48,
      duration: 0.32,
      ease: 'easeOut'
    }
  }),
  hide: () => ({
    y: 'calc(100% + 8px)',
    transition: {
      delay: 0,
      duration: 0.32,
      ease: 'easeOut'
    }
  })
};

/**
 * @typedef {object} MobileOverlayProps
 * @property {children} never
 * @property {React.ReactNode} iconSlot
 * @property {React.ReactNode} textSlot
 * @property {import('react').CSSProperties} style
 */

/** @type {import('react').FC<MobileOverlayProps>} */
export const MobileOverlay = (props) => {
  const { iconSlot, textSlot, onClick, style } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0.4 } }}
      className={classes.root}
      style={style}
      onClick={onClick}
    >
      <div className={classes.backdrop} />
      <div className={classes.iconSlot}>
        {iconSlot}
      </div>

      <div className={classes.textSlot}>
        <motion.div
          animate="show"
          exit="hide"
          initial="hide"
          variants={RevealVariants}
        >
          {textSlot}
        </motion.div>
      </div>
    </motion.div>
  );
};
