import React, { useRef } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";
import AnimTextPerlineSpecial from "./Motion/TextperlineSpecial";

import { gsap, ScrollTrigger, useGSAP } from "../../gsap";
import { Button } from "../Button";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pxToRem(15)};
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: ${pxToRem(100)} 0;

  @media screen and (max-width: 620px) {
    padding-top: ${pxToRem(60)};
    padding-bottom: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: ${pxToRem(120)};
  }

  .case-btn {
    bottom: ${pxToRem(100)};
  }

  a {
    color: currentcolor;
  }
`;

const List = styled.ul`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, ${pxToRem(16)}, ${pxToRem(16)});
  letter-spacing: 0.03em;
  line-height: 1.625;
  padding-left: 0;

  @media screen and (max-width: 620px) {
    width: 100%;
    font-size: ${pxToRem(14)};
    letter-spacing: 0.02em;
    line-height: 1.57;
    position: relative;

    &.active {
      &::after {
        transform: scaleX(1);
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -${pxToRem(60)};
      width: 100%;
      height: 1px;
      background-color: ${(props) => props.borderColor || "currentColor"};
      transform: scaleX(0);
      transform-origin: left;
      transition: transform var(--transition-default);
    }
  }
`;

const Item = styled.li`
  display: flex;
  gap: 6px;

  @media screen and (max-width: 620px) {
    justify-content: space-between;
  }
`;

const Value = styled.span`
  opacity: 0.4;
  font-size: clamp(14px, ${pxToRem(16)}, ${pxToRem(16)});
  letter-spacing: 0.02em;
  line-height: 1.625;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(14)};
    line-height: 1.57;
    text-align: right;
  }
`;

const TextButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 620px) {
    margin-top: ${pxToRem(10)};
  }

  a {
    color: currentColor;
  }
`;

const Credit = (props) => {
  const el = useRef();
  const itemRefs = useRef([]);
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const animationDelay = gsap.defaults().stagger; // stagger;

  const addItemRef = (el, index) => {
    itemRefs.current[index] = el;
  };

  useGSAP(
    () => {
      if (!isMobile) return;

      gsap.utils.toArray([itemRefs.current]).forEach((ref, index) => {
        ScrollTrigger.create({
          trigger: ref,
          start: "bottom bottom",
          onEnter: () => {
            ref.classList.add("active");
          },
        });
      });
    },
    {
      dependencies: [isMobile],
      scope: el,
    }
  );

  return (
    <Wrapper ref={el}>
      {!isMobile && (
        <AnimTextPerlineSpecial delay={(animationDelay ?? 0) + 0.1} className="case-btn">
          <div>
            <Button
              asLink
              href={props.visitWebsiteLink}
              target="_blank"
              style={{
                '--btn-bg-color': 'var(--color-headerpanel)',
                '--btn-text-color': 'var(--color-headertext)',
              }}
              noAnimation
            >
              Visit Website
            </Button>
          </div>
        </AnimTextPerlineSpecial>
      )}

      {!isMobile && (
        <List borderColor={props.borderColor}>
          <AnimTextPerlineSpecial>
            <Item>
              Client: <Value>{props.client}</Value>
            </Item>

            {!props.onlyClient && (
              <>
                <Item>
                  Timeline: <Value>{props.timeline}</Value>
                </Item>
                <Item>
                  Year: <Value>{props.year}</Value>
                </Item>
                {/* <Item>
                  Team: <Value>{props.team}</Value>
                </Item> */}
              </>
            )}
          </AnimTextPerlineSpecial>
        </List>
      )}

      {!isMobile && props.onlyClient && (
        <List borderColor={props.borderColor}>
          <AnimTextPerlineSpecial>
            <Item>
              Timeline: <Value>{props.timeline}</Value>
            </Item>
            <Item>
              Year: <Value>{props.year}</Value>
            </Item>
            {/* <Item>
              Team: <Value>{props.team}</Value>
            </Item> */}
          </AnimTextPerlineSpecial>
        </List>
      )}

      {isMobile && (
        <List borderColor={props.borderColor} ref={(el) => addItemRef(el, 0)}>
          <Item>
            <AnimTextPerlineSpecial delay={animationDelay}>
              <span>Client:</span>
              <span>Timeline:</span>
              <span>Year:</span>
              {/* <span>Team:</span> */}
              <span>Country:</span>
              <span>Industry:</span>
            </AnimTextPerlineSpecial>
            <Value>
              <AnimTextPerlineSpecial delay={animationDelay}>
                <span>
                  {props.client}
                  <br />
                  {props.timeline}
                  <br />
                  {props.year}
                  {/* <br />
                  {props.team} */}
                  <br />
                  {props.country}
                  <br />
                  {props.industry}
                </span>
              </AnimTextPerlineSpecial>
            </Value>
          </Item>
        </List>
      )}

      {props.awards && (
        <List borderColor={props.borderColor} ref={(el) => addItemRef(el, 1)}>
          <Item>
            <AnimTextPerlineSpecial delay={animationDelay}>
              <span>Awards:</span>
            </AnimTextPerlineSpecial>
            <Value>
              <AnimTextPerlineSpecial delay={animationDelay}>
                {props.awards.map((item, index) => (
                  <span key={index}>
                    <a href={item.link} rel="noreferrer noopener" target="_blank">{item.title}</a>
                    <br />
                  </span>
                ))}
              </AnimTextPerlineSpecial>
            </Value>
          </Item>
        </List>
      )}

      {!isMobile && (
        <List borderColor={props.borderColor}>
          <AnimTextPerlineSpecial delay={animationDelay * 3}>
            <Item>
              Industry: <Value>{props.industry}</Value>
            </Item>
            <Item>
              Country: <Value>{props.country}</Value>
            </Item>
          </AnimTextPerlineSpecial>
        </List>
      )}

      <List borderColor={props.borderColor} ref={(el) => addItemRef(el, 2)}>
        <Item>
          <AnimTextPerlineSpecial delay={animationDelay * 2}>
            <span>Services:</span>
          </AnimTextPerlineSpecial>
          <Value>
            <AnimTextPerlineSpecial delay={animationDelay * 2}>
              {props.services.map((item, index) => (
                <span key={index}>
                  {item}
                  <br />
                </span>
              ))}
            </AnimTextPerlineSpecial>
          </Value>
        </Item>
      </List>

      {isMobile && (
        <AnimTextPerlineSpecial className="case-btn">
          <TextButtonWrapper>
            <Button
              asLink
              href={props.visitWebsiteLink}
              target="_blank"
              style={{
                '--btn-bg-color': 'var(--color-headerpanel)',
                '--btn-text-color': 'var(--color-headertext)',
              }}
              noAnimation
            >
              Visit Website
            </Button>
          </TextButtonWrapper>
        </AnimTextPerlineSpecial>
      )}
    </Wrapper>
  );
};

export default Credit;
