import React from 'react';
import { isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { useRouteOptions } from '../../hooks';
import { Flex } from '../Flex';
import classes from './SiteLayoutGuard.module.scss';
import { getDeviceType } from '../Flex/helpers';

/** @type {import('react').FC} */
export const SiteLayoutGuard = ({ children }) => {
  const { orientation } = useMobileOrientation();
  const { layoutGuard: layoutGuardOptions } = useRouteOptions();
  const guardKey = getDeviceType();
  const shouldGuard = layoutGuardOptions?.[guardKey]?.[orientation] ?? false;

  if (shouldGuard) {
    return (
      <Flex
        alignItems="center"
        className={classes.root}
        direction="column"
        data-device-orientation={orientation}
        data-device-type={guardKey}
      >
        <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 114 114">
          <path fill="#575757" fillRule="evenodd" d="M78.226 11.875C93.966 19 104.937 34.2 106.845 52.25H114C111.138 22.8 86.812 0 56.761 0h-3.338l18.125 18.05 6.678-6.175Zm-42.929 90.25C19.557 95 8.586 79.8 6.677 61.75H0C2.385 91.2 26.711 114 56.761 114h3.34L41.974 95.95l-6.678 6.175Z" clipRule="evenodd" />
          <path fill="#fff" fillRule="evenodd" d="M48.175 8.075c-2.862-2.85-7.155-2.85-10.017 0l-30.527 30.4c-2.862 2.85-2.862 7.125 0 9.975l57.239 57c2.862 2.85 7.155 2.85 10.016 0l30.528-30.4c2.862-2.85 2.862-7.125 0-9.975l-57.239-57ZM70.117 100.7l-57.239-57 30.527-30.4 57.239 57-30.527 30.4Z" clipRule="evenodd" />
        </svg>

        <svg className={classes.logo} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 8">
          <path fill="#fff" d="M52.547 0H45.82c-.223 0-.404.152-.404.34v3.307c0 .157.128.292.31.329l5.1 1.01v1.353h-3.288v-.663h-2.122v1.87c0 .187.18.34.404.34h6.727c.222 0 .404-.153.404-.34V4.236c0-.158-.129-.292-.31-.329l-5.1-1.01v-1.35h3.287v.663h2.123V.34c0-.188-.182-.34-.404-.34ZM73.088 3.501 74.916 0h-2.344l-1.83 3.501h2.346ZM65.032 0l3.73 7.886h2.349L67.379 0h-2.347ZM11.428 0H9.305v7.885h2.123V0ZM26.58 7.885h2.107v-6.35h3.108V0h-8.327v1.536h3.11v6.35h.001ZM93.317 5.004 88.283 0h-1.786v7.885h2.123V3.032l5.033 4.854h1.773V0h-2.109v5.004ZM37.535 3.501 35.705 0h-2.346l1.83 3.501h2.346ZM39.516 7.885 43.246 0h-2.349l-3.731 7.886h2.35ZM62.861 6.338h-5.057V4.716h3.277c.222 0 .402-.153.402-.339V3.168h-3.68v-1.62h5.058V0H55.68v7.885h7.181V6.338ZM16.287 4.716h3.277c.222 0 .402-.153.402-.339V3.168h-3.68v-1.62h5.058V0h-7.181v7.885h2.122v-3.17h.002ZM2.123 4.716H5.4c.222 0 .402-.153.402-.339V3.168h-3.68v-1.62h5.06V0H0v7.885h2.123v-3.17ZM83.763 6.338h-5.056V4.716h3.277c.222 0 .402-.153.402-.339V3.168h-3.678v-1.62h5.056V0h-7.181v7.885h7.181V6.338h-.001Z" />
        </svg>

        <div className={classes.title}>
          {isMobileOnly && <span>Please rotate your device.</span>}
          {isTablet && <span>Please rotate your device<br />for a better experience.</span>}
        </div>
      </Flex>
    );
  }

  return (children);
};
