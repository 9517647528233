import React from 'react';
import SlotCounter from 'react-slot-counter';
import classes from './Counter.module.scss';

/**
 * @typedef {Parameters<typeof SlotCounter>[0]} CounterProps
 */

/** @type {import('react').ForwardRefExoticComponent<CounterProps>} */
export const Counter = React.forwardRef((props, ref) => {
  return (
    <SlotCounter
      ref={ref}
      numberSlotClassName={classes.numberSlot}
      autoAnimationStart={false}
      sequentialAnimationMode
      {...props}
    />
  );
});

Counter.displayName = 'Counter';
