import { Durations, Easings } from '../../animation';

/** @type {import('framer-motion').Variants} */
export const LabelVariants = {
  show: () => ({
    y: 0,
    transition: {
      ease: Easings.easeOutCubic
    }
  }),
  hide: ({ y }) => ({
    y,
    transition: {
      ease: Easings.easeOutCubic
    }
  })
};

export const BodyVariants = {
  open: {
    height: 'auto',
    transition: {
      ease: Easings.brunoIn,
      duration: Durations.base
    }
  },
  collapsed: {
    height: 0,
    transition: {
      ease: Easings.brunoOut,
      duration: Durations.base
    }
  }
};
