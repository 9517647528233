import { AnimatePresence } from 'framer-motion';
import { useCallback } from 'react';
import { Navigate, Route, Routes as DOMRoutes } from 'react-router-dom';
import { getRegisteredRoutes } from './routing';

/**
 * @typedef {object} RoutesProps
 * @property {import('react-router-dom').Location} location
 * @property {import('./routing').RouteTransitionType} mode
 */

/**
 * @param {RoutesProps} props
 * @returns {JSX.Element}
 */
export function Routes(props) {
  const { location, mode } = props;

  const handleOnExitComplete = useCallback(() => {
  }, []);

  return (
    <AnimatePresence
      initial="exit"
      mode={mode}
      onExitComplete={handleOnExitComplete}
    >
      <DOMRoutes
        key={location.pathname}
        location={location}
      >
        {getRegisteredRoutes().map(({ route, component: Component, options }) => (
          <Route
            key={options.key}
            element={(
              <Component
                key={options.key}
                routeOptions={options}
              />
            )}
            path={route}
          />
        ))}
        <Route
          key="not_found"
          path="*"
          element={(
            <Navigate to="/" />
          )}
        />
      </DOMRoutes>
    </AnimatePresence>
  );
}
