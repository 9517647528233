import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import { gsap, ScrollTrigger } from "../../gsap";

import { useAtom } from "jotai";
import { lenisAtom } from "../../atom/transitionAtom";

const Wrapper = styled.nav`
  position: fixed;
  top: 50%;
  right: 50px;
  z-index: 2;
  transform: translateY(-50%) translateX(150%);

  @media screen and (max-width: 620px) {
    display: none;
  }

  &.fullscreen {
    li {
      background-color: var(--color-headerpanel);
      border-color: var(--color-headerpanel);
    }
  }

  ul {
    list-style: none;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
  }

  li {
    font-size: 8px;
    font-family: "Suisse Intl", sans-serif;
    font-weight: 600;
    font-size: 8px;
    letter-spacing: 0.1em;
    line-height: 1;
    width: 14px;
    height: 17px;
    border-radius: 3px;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    color: var(--color-headertext);
    /* border: 1px solid var(--color-headerpanel); */
    background-color: var(--color-headerpanel);
    opacity: 0.35;
    transition: width 0.4s var(--transition-ease),
      /* border-color 0.4s var(--transition-ease), */
      background-color 0.4s var(--transition-ease),
      opacity 0.4s var(--transition-ease);
    will-change: width, border-color, background-color, opacity;

    &.color-branding {
      /* border-color: var(--color-chapters-branding-border); */
      color: var(--color-chapters-branding-text);
      background-color: var(--color-chapters-branding-border);
    }

    &.color-visual {
      /* border-color: var(--color-chapters-visual-border); */
      color: var(--color-chapters-visual-text);
      background-color: var(--color-chapters-visual-border);
    }

    &.color-website {
      /* border-color: var(--color-chapters-website-border); */
      color: var(--color-chapters-website-text);
      background-color: var(--color-chapters-website-border);
    }

    &.i {
      margin-top: 0px;
    }

    &.b {
      margin-top: 14px;
    }

    &.v {
      margin-top: 23px;
    }

    &.w {
      margin-top: 43px;
    }

    &.o {
      margin-top: 41px;
    }

    &.active {
      opacity: 1;
    }

    .text {
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      inset: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .shortext,
    .fulltext {
      display: inline-block;
    }

    .fulltext {
      letter-spacing: 0.2em;
      width: 0;
      opacity: 0;
      transition: width 0.4s var(--transition-ease),
        opacity 0.3s var(--transition-ease),
        letter-spacing 0.6s var(--transition-ease);
      will-change: width, opacity, letter-spacing;
    }

    &:hover {
      width: 65px;
      opacity: 1;
      /* background-color: transparent; */

      .fulltext {
        letter-spacing: inherit;
        width: auto;
        opacity: 1;
      }
    }
  }
`;

const SectionChapters = ({
  sectionIntro = true,
  sectionBranding = true,
  sectionVisual = true,
  sectionWebsite = true,
  sectionOutcome = true,
  refOverview,
  refBranding,
  refVisual,
  refWebsite,
  refOutcome,
  refNext,
  refFull,
}) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();
  const elIntro = useRef();
  const elBranding = useRef();
  const elVisual = useRef();
  const elWebsite = useRef();
  const elOutcome = useRef();

  const [lenis] = useAtom(lenisAtom);

  const scrollTo = (id) => {
    let el;

    switch (id) {
      case "overview":
        el = refOverview;
        break;
      case "branding":
        el = refBranding;
        break;
      case "visual":
        el = refVisual;
        break;
      case "website":
        el = refWebsite;
        break;
      case "outcome":
        el = refOutcome;
        break;
      default:
        break;
    }

    lenis.scrollTo(el, {
      offset: id === "outcome" ? -200 : 50,
      duration: 1,
      force: true,
    });
  };

  const triggersConfig = [
    { targetElement: elIntro, startValue: "clamp(top center-=10%)" },
    { targetElement: elBranding, startValue: "clamp(top center-=6.8%)" },
    { targetElement: elVisual, startValue: "clamp(top center-=2.5%)" },
    { targetElement: elWebsite, startValue: "clamp(top center+=4%)" },
    { targetElement: elOutcome, startValue: "clamp(top center+=10%)" },
  ];

  const createScrollTrigger = ({
    triggerElement,
    targetElement,
    startValue,
    endValue = "bottom top",
    className = "color-branding",
  }) => {
    if (!targetElement.current) return;
    ScrollTrigger.create({
      trigger: triggerElement,
      start: startValue,
      end: endValue,
      onEnter: () => {
        targetElement.current.classList.add(className);
      },
      onEnterBack: () => {
        targetElement.current.classList.add(className);
      },
      onLeave: () => {
        targetElement.current.classList.remove(className);
      },
      onLeaveBack: () => {
        targetElement.current.classList.remove(className);
      },
    });
  };

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (isMobile) return;
      if (!el.current) return;
      if (!refFull) return;

      gsap.utils.toArray(refFull).forEach((ref, i) => {
        ScrollTrigger.create({
          trigger: ref,
          start: "clamp(top center+=12%)",
          end: "clamp(bottom center-=12%)",
          onEnter: () => {
            el.current.classList.add("fullscreen");
          },
          onEnterBack: () => {
            el.current.classList.add("fullscreen");
          },
          onLeave: () => {
            el.current.classList.remove("fullscreen");
          },
          onLeaveBack: () => {
            el.current.classList.remove("fullscreen");
          },
        });
      });
    }, el);

    return () => ctx.revert();
  }, [el, isMobile, refFull]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (isMobile) return;
      if (!el.current) return;

      const triggerStart = "clamp(top top)";
      const triggerEnd = "clamp(bottom top)";

      const next = refNext;

      const showNav = () => {
        gsap.to(el.current, {
          x: 0,
          pointerEvents: "auto",
          duration: 0.6,
          overwrite: true,
        });
      };

      const hideNav = () => {
        gsap.to(el.current, {
          x: "150%",
          pointerEvents: "none",
          duration: 0.4,
          overwrite: true,
        });
      };

      ScrollTrigger.create({
        trigger: next,
        start: "clamp(top bottom)",
        onEnter: () => {
          hideNav();
        },
        onEnterBack: () => {
          hideNav();
        },
        onLeave: () => {
          showNav();
        },
        onLeaveBack: () => {
          showNav();
        },
      });

      if (sectionIntro) {
        const overview = refOverview;

        ScrollTrigger.create({
          trigger: overview,
          start: triggerStart,
          end: "clamp(bottom center-=6.8%)",
          onEnter: () => {
            elIntro.current.classList.add("active");
            showNav();
          },
          onEnterBack: () => {
            elIntro.current.classList.add("active");
          },
          onLeave: () => {
            elIntro.current.classList.remove("active");
          },
          onLeaveBack: () => {
            elIntro.current.classList.remove("active");
            hideNav();
          },
        });
      }

      if (sectionBranding) {
        const branding = refBranding;

        ScrollTrigger.create({
          trigger: branding,
          start: "clamp(top center-=6.8%)",
          end: "clamp(bottom center-=2.5%)",
          onEnter: () => {
            elBranding.current.classList.add("active");
          },
          onEnterBack: () => {
            elBranding.current.classList.add("active");
          },
          onLeave: () => {
            elBranding.current.classList.remove("active");
          },
          onLeaveBack: () => {
            elBranding.current.classList.remove("active");
          },
        });

        triggersConfig.forEach((config) => {
          createScrollTrigger({
            triggerElement: branding,
            targetElement: config.targetElement,
            startValue: config.startValue,
            className: "color-branding",
          });
        });
      }

      if (sectionVisual) {
        const visual = refVisual;

        ScrollTrigger.create({
          trigger: visual,
          start: "clamp(top center-=2.5%)",
          end: "clamp(bottom center+=4%)",
          onEnter: () => {
            elVisual.current.classList.add("active");
          },
          onEnterBack: () => {
            elVisual.current.classList.add("active");
          },
          onLeave: () => {
            elVisual.current.classList.remove("active");
          },
          onLeaveBack: () => {
            elVisual.current.classList.remove("active");
          },
        });

        triggersConfig.forEach((config) => {
          createScrollTrigger({
            triggerElement: visual,
            targetElement: config.targetElement,
            startValue: config.startValue,
            className: "color-visual",
          });
        });
      }

      if (sectionWebsite) {
        const website = refWebsite;

        ScrollTrigger.create({
          trigger: website,
          start: "clamp(top center+=4%)",
          end: "clamp(bottom center+=10%)",
          onEnter: () => {
            elWebsite.current.classList.add("active");
          },
          onEnterBack: () => {
            elWebsite.current.classList.add("active");
          },
          onLeave: () => {
            elWebsite.current.classList.remove("active");
          },
          onLeaveBack: () => {
            elWebsite.current.classList.remove("active");
          },
        });

        triggersConfig.forEach((config) => {
          createScrollTrigger({
            triggerElement: website,
            targetElement: config.targetElement,
            startValue: config.startValue,
            className: "color-website",
          });
        });
      }

      if (sectionOutcome) {
        const outcome = refOutcome;

        ScrollTrigger.create({
          trigger: outcome,
          start: "clamp(top-=205px top)",
          end: triggerEnd,
          onEnter: () => {
            elOutcome.current.classList.add("active");
          },
          onEnterBack: () => {
            elOutcome.current.classList.add("active");
          },
          onLeave: () => {
            elOutcome.current.classList.remove("active");
          },
          onLeaveBack: () => {
            elOutcome.current.classList.remove("active");
          },
        });
      }
    }, el);

    return () => ctx.revert();
  }, [
    el,
    lenis.rootElement,
    isMobile,
    sectionIntro,
    sectionBranding,
    sectionVisual,
    sectionWebsite,
    sectionOutcome,
    refOverview,
    refBranding,
    refVisual,
    refWebsite,
    refOutcome,
    refNext,
    // refFull,
  ]);

  return (
    <Wrapper ref={el} className="section-chapters">
      <ul>
        {sectionIntro && (
          <li
            className="chapteritem i active"
            ref={elIntro}
            onClick={() => scrollTo("overview")}
          >
            <span className="text">
              <span className="shorttext">I </span>
              <span className="fulltext">ntro</span>
            </span>
          </li>
        )}

        {sectionBranding && (
          <li
            className="chapteritem b"
            ref={elBranding}
            onClick={() => scrollTo("branding")}
          >
            <span className="text">
              <span className="shorttext">B </span>
              <span className="fulltext">randing</span>
            </span>
          </li>
        )}

        {sectionVisual && (
          <li
            className="chapteritem v"
            ref={elVisual}
            onClick={() => scrollTo("visual")}
          >
            <span className="text">
              <span className="shorttext">V </span>
              <span className="fulltext">isual</span>
            </span>
          </li>
        )}

        {sectionWebsite && (
          <li
            className="chapteritem w"
            ref={elWebsite}
            onClick={() => scrollTo("website")}
          >
            <span className="text">
              <span className="shorttext">W </span>
              <span className="fulltext">ebsite</span>
            </span>
          </li>
        )}

        {sectionOutcome && (
          <li
            className="chapteritem o"
            ref={elOutcome}
            onClick={() => scrollTo("outcome")}
          >
            <span className="text">
              <span className="shorttext">O </span>
              <span className="fulltext">utcome</span>
            </span>
          </li>
        )}
      </ul>
    </Wrapper>
  );
};

export default SectionChapters;
