import React, { useRef } from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import { ScrollTrigger, useGSAP } from "../../../gsap";

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${(props) => {
    // Check if paddingLess is true
    if (props.paddingLess) {
      return props.padding || `${pxToRem(160)} ${pxToRem(194)}`;
    }
    // Fallback to provided padding or default padding
    return props.padding || `${pxToRem(360)} ${pxToRem(268)}`;
  }};

  @media (max-width: 620px) {
    padding: ${(props) => {
      // Check if paddingLess is true
      if (props.paddingLess) {
        return props.padding || `${pxToRem(34)} ${pxToRem(42)}`;
      }
      // Fallback to provided padding or default padding
      return props.padding || `${pxToRem(72)} ${pxToRem(55)}`;
    }};
  }
`;

const OverlayVideo = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
`;

const VideoElement = styled.video`
  width: 100%;
  height: auto;
`;

const VideoFallback = styled.p`
  padding: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
`;

const Video = (props) => {
  const refWrapper = useRef(null);
  const refVideo = useRef(null);

  useGSAP(
    () => {
      ScrollTrigger.create({
        trigger: refWrapper.current,
        start: "top-=2000px bottom+=200px",
        onEnter: () => {
          refVideo.current.play();
        },
        onEnterBack: () => {
          refVideo.current.play();
        },
        onLeave: () => {
          refVideo.current.pause();
        },
        onLeaveBack: () => {
          refVideo.current.pause();
        },
      });
    },
    {
      scope: refWrapper,
    }
  );

  return (
    <Wrapper ref={refWrapper} className={props.className}>
      <VideoWrapper padding={props.padding} paddingLess={props.paddingLess}>
        <OverlayVideo />
        <VideoElement
          ref={refVideo}
          autoPlay={props.autoPlay ?? false}
          playsInline={props.playsInline ?? true}
          loop={props.loop ?? true}
          muted={props.muted ?? true}
          controls={props.controls ?? false}
          preload={props.preload ?? "metadata"}
          poster={props.poster}
        >
          <source src={props.src} type={props.type ?? "video/mp4"} />
          <VideoFallback>
            {props.fallbackMessage ??
              "Your browser does not support the video element. Please consider updating to a modern browser."}
          </VideoFallback>
        </VideoElement>
      </VideoWrapper>
    </Wrapper>
  );
};

export default Video;
